import { isEmpty, removedataclient,removecookie } from "../Utilities/Utils"
import axios from "../api/axios"
import CryptoJS from "crypto-js"
export const ADD_USER="ADD_USER"
export const GET_USER="GET_USER"
export const GET_ERRORS_USER="GET_ERRORS_USER"
export const GET_ERRORS_REGISTER="GET_ERRORS_REGISTER"
export const REMOVE_STATE="REMOVE_STATE"
export const LOGOUT_USER="LOGOUT_USER"
export const UPDATEAFTER="UPDATEAFTER"
export const GET_SESSION_USER="GET_SESSION_USER"

export const adduser=(name,email,password,password_confirmation,type,adresse,datenaissance,nom,postnom,prenom,telephone)=>{

    return (dispatch)=>{
        return axios.post('/register',{name,email,password,password_confirmation,type:type,adresse:adresse,datenaissance:datenaissance,nom:nom,postnom:postnom,prenom:prenom,telephone:telephone}).then((res)=>{
            dispatch({type:ADD_USER,payload:res})

        }).catch((err)=>{
            dispatch({type:GET_ERRORS_REGISTER,payload:err})
        })
    }
}
export const getsession=(email,id,cookie)=>{
    return(dispatch)=>{
        return axios.post('/api/usersession',{email:email,id:id,token:cookie}).then((res)=>{
                dispatch({type:GET_SESSION_USER,payload:res})
        }).catch((err)=>{
            dispatch({type:GET_ERRORS_USER,payload:err})
        })
    }
}
export const getuser=(email,password)=>{
  
    return(dispatch)=>{
    
        return axios.post('api/loginuser',{email,password}).then((res)=>{
            dispatch({type:GET_USER,payload:res})
            if(!isEmpty(res?.data?.id)){
                
             // const secretKey=res?.data?.promoteur
          //    const encryptedData = CryptoJS.AES.encrypt(res?.data?.promoteur, secretKey).toString();
                localStorage.setItem("username",res?.data?.name)
                localStorage.setItem("id",res?.data?.id)
                localStorage.setItem("email",res?.data?.email)
                localStorage.setItem("idecole",res?.data?.idecole)
                localStorage.setItem("annee_scolaire",res?.data?.annee_scolaire)
                localStorage.setItem("idprogramme",res?.data?.idprogramme)
                localStorage.setItem("idenseignant",res?.data?.idenseignant)
                localStorage.setItem("ideleve",res?.data?.ideleve)
                localStorage.setItem("guest",res?.data?.type_compte)
                localStorage.setItem("promoteur",res?.data?.promoteur)
         //       localStorage.setItem('email_encrypted',encryptedData)
            }
            
           
        }).catch((err)=>{
            dispatch({type:GET_ERRORS_USER,payload:err})
        })
    }
}
export const logoutuser=(email,id)=>{
    return(dispatch)=>{
        return axios.post('/logout',{email,id:id}).then((res)=>{
          
            dispatch({type:LOGOUT_USER,payload:res})
            removedataclient()
           
        }).catch((err)=>{
            dispatch({type:GET_ERRORS_USER,payload:err})
        })
    }
}
export const updateafter=(id,token)=>{
    return(dispatch)=>{
        return axios.post('api/updateafter',{id,token}).then((res)=>{
            dispatch({type:UPDATEAFTER,payload:res})
        }).catch((err)=>{
            dispatch({type:GET_ERRORS_USER,payload:err})
        })
    }
}
export const sessionuser=()=>{
    return(dispatch)=>{
        return axios.get("api/getuserinfo").then((res)=>{
            dispatch({type:GET_USER,payload:res})
            
          }).catch((err)=>{
            if(err?.response?.status===401){
              removedataclient()
            }
          })
    }
}