import { useEffect } from "react"
import axios from "../../../../api/axios"
import React from "react"
import { useSelector } from "react-redux"
function Deliberation(){

    useEffect(()=>{

    },[])
    const usersession=useSelector((state)=>state.userReducer)
    const handleRequest=async()=>{
        await axios.get("/").then((res)=>{

        }).catch((err)=>{

        })
    }
    return(
        <>
           <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form">
                            <h3 className="form-title text-primary">
                                Insertion Critères de déliberation : {usersession?.data?.name}
                            </h3>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="form-outline mb-2">
                                        <label htmlFor="type_deliberation" className="form-label">Type de délibération</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}
export default Deliberation