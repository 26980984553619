import React from "react";
import axios from "../../../../../api/axios";
import { useState,useEffect } from "react";
import { useParams,Link } from "react-router-dom";
function Listeproclamation(){
const [cotes,setCotes]=useState([])
const [cours,setCours]=useState([])
const [eleves,setEleves]=useState([])
const [totalite,setTotalite]=useState([])
const [classes,setClasses]=useState([])
const {classe,periode}=useParams()

const recordseleves=eleves.filter((eleve)=>eleve.idclasse==classe)
const recordtotalite=totalite.filter((tota)=>tota.periode==periode)
const myclasse=classes.filter((clas)=>clas.id==classe)
const mes_cours=cours.filter((cour)=>cour.idclasse_initiale==myclasse?.[0]?.idclasse_initiale)
useEffect(()=>{
    getbulletin()
    elevesrequest()
    HandleRequest()
    
},[])

const elevesrequest=async()=>{

    await axios.post("api/allinscription",{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setEleves(res?.data?.items)
  
    }).catch((err)=>{

    })
}
const HandleRequest=async()=>{
    await axios.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{
        setClasses(res.data.items)
    }).catch((err)=>{
        
    })
}
const getbulletin=async()=>{
    await axios.post("api/getbulletin",{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setCours(res?.data?.cours)
        setCotes(res?.data?.tableau)
        setTotalite(res?.data?.total)
    }).catch((err)=>{

    })
}

function totaux(eleve,periode){
    let tot=0
    
    if(periode=="Examen1" || periode=="Examen2"){
    for(let i=0;i<mes_cours.length;i++){
        let cour_pond=mes_cours.filter((cour)=>cour.id==mes_cours[i]?.id)
        const mes_points=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode==periode && cote.my_course==mes_cours[i].id)
            tot=parseInt(tot)+parseInt((parseInt(mes_points[0]?.somme_point)*cour_pond[0]?.ponderation*2)/parseInt(mes_points[0]?.ponderation))
        }
        }
        else{
            for(let i=0;i<mes_cours.length;i++){
                let cour_pond=mes_cours.filter((cour)=>cour.id==mes_cours[i]?.id)
                const mes_points=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode==periode && cote.my_course==mes_cours[i].id)

            tot=parseInt(tot)+parseInt((parseInt(mes_points[0]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points[0]?.ponderation))
            }
        }
        
   
return (tot)
}
function maximum(){
    let sum=0
   const nombre_cour=mes_cours.length
   if(nombre_cour>0){
    for(let i=0;i<nombre_cour;i++){
        sum=parseInt(sum)+parseInt(mes_cours[i]?.ponderation)
       
    }
   }

   return(sum)
}
function totauxgen(eleve,periode){
    let tot1=0
    let tot2=0
    let tot3=0
    if(periode=="tot1"){
        const mes_points1=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P1")
        const mes_points2=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P2")
        const mes_points3=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="Examen1")
        for(let i=0;i<mes_points1.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points1[i]?.my_course)
            tot1=parseInt(tot1)+parseInt((parseInt(mes_points1[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points1[i]?.ponderation))
        }
        for(let i=0;i<mes_points2.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points2[i]?.my_course)
            tot2=parseInt(tot2)+parseInt((parseInt(mes_points2[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points2[i]?.ponderation))
        }
        for(let i=0;i<mes_points3.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points3[i]?.my_course)
            tot3=parseInt(tot3)+parseInt((parseInt(mes_points3[i]?.somme_point)*cour_pond[0]?.ponderation*2)/parseInt(mes_points3[i]?.ponderation))
        }
    }
    else{
        const mes_points1=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P3")
        const mes_points2=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P4")
        const mes_points3=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="Examen2")
        for(let i=0;i<mes_points1.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points1[i]?.my_course)
            tot1=parseInt(tot1)+parseInt((parseInt(mes_points1[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points1[i]?.ponderation))
        }
        for(let i=0;i<mes_points2.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points2[i]?.my_course)
            tot2=parseInt(tot2)+parseInt((parseInt(mes_points2[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points2[i]?.ponderation))
        }
        for(let i=0;i<mes_points3.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points3[i]?.my_course)
            tot3=parseInt(tot3)+parseInt((parseInt(mes_points3[i]?.somme_point)*cour_pond[0]?.ponderation*2)/parseInt(mes_points3[i]?.ponderation))
        }
    }
    
return(tot1+tot2+tot3)
}
let tableaupercent = [];
const tableaupourcent = () => {
    let pourcent = 0;
    if(periode=="Examen1" || periode=="Examen2"){
        recordtotalite.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                pourcent = ((totaux(elv.id, periode) / maximum()*2) * 100).toFixed(2);
                tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
            });
        });
    }
    else{
        recordtotalite.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                pourcent = ((totaux(elv.id, periode) / maximum()) * 100).toFixed(2);
                tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
            });
        });
    }
    
    tableaupercent.sort((a, b) => b.pourcent - a.pourcent);
};
if(recordseleves.length>0){
    tableaupourcent()
}
    return(
        <>
        <div className="container">
            <div className="row"></div>
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <h3 className="text-primary">Liste de proclamation de la {myclasse?.[0]?.nom} </h3>
                    <h4 className="text-primary mb-2">Période: {periode}
                        <Link to={`/listepdfproclamation/${classe}/${periode}`}><button className="btn btn-primary float-end mb-2">Télécharger...</button></Link>
                         </h4>
                    <table className="table table-bordered table-responsive">
                        <thead>
                            <tr>
                                <th width="5%">#</th>
                                <th width="50%">Elève</th>
                                <th width="10%">%</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            recordseleves.length>0 &&
                            tableaupercent.map((record,index)=>
                                recordseleves.filter((rec)=>rec.id==record.idinscription).map((elv)=>
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{elv.nom_complet}</td>
                                        {
                                        periode=="Examen1" || periode=="Examen2" ?
                                        <td>{((totaux(elv.id,periode)/(maximum()*2))*100).toFixed(2)}%</td>
                                        :
                                        <td>{((totaux(elv.id,periode)/maximum())*100).toFixed(2)}%</td>
}
                                    </tr>
                                )
                                )
                        }
                        </tbody>
                    </table>

                </div>
              
            </div>
        </div>
            
        </>
    )
}

export default Listeproclamation;