import { useNavigate } from 'react-router-dom'
import Body from '../../utilitaires/Body'
import Footer from '../../utilitaires/Footer'
import Navbar from '../../utilitaires/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../../api/axios'
import { getCookie, isEmpty, removedataclient } from '../../../Utilities/Utils'
import { useEffect } from 'react'
import { getsession } from '../../../actions/user.action'
import User_session from '../../../Utilities/use_session'
function Accueil(){
 const useselector=useSelector((state)=>state.userReducer)
 const navigate=useNavigate()
 const dispatch=useDispatch()
 const selector=useSelector((state)=>state.userReducer)
 const csrf=()=>axios.get('sanctum/csrf-cookie')

 const handleauthentication=async()=>{
   await csrf()
 }
 useEffect(()=>{
   if(!isEmpty(getCookie("gesco_inf")) && !isEmpty(localStorage.getItem('email')) && !isEmpty(localStorage.getItem("id"))){
     handleauthentication()
     dispatch(getsession(localStorage.getItem('email'),localStorage.getItem("id"),getCookie("gesco_inf")))
   }
 },[])

 if(selector?.status===200 && selector?.data?.status===210){
       
       !isEmpty(localStorage.getItem("lastpage")) ? navigate(localStorage.getItem("lastpage")):navigate("/eleve")


 }
 if(selector?.status===200 && selector?.data?.status===250){
  removedataclient()
}
    return(
        <>

        {
        useselector?.status!==200 && useselector?.data?.status!==210 && 
        (
          <>
          <Body/>
          <Footer/>
          </>
          )
        }
        
        </>
    )
}
export default Accueil