import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Swal from 'sweetalert2';
import {useParams,useNavigate} from 'react-router-dom'

function Update(){
    const [enseignants,setEnseignants]=useState([])
   
    const {id}=useParams()
    const navigate=useNavigate()
    const [my_enseignant,setMy_enseignant]=useState()
    const [enseignant,setEnseignant]=useState()
    
    useEffect(()=>{
        handleenseigner()
        handlerequestEnseignant()
       
    },[])
   const handlerequestEnseignant=async()=>{
    await axios.post('api/getenseignants',{idecole:localStorage.getItem("idecole")}).then(
        (res)=>{
            setEnseignants(res.data.items)
        }
    ).catch((err)=>{

    })
   }
   const handleenseigner=async()=>{
        await axios.post("api/getcourenseigne",{idcours:id}).then((res)=>{
           setMy_enseignant(res.data.cour)
        }).catch((err)=>{

        })
   }
   const handleclick=async()=>{
        await axios.put(`api/Coursenseigne/${id}`,{enseignant:my_enseignant}).then((res)=>{
            if(res.data.code==200){
                Swal.fire({
                    title:"Mis à jour",
                    text:"Modification de l'enseignant du cours effectuée avec succès",
                    icon:"success"
                }).then(
                    navigate("/Cours")
                )
            }
            else{
                Swal.fire({
                    title:"Mis à jour",
                    text:"Une erreur est survenue sur le serveur, le cour a été introuvable",
                    icon:"error"
                })
            }
          
        }).catch((err)=>{

        })
   }
   function confirmSave(){
        Swal.fire({
            title:"Mise à jour",
            text:"Voulez-vous vraiment modifier l'enseignant de ce cours ?",
            icon:"question",
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:"Confirmer",
            confirmButtonColor:"green",
            cancelButtonColor:"red"
        }).then((res)=>{
            if(res.isConfirmed){
                handleclick()
            }
        })
   }
    return(
    <>
    <div className="container">
    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-outline">
                                    <label className="form-label" for="grid-state">
                                        ENSEIGNANT  <span class="obligatoire">*</span>
                                    </label>
                                    <select className="form-select form-select-lg" id="grid-state" name="niveau" value={my_enseignant} onChange={(e)=>setMy_enseignant(e.target.value)} >
                                    <option value="" selected disabled className="fw-bold">Choix de l'enseignant</option>
                                        {
                                            enseignants.map((ense)=>
                                            <option value={ense.my_enseignant} key={ense.my_enseignant} className="fw-bold">{ense.nom_complet}</option>
                                        )
                                        }
                                    
                                    
                                    </select>
                            </div>
                            <div className="form-outline">
                                <button className='btn btn-success' onClick={confirmSave}>Modifier</button>
                            </div>
                        </div>
                    </div>
    </div>
 
    </>
    )
}
export default Update