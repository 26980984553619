


//function Formulaire({children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,count,setCount,nom,postnom,prenom,sexe,telephone,Image,setNom,setPostnom,setPrenom,setSexe,setImage,setTelephone,setNomcomplet}){
    function Formulaire({children,title,confirmSave,province_educationnelle,province,code_province,arrete,setProvince_educationnelle,setProvince,setCode_province,setArrete}){
  
        return(
            <>
            <div className="container">
                <form onSubmit={confirmSave}>
                    <legend>
                        <h3 className="font-bold text-blue-500 mb-4 text-xl">
                            {title}
                        </h3>
                    </legend>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-outline">
                                <label class="form-label" for="province_educationnelle">Province Educationnelle</label>
                                <input type="text" id="firstName" class="form-control form-control-lg" value={province_educationnelle} onChange={(e)=>setProvince_educationnelle(e.target.value.toUpperCase())} />                          
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-outline">
                                <label htmlFor="province" className="form-label">Province</label>
                                <select name="province" id="" className="form-select form-select-lg" value={province} onChange={(e)=>setProvince(e.target.value.toUpperCase())}>
                                    <option value="" selected disabled>Choix de la province</option>
                                    <option value="SUD-KIVU">SUD-KIVU</option>
                                    <option value="NORD-KIVU">NORD-KIVU</option>
                                    <option value="HAUT-KATANGA">HAUT-KATANGA</option>
                                    <option value="KINSHASA">KINSHASA</option>
                                    <option value="MANIEMA">MANIEMA</option>
                                    <option value="KONGO-CENTRAL">KONGO-CENTRAL</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-outline">
                                <label htmlFor="Arrete" className="form-label">Arrêté de l'inspection</label>
                                <input type="text" className="form-control form-control-lg" value={arrete} onChange={(e)=>setArrete(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-outline">
                                <label class="form-label" for="firstName">Code de la province éducationnelle</label>
                                <input type="text" id="code_province" class="form-control form-control-lg" value={code_province} onChange={(e)=>setCode_province(e.target.value)}  />                          
                            </div>
                        </div>
                    </div>
                    {children}

                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <button className="btn btn-primary" type="submit" name="soumettre" id="submit" >Submit</button>
                        </div>
                    </div>

                </form>
            </div>

            </>
        )
    }
    export default Formulaire;