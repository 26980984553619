import axios from '../../../../api/axios'
import React,{useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Formulaire from '../../../ModelesPages/Modele1/FormulaireEcole'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { getsession,logoutuser } from '../../../../actions/user.action'
import { getCookie,removedataclient } from '../../../../Utilities/Utils'

function Insertion(){
    const dispatch=useDispatch()
    useEffect(()=>{
        HandleRequest()
      dispatch(getsession(localStorage.getItem('email'),localStorage.getItem("id"),getCookie("gesco_inf")))

    },[])
    const userselector=useSelector((state)=>state.userReducer)

    const [inspections,setInspections]=useState([])
    const [adresse,setAdresse]=useState("");
    const [Count,setCount]=useState(0);
    const [ville,setVille]=useState("")
    const [type,setType]=useState("")
    const [nom,setNom]=useState("");
    const [commune,setCommune]=useState("")
    const [code,setCode]=useState("")
    const [province,setProvince]=useState("");
    const [province_educationnelle,setProvince_educationnelle]=useState("");
    const [arrete,setArrete]=useState("");
    const [numero_agreement,setNumero_agreement]=useState("");
    const [inspection,setInspection]=useState("")
    const [error_submission,setError_submission]=useState(false)
    const [submission,setSubmission]=useState(false)
    const [status_code,setStatus_code]=useState("")
    const csrf=()=>axios.get('sanctum/csrf-cookie')
    let navigate=useNavigate()
 
    
    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    const confirmSave=(e)=>{
        e.preventDefault()
        if(nom !=""  && type!="" && province!="" && province_educationnelle!="" && adresse!="" && numero_agreement!=""){
        Swal.fire({
            title:'Confirmation de soumission',
            icon:'question',
            text:'Voulez-vous vraiment enregistrer votre école sur notre plateforme  ?',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonColor:'success',
            confirmButtonText:'Confirmer'

        }).then((resultat)=>{
            if(resultat.isConfirmed){
                
                HandleSubmit()
            }
        })
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord compléter tous les champs obligatoires',
                icon:'error'
                    
        })
        }
    }
    const HandleSubmit=async (e)=>{
      //e.preventDefault()
            await axios.post('/api/ecole',{code:code,commune:commune,nom:nom.toLowerCase(),province:province.toLowerCase(),province_educationnelle:province_educationnelle,type:type.toLowerCase(),adresse:adresse.toLowerCase(),ville:ville.toLowerCase(),arrete:arrete.toLowerCase(),numero_agreement:numero_agreement,id_admin:localStorage.getItem("ideleve")}).then((res)=>{
          /*  setNom("")
            setProvince("")
            setProvince_educationnelle("") 
            setType("")
            setAdresse("")
            setVille("")
            setNumero_agreement("")
            setArrete("")
            setError_submission(false)*/
            setSubmission(true)
          //  document.getElementById("resultat").innerHTML=""
            if (res.data.status_code===200){
                Swal.fire({
                    title:'Message de confirmation',
                    icon:'success',
                    text:'Votre école a été enregistré sur notre plateforme, veuillez vous reconnecter encore',
                })
                dispatch(logoutuser())
                 removedataclient()
                 navigate("/Connexion")
               
            } else{
                Swal.fire({
                    title:'Erreur d\'exécution',
                    icon:'error',
                    text:'Une erreur est survenu au cours du traitement de votre requête',
                })      
            }
             
        }).catch((err)=>{
                //document.getElementById("erreur").innerHTML=err
                
                
        })

    
       //alert("i want to submit your form don't be worry")
    }
    const recorderInspections=inspections.filter((inspect)=>inspect.province==province)
    const HandleRequest=async()=>{
        await axios.get('api/Inspection').then((res)=>{
            setInspections(res.data.items)
        }).catch((err)=>{
            console.log(err)
        })

    }
    return(
        <>
            <div className="">
            <Formulaire title="Formulaire d'inscription des écoles" recorderInspections={recorderInspections} code={code} setCode={setCode} commune={commune} setCommune={setCommune} confirmSave={confirmSave} error_submission={error_submission} submission={submission} setInspections={setInspections} inspections={inspections} onclick={handleclick} setNom={setNom} handlekeyUpgain={handlekeyUpgain} setProvince={setProvince} setProvince_educationnelle={setProvince_educationnelle} setType={setType} setVille={setVille} setAdresse={setAdresse} nom={nom} province={province} province_educationnelle={province_educationnelle} type={type} ville={ville} arrete={arrete} handleMouse={handleMouse} Count={Count} setCount={setCount} handleMouseleave={handleMouseleave} HandleSubmit={HandleSubmit} setNumero_agreement={setNumero_agreement} numero_agreement={numero_agreement} adresse={adresse} inspetion={inspection} setInspection={setInspection} setArrete={setArrete} >
                <div className="w-full md:w-1/1 px-3  md:mb-0">
                   
                <div id="resultat" className="block text-red-700"></div>
                <div id="erreur" className="block text-red-500"></div>
                </div>
            </Formulaire>
            </div>
        </>
    )
}
export default Insertion
