import axios from 'axios';
import axios2 from '../../../../api/axios';
import React, {useState,useEffect} from 'react'
import {EyeFill,PencilFill,Trash3Fill,PersonPlus} from "react-bootstrap-icons"
import { useNavigate,useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import loadingIcon from '../../../../Images/loading.gif'
import { useDispatch, useSelector } from 'react-redux';
import { getCookie, isEmpty, session_callback } from '../../../../Utilities/Utils';
import User_session from '../../../../Utilities/use_session';
import { getsession } from '../../../../actions/user.action';


function Eleve() {
    const usersinformation=useSelector((state)=>state.userReducer)
    let i=0
    const [eleves,setEleve]=useState([])
    const [my_classe,setMy_classe]=useState("")
    const [curentpage,setCurentpage]=useState(1)
    const [search,setSearch]=useState('')
    const [recordperpage,setRecordperpage]=useState(50)
    let navigate=useNavigate()
    const [classes,setClasses]=useState([])

    const lastIndex=curentpage * recordperpage
    const firstIndex=lastIndex - recordperpage
    const [loading,setLoading]=useState(false)
    const dispatch=useDispatch()
    const csrf=()=>axios2.get('sanctum/csrf-cookie')
    localStorage.setItem("lastpage","/eleve")
    useEffect(
        ()=>{
        /*   if(session_callback){
            handle()
            dispatch(getsession(localStorage.getItem('email'),localStorage.getItem("id"),getCookie("gesco_inf")))*/
            elevesRequest()
            handle_classe_request()

         //  }
        },[])
    const elevesRequest=async ()=>{
        //await csrf()
        setLoading(true)
      
        await axios2.post('api/geteleves',{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then(
            (res)=>{
                setEleve(res.data.items)
                setLoading(false)
                console.log(res)
            }
        ).catch((err)=>{
            console.log(err)
        })
    }
    /*useEffect(()=>{
        const records= search.toLowerCase() === '' ? eleves.slice(firstIndex, lastIndex) : eleves.filter((eleve)=>{return search.toLowerCase() === '' ? eleve : eleve.nom_complet.toLowerCase().includes(search)}).slice(firstIndex, lastIndex)
        const recordNpage=search.toLowerCase() === '' ? eleves : eleves.filter((eleve)=>{ return eleve.nom_complet.toLowerCase().includes(search)})
        const npage=Math.ceil(recordNpage.length/recordperpage)
        const numbers=[...Array(npage+1).keys()].slice(1)   
            function affichage(){
                eleves.length>0 ? 
    
                        records.filter((eleve)=>{
                    return search.toLowerCase() === '' ? eleve : eleve.nom_complet.toLowerCase().includes(search)
                }).map((eleve,indexV)=>
                {
                  return(
                        <tr key={indexV} className='ligne'>
                            <td>{indexV+1}</td>
                            <td>{eleve.nom.toLowerCase() + ' '+ eleve.postnom.toLowerCase() + ' ' + eleve.prenom.toLowerCase()}</td>
                            <td>{eleve.sexe}</td>
                            <td>{eleve.adresse.toLowerCase()}</td>
                            <td>{eleve.date_naissance}</td>
                            <td>{eleve.lieu_naissance.toLowerCase()}</td>
                            <td>{eleve.numero_serni}</td>
                            <td><EyeFill color="lightgreen" size={20} className='d-inline-flex mx-2 icone'  /><PencilFill color="royalblue" size={20} className='d-inline-flex mx-2 icone' onClick={()=>navigate(`${eleve.id}`)}/>  <Trash3Fill color='brown' size={20} className='d-inline-flex mx-2 icone' onClick={()=>AlertNotification(eleve.id)} /> </td>
                        </tr>
                  )
                    
                })
               :
                <div className="">
                    Aucun élève n'a été trouvé
                </div>
            }
    },[eleves])*/
   
    const {id}=useParams()
    const records= search === '' && my_classe==='' ? eleves.slice(firstIndex, lastIndex) : eleves.filter((eleve)=>{return search === '' && my_classe==='' ? eleve : !isEmpty(my_classe) ? eleve.nom_complet.includes(search) && eleve.idclasse==my_classe : eleve.nom_complet.includes(search)}).slice(firstIndex, lastIndex)
    const recordNpage=search === '' && my_classe==='' ? eleves : eleves.filter((eleve)=>{ return eleve.nom_complet.includes(search) && eleve.idclasse==my_classe})
    const npage=Math.ceil(recordNpage.length/recordperpage)
    const numbers=[...Array(npage+1).keys()].slice(1)    
   //const recordperpage=2
    
    //const numbers=[1,2,3,4,5]
    
    const Iteration=()=>{
        return (loading) ? (
            <div className='w-100 mx-auto'>
                <img src={loadingIcon} alt='loading'/>
            </div>
        ) : (
            records.length > 0 &&
                records.filter((eleve)=>{
                    return search === '' ? eleve : eleve.nom_complet.includes(search)
                }).map((eleve,indexV)=>
                {
                  return(
                        <tr key={indexV} className='ligne'>
                            <td>{indexV+1}</td>
                            <td>{eleve.nom.toLowerCase() + ' '+ eleve.postnom + ' ' + eleve.prenom}</td>
                            <td>{eleve.sexe}</td>
                            <td>{eleve.adresse.toLowerCase()}</td>
                            <td>{eleve.date_naissance}</td>
                            <td>{eleve.lieu_naissance}</td>
                            <td>{eleve.numero_serni}</td>
                            <td>{eleve.my_classe}</td>
                            <td><EyeFill color="lightgreen" size={20} className='d-inline-flex mx-2 icone'  /><PencilFill color="royalblue" size={20} className='d-inline-flex mx-2 icone' onClick={()=>navigate(`${eleve.id}`)}/>  <Trash3Fill color='brown' size={20} className='d-inline-flex mx-2 icone' onClick={()=>AlertNotification(eleve.id)} /> </td>
                        </tr>
                  )
                    
                }
                )
            
        )
        
            }   
            function AlertNotification(ideleve){
                Swal.fire({
                    title:'Etes-vous sûr de le supprimer ?',
                    text:'Cet élève sera supprimer',
                    icon:'warning',
                    showDenyButton:true,
                    showCancelButton:true,
                    confirmButtonText:'Confirmer',
                    denyButtonText:'Annuler'
                }).then((resultat)=>{
                    if(resultat.isConfirmed){
                        navigate(`/eleve/suppression/${ideleve}`)
                            }
                })
            }
            const handle_classe_request=async()=>{
                try{
                    await axios2.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{
        
                        setClasses(res.data.items)
                    })
                }
                catch(e){
        
                }
            }
    return(

        <>
       
        <div className="row justify-content-center px-2">
            <div className="col-md-12 mb-2 text-center ">
                <h3>Bienvenu Mr <span className=''> {usersinformation?.data?.name}</span></h3>
            </div>
        </div>
        <div className='row justify-content-center'>
            <div className='col-md-6 mb-2'> 
               <h3 className="text-dark">
              
                Liste des élèves déjà inscrits (Total : <span className='text-primary'> {records.length}</span>)
        
                </h3>
                
            </div>
            <div className='col-md-2 mb-2'>
                <input type="text" name="recherche" id="recherche" placeholder='Rechercher un élève' className='form-control form-control-sm'  onChange={(e)=>setSearch(e.target.value.toLowerCase())} value={search} />

            </div>
            <div className="col-md-2 mb-2">
                <select name="classe" id="" className='form-select' onChange={(e)=>setMy_classe(e.target.value)}>
                    <option value="" selected>Toutes les classes</option>
                    {
                                    classes.map((cla)=>(
                                        <option  value={cla.id} key={cla.id}  >{cla.nom}</option>
                                        )
                                    )
                                }
                </select>
            </div>
            <div className="col-md-2  mb-2">
                    <button name="ajouter" id="ajouter" onClick={()=>navigate('insertion')} className='btn btn-success d-inline-flex w-50 mx-auto  ' ><span className='text-center'> <PersonPlus color='white' size={25} className='d-inline-flex' /> Ajouter un élève </span></button>

            </div>
        </div>
        <div>
           <table className="table table-striped table-hover table-responsive">
                <thead className='thead-light'>
                    <tr className=''>
                        <th >#</th>
                        <th>Nom</th>
                        
                        <th>Sexe</th>
                        <th>Adresse</th>
                        <th>Date de naissance</th>
                        <th>Lieu de naissance</th>
                        <th>Numero serni</th>
                        <th>Classe</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody className="text-slate-700 text-xs">
                    {Iteration()}
                </tbody>
           </table>

        </div>
        <nav>
                <ul className='pagination mt-2 mx-4'>
                    <li className='page-item  '>
                        <a href="#" className='page-link' onClick={Prepage}>Prev</a>
                    </li>
                {
                    numbers.map((number,index)=>{
                        return(
                            <li className={`page-item ${curentpage === number ? 'active': '' }`} key={index}>
                                <a href="#" className='page-link' onClick={()=>changeCPage(number)}>{number}</a>
                            </li>
                        )
                    })
                    
                }
                    <li className='page-item '>
                        <a href="#" className='page-link' onClick={Nextpage}>Next</a>
                    </li>
                    <span className='mr-8'>
                    <input type="number" name="nbrperpag" className='form-control' id="nbrperpag" placeholder='Nombre par page' value={recordperpage} onChange={(e)=>setRecordperpage(e.target.value !== '' ? e.target.value : 1)} onBlur={()=>setCurentpage(1)} />
                    </span>
                </ul>
        </nav>
        <h3>Bienvenu Mr <span className=''> {usersinformation?.data?.id}</span></h3>
        </>
    )
function Prepage(){
    if(curentpage > 1){
        setCurentpage(curentpage-1)
    }
}
function changeCPage(id){
   setCurentpage(id)
}
function Nextpage(){
    if(curentpage < npage){
       // alert(curentpage)
        setCurentpage(curentpage+1)
    }
}

}
export default Eleve;

