import Tableau from './tableau';
import Allclasse from './allclasse'
//import { Document,Page,StyleSheet } from '@react-pdf/renderer';
import { Document, Page, Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import { Table,TableRow,TableCell,TableHeader } from '@ag-media/react-pdf-table';
import Drapeau from "../photos/drapeau.png"
import Embleme from "../photos/embleme.png"  
const MyPDF = ({total2,classetoute,dataclasse,recordseleves,My_ecole,dataeleve,eleve,idclasse,isEmpty,getponderation,eleves,cours,mes_cours,cotes,total,totaux,place,maximum,classe,totauxgen}) => {
/*const styles=StyleSheet.create({
  page:{
    flexDirection:"row",
    border:"3px  black solid",
  }
})*/
const styles=StyleSheet.create({
  page:{
    flexDirection:"row",
    border:"3px  black solid",
  },
  section:{
   paddingHorizontal:"10px",
   paddingVertical:"10px",
    width:"100%",
    border:"1px solid black",
    justifyContent:"start",
    
    
    
  },
  theader:{
    fontWeight:'bold',
    fontSize:10,
    textAlign:"center"
  
  },
  othertext:{
    fontSize:12,
    textAlign:"left",
    width:"100%",
    display:"block",
    flex:1,
  },
  texta:{
    fontSize:12,
    textAlign:"center",
    width:"100%",
    display:"block",
    flex:1,
  },
  noborder:{
    borderStyle:"none",
  },
  tbranche:{
    width:"55%"
  },
  tother:{
    width:"5%"
  }

})

  return (
    <Document>
      
      {
        classetoute==="true" ?
        (
          recordseleves.map(elv=>
            (
             // <Allclasse key={elv.idinscription} total2={total2} dataclasse={dataclasse} annee_scolaire={elv.annee_scolaire} num_permanent={elv.Numero_serni} sexe={elv.sexe} nom={elv.nom_complet} My_ecole={My_ecole} datenaissance={elv.date_naissance} lieunaissance={elv.lieu_naissance} recordseleves={recordseleves} classetoute={classetoute} dataeleve={dataeleve} classe={classe} totauxgen={totauxgen} maximum={maximum} totaux={totaux} place={place} cotes={cotes} total={total} cours={cours} mes_cours={mes_cours} eleve={elv.id} eleves={eleves} idclasse={idclasse}  isEmpty={isEmpty} getponderation={getponderation}/>
             <Allclasse  />
            )
          
            
          )
          
        )
        :
        (
          <Tableau recordseleves={recordseleves} total2={total2} dataclasse={dataclasse} My_ecole={My_ecole} classetoute={classetoute} dataeleve={dataeleve} classe={classe} totauxgen={totauxgen} maximum={maximum} totaux={totaux} place={place} cotes={cotes} total={total} cours={cours} mes_cours={mes_cours} eleve={eleve} eleves={eleves} idclasse={idclasse}  isEmpty={isEmpty} getponderation={getponderation}>

          </Tableau>
        )
      }
       
    </Document>
  
  );
};

export default MyPDF;