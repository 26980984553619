


    function Formulaire_programme({annee_scolaire,HandleSubmit,setAnnee_scolaire,submission,error_submission,debut_p1,setDebut_p1,debut_p2,setDebut_p2,debut_p3,setDebut_p3,debut_p4,setDebut_p4,debut_exam1,setDebut_exam1,debut_exam2,setDebut_exam2,fin_p1,setFin_p1,fin_p2,setFin_p2,fin_p3,setFin_p3,fin_p4,setFin_p4,fin_exam1,setFin_exam1,fin_exam2,setFin_exam2}){
  
    return(
        <>
        <div className="container justify-content-end">

        
            <form className="mt-2" >
                <legend>
                    <h3 className="font-bold text-blue-500 mb-4 text-xl">
                            Calendrier Scolaire
                    </h3>
                </legend>
                {//Début test
                }
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <div className="form-outline">
                            <label htmlFor="" className="form-label">Année scolaire</label>
                            <input type="text" placeholder="2023-2024" className="form-control form-control-lg" value={annee_scolaire} onChange={(e)=>setAnnee_scolaire(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Début P1</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={debut_p1} onChange={(e)=>setDebut_p1(e.target.value)} required='required' />                          
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Fin P1</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={fin_p1} onChange={(e)=>setFin_p1(e.target.value)} required='required' />                          
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Début P2</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={debut_p2} onChange={(e)=>setDebut_p2(e.target.value)} required='required' />                          
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Fin P2</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={fin_p2} onChange={(e)=>setFin_p2(e.target.value)} required='required' />                          
                        </div>
                    </div>
              </div>
              <div className="row justify-content-center">
                <h3 className="text-title text-center text-primary">Examen Ier semestre</h3>
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Debut </label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={debut_exam1} onChange={(e)=>setDebut_exam1(e.target.value)} required='required' />                          
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Fin</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={fin_exam1} onChange={(e)=>setFin_exam1(e.target.value)} required='required' />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Début P3</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={debut_p3} onChange={(e)=>setDebut_p3(e.target.value)} required='required' />                          
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Fin P3</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={fin_p3} onChange={(e)=>setFin_p3(e.target.value)}required='required'  />                          
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Début P4</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={debut_p4} onChange={(e)=>setDebut_p4(e.target.value)} required='required' />                          
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Fin P4</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={fin_p4} onChange={(e)=>setFin_p4(e.target.value)} required='required' />                          
                        </div>
                    </div>
              </div>
              <div className="row justify-content-center">
                <h3 className="text-title  text-center text-primary">Examen second semestre</h3>
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Debut </label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={debut_exam2} onChange={(e)=>setDebut_exam2(e.target.value)} required='required' />                          
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Fin</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={fin_exam2} onChange={(e)=>setFin_exam2(e.target.value)} required='required' />                          
                        </div>
                    </div>
              </div>
           
              {
                            error_submission && 
                      
              <div className="row justify-content-start">
              
                <div className="col-md-6 mb-2">
                    <span className="text-danger">
                        Veuillez compléter tous les champs ils sont obligatoires
                    </span>
                </div>
              </div>
              }
               {
                            submission && 
                      
              <div className="row justify-content-start">
              
                <div className="col-md-6 mb-2">
                    <span className="text-primary">
                      Le programme a été enregistré avec succès
                    </span>
                </div>
              </div>
              }
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <button type="button" className="btn btn-primary" onClick={HandleSubmit}>Enregistrer</button>
                        </div>
                    </div>
              </div>

                
                
            </form>
        </div>
        </>
    )
}
export default Formulaire_programme;