import axios from "../api/axios";
export const ADD_CLASSES="ADD_CLASSES"
export const addclasse=(data)=>{
    return (dispatch)=>{
        return axios.post('/api/classe',{data:data}).then((res)=>{
            
            dispatch({type:ADD_CLASSES,payload:res})
        }).catch((err)=>{
            
        })
    }
}


