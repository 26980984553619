


//function Formulaire({children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,count,setCount,nom,postnom,prenom,sexe,telephone,Image,setNom,setPostnom,setPrenom,setSexe,setImage,setTelephone,setNomcomplet}){
    function Formulaire({reinitialiser,message,email,setEmail,date_engagement,setDate_engagement,telephone,setTelephone,my_domaine,setMy_domaine,error_submission,submission,HandleSubmit,children,title,handlekeyUpgain,nom,postnom,prenom,sexe,date_naissance,lieu_naissance,setNom,setPostnom,setPrenom,setSexe,setDate_naissance,setLieu_naissance,setNom_complet,niveau,setNiveau,adresse,setAdresse}){
  
    return(
        <>
        <div className="container justify-content-end">

        
            <form className="mt-2" >
                <legend>
                    <h3 className="font-bold text-blue-500 mb-4 text-xl">
                        {title}

                        
                    </h3>
                </legend>
                <button type="button" onClick={reinitialiser} className="btn btn-warning ml-2">Reinitialiser compte</button>
                {//Début test
                }
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Nom</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={nom} onChange={(e)=>setNom(e.target.value.toLowerCase())} onBlur={(e)=>setNom_complet(e.target.value.toLowerCase() +" "+postnom+" "+prenom)} onKeyUp={handlekeyUpgain} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Postnom</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={postnom} onChange={(e)=>setPostnom(e.target.value.toLowerCase())} onBlur={(e)=>setNom_complet(nom +" "+e.target.value.toLowerCase()+" "+prenom)} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Prenom</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={prenom} onChange={(e)=>setPrenom(e.target.value.toLowerCase())} onBlur={(e)=>setNom_complet(nom +" "+postnom+" "+e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Genre</label>
                            <select name="genre" id="genre" className="form-control form-select-lg" value={sexe} onChange={(e)=>setSexe(e.target.value)}>
                                <option value="" selected disabled>Choix du genre</option>
                                <option value="M">Homme</option>
                                <option value="F">Femme</option>
                            </select>
                        </div>
                    </div>
                  
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Adresse</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={adresse} onChange={(e)=>setAdresse(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Email</label>
                            <input type="email" id="firstName" class="form-control form-control-lg" value={email} onChange={(e)=>setEmail(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Date d'engagement</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={date_engagement} onChange={(e)=>setDate_engagement(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Téléphone</label>
                            <input type="number" id="firstName" class="form-control form-control-lg" maxLength="9" value={telephone} onChange={(e)=>{const value=e.target.value;if(value.length<=9){setTelephone(value)}}} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Date de naissance</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={date_naissance} onChange={(e)=>setDate_naissance(e.target.value)} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Lieu de naissance</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={lieu_naissance} onChange={(e)=>setLieu_naissance(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Niveau</label>
                            <select name="genre" id="genre" className="form-control form-select-lg" value={niveau} onChange={(e)=>setNiveau(e.target.value)}>
                                <option value="" selected disabled>Choix du niveau</option>
                                <option value="D6">D6</option>
                                <option value="GRADUAT">GRADUAT</option>
                                <option value="LICENCE">LICENCE</option>
                                <option value="MASTER">MASTER</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">DOMAINE</label>
                            <select name="section" id="section" className="form-control form-select-lg" onChange={(e)=>setMy_domaine(e.target.value)} >
                                <option value="" selected disabled>Choix du domaine</option>
                               <option value="MATH-PHYSIQUE">MATH-PHYSIQUE</option>
                               <option value="HISTOIRE">HISTOIRE</option>
                               <option value="GEOGRAPHIE">GEOGRAPHIE</option>
                               <option value="FRANCAIS">FRANCAIS</option>
                            </select>                        
                        </div>
                    </div>
              </div>
              
              <div className="row">
                {children}
              </div>
              {
                            error_submission && 
                      
              <div className="row justify-content-start">
              
                <div className="col-md-6 mb-2">
                    <span className="text-danger">
                        Veuillez compléter tous les champs obligatoires
                    </span>
                </div>
              </div>
              }
               {
                            submission && 
                      
              <div className="row justify-content-start">
              
                <div className="col-md-6 mb-2">
                    <span className="text-primary">
                        {message}
                    </span>
                </div>
              </div>
              }
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <button type="button" className="btn btn-primary" onClick={HandleSubmit}>Enregistrer</button>
                        </div>
                    </div>
              </div>

                
                
            </form>
        </div>
        </>
    )
}
export default Formulaire;