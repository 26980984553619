


//function Formulaire({children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,count,setCount,nom,postnom,prenom,sexe,telephone,Image,setNom,setPostnom,setPrenom,setSexe,setImage,setTelephone,setNomcomplet}){
    function Formulaire({HandleSubmit,titlecours,children,classe,ponderation,setClasse,setPonderation,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,cour,setCour,confirmSave,classes,cours}){
      return(
            <>
            <div className="container">
            <div className="row justify-content-start">
                <div className="col-md-6">
                    <form className="" onSubmit={confirmSave}>
                    <legend>
                        <h3 className="font-bold text-blue-500 mb-4 text-xl">
                            {title}
                        </h3>
                    </legend>
    
                    <div className="row">
                       
                        
                        <div className="col-md-12 mb-2">
                            <label className="form-label" for="grid-state">
                                CLASSE  <span class="obligatoire">*</span>
                            </label>
                            <div className="form-outline">
                                <select className="form-select form-select-lg" id="grid-state" name="classe" onChange={(e)=>setClasse(e.target.value)} value={classe}  >
                                <option value="" selected disabled>Choix de la classe</option>
                                {
                                    classes.map((cla)=>
                                        <option value={cla.id} key={cla.id} >{cla.nom}</option>
                                    )
                                }
                                
                                </select>
                            </div>
                        </div>
                       
                        
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="form-outline">
                                    <label className="form-label" for="grid-state">
                                        COURS  <span class="obligatoire">*</span>
                                    </label>
                                    <select className="form-select form-select-lg" id="grid-state" name="niveau"  onChange={(e)=>setCour(e.target.value)} value={cour}>
                                    <option value="" selected disabled>Choix du cours</option>
                                        {
                                            cours.map((crs)=>
                                            <option value={crs.id} key={crs.id} >{crs.nom}</option>
                                        )
                                        }
                                    
                                    
                                    </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="form-outline">
                                <label className="form-label" for="grid-first-name">
                                    PONDERATION : <span class="obligatoire">*</span>
                                </label>
                                <input className="form-control form-control-lg" id="grid-first-name" name="nom" type="number" placeholder="Entrer la pondération du cours ici" value={ponderation} onChange={(e)=>setPonderation(e.target.value)} />
                            </div>
                        </div>
                    </div>
                 

                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="form-outline">
                                <button className="btn btn-primary" type="submit" name="soumettre" id="submit" >Submit</button>
                            </div>
                        </div>
                    </div>

                </form>
                </div>
                
                <div className="col-md-6 px-2">
                        <h3 className="text-bold text-black">
                           {titlecours}
                        </h3>
                        <div className="">
                            {children}
                        </div>
                </div>
            </div>
            </div>
            </>
        )
    }
    export default Formulaire;