
function FormulaireEcole({recorderInspections,code,setCode,commune,setCommune,error_submission,submission,confirmSave,HandleSubmit,inspection,children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,nom,province,province_educationnelle,type,ville,arrete,setNom,setProvince,setProvince_educationnelle,setType,setVille,setArrete,setInspection,numero_agreement,setNumero_agreement,adresse,setAdresse,inspections,setInspections}){
   
    return(
        <>
        <div className="container align-items-center">
                <form className="" onSubmit={confirmSave}>
                <legend>
                    <h3 className="font-bold text-blue-500 mb-4 text-xl">
                        {title}
                    </h3>
                </legend>
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">NOM DE L'ECOLE</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={nom} onChange={(e)=>setNom(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">PROVINCE</label>
                            <select name="genre" id="province" className="form-control form-select-lg" value={province} onChange={(e)=>setProvince(e.target.value.toUpperCase())}>
                                <option value="" selected disabled>Choix de la province</option>
                                <option value="SUD-KIVU">SUD-KIVU</option>
                                <option value="NORD-KIVU">NORD-KIVU</option>
                                <option value="HAUT-KATANGA">HAUT-KATANGA</option>
                                <option value="KINSHASA">KINSHASA</option>
                                <option value="MANIEMA">MANIEMA</option>
                                <option value="KONGO-CENTRAL">KONGO-CENTRAL</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">PROVINCE EDUCATIONNELLE</label>
                            <select name="genre" id="province" className="form-control form-select-lg" value={province_educationnelle}  onChange={(e)=>setProvince_educationnelle(e.target.value)}>
                                    <option value="">Choix de la province educationnelle</option>
                                {
                                recorderInspections.map((inspect)=>
                                    <option value={inspect.id}  >{inspect.province_educationnelle}</option>
                                    
                                )
                                
                                }
                            </select>
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">VILLE</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={ville} onChange={(e)=>setVille(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">ADRESSE</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={adresse} onChange={(e)=>setAdresse(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">ARRETE</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={arrete} onChange={(e)=>setArrete(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">NUMERO AGREEMENT</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={numero_agreement} onChange={(e)=>setNumero_agreement(e.target.value)} />                          
                        </div>
                    </div>
              </div>
              <div className="row">
              <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">CODE DE L'ECOLE</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={code} onChange={(e)=>setCode(e.target.value.toUpperCase())} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
              <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">COMMUNE</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={commune} onChange={(e)=>setCommune(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">TYPE</label>
                            <select name="genre" id="province" className="form-control form-select-lg" value={type}  onChange={(e)=>{setType(e.target.value.toLowerCase())}}>
                                <option value="" >Choix du type de l'école</option>
                                <option value="non conventionné"  >Non Conventionnée</option>
                                <option value="conventionnée catholique">Conventionnée Catholique</option>
                                <option value="conventionnée protestante">Conventionnée Protestante </option>
                                <option value="conventionnée kimbanguiste">Conventionnée Kimbanguiste</option>
                                <option value="ecole privée">Ecole Privée</option>
                            </select>
                        </div>
                    </div>
              </div>
              {
                                        error_submission && 
                                
                        <div className="row justify-content-start">
                        
                            <div className="col-md-6 mb-2">
                                <span className="text-danger">
                                    Veuillez compléter tous les champs obligatoires
                                </span>
                            </div>
                        </div>
                        }
                        {
                                        submission && 
                                
                        <div className="row justify-content-start">
                        
                            <div className="col-md-6 mb-2">
                                <span className="text-primary">
                                    L'élève a été enregistré avec succès
                                </span>
                            </div>
                        </div>
                        }
                    <div className="row">
                        {children}
                    </div>
                    <div className="row justify-content-start">
                        <div className="col-md-6">
                            <button type="submit" name="submit" className="btn btn-primary px-2">Enregistrer</button>
                        </div>
                    </div>
            </form>
        </div>
        </>
    )
}
export default FormulaireEcole