


//function Formulaire({children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,count,setCount,nom,postnom,prenom,sexe,telephone,Image,setNom,setPostnom,setPrenom,setSexe,setImage,setTelephone,setNomcomplet}){
    function Formulaire({HandleSubmit,children,niveau,handleBlurNiveau,section,handleBlurSection,setSection,handleChangeSection,handleChangeNiveau,setNiveau,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,nom,setNom,confirmSave,sections}){
  function AffichageOption(){
    if(section==3){
        return(
            <>
                <option value="7">7ème </option>
                <option value="8">8ème</option>
            </>
            
        )
        
        
    }
    if(section!="" && section!=3){
        return(
            <>
                <option value="1">1ère</option>
                <option value="2">2ème</option>
                <option value="3">3ème</option>
                <option value="4">4ème</option>
            </>
            
        )
        
    }
  }
        return(
            <>
            <div className="container align-items-center">
                <form  onSubmit={confirmSave}>
                    <legend>
                        <h3 className="font-bold text-blue-500 mb-4 text-xl">
                            {title}
                        </h3>
                    </legend>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-outline">
                                <label class="form-label" for="firstName">Section</label>
                                <select name="section" id="section" className="form-control form-select-lg" onChange={(e)=>handleChangeSection(e.target.value)} value={section} onBlur={(e)=>handleBlurSection(e.target.value)}>
                                <option value="" selected disabled>Choix de la section</option>
                                {
                                    sections.map((sect)=>
                                        <option value={sect.id} key={sect.id} >{sect.nom}</option>
                                    )
                                }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-outline">
                                <label class="form-label" for="firstName">NIVEAU</label>
                                <select name="section" id="niveau" className="form-control form-select-lg" value={niveau} onChange={(e)=>handleChangeNiveau(e.target.value)} onBlur={(e)=>handleBlurNiveau(e.target.value)}>
                                <option value="" selected disabled>Niveau de la classe</option>
                                    {
                                        AffichageOption()
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-outline">
                                <label class="form-label" for="firstName">CLASSE</label>
                                <input type="text" id="nomclasse" class="form-control form-control-lg" value={nom}  />                          
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {children}
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <button className="btn btn-primary" type="submit" name="soumettre" id="submit">Submit</button>
                        </div>
                    </div>

                </form>
            </div>
            </>
        )
    }
    export default Formulaire;