import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Formulaire from '../../../ModelesPages/Modele1/FormulaireClasse_initiale'
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { getsession } from '../../../../actions/user.action';
import { getCookie } from '../../../../Utilities/Utils';

function Insertion(){
    const [Count,setCount]=useState(0);
    const [sections,setSections]=useState([])
    const [section,setSection]=useState("")
    const [niveau,setNiveau]=useState("")
    const [nom,setNom]=useState("")
    const [data,setData]=useState([])
    const record=sections.filter(secti=>secti.id==section)
    const dispatch=useDispatch()
    const csrf=()=>axios.get('sanctum/csrf-cookie')
    useEffect(()=>{            

        HandleRequest()
    },[])
 
   
   
    const HandleRequest=async()=>{
        await axios.get('/api/Section').then((res)=>{
            setSections(res.data.items)
        }).catch((err)=>{
        })
    }
    function confirmSave(e){
        e.preventDefault()
        if(nom !=""){
        Swal.fire({
            title:'Demande de confirmation',
            text:'Voulez-vous vraiment enregistrer ce cours ?',
            icon:'question',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            }
        })  
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord confirmer le nom du cours',
                icon:'warning'
            })
        }
    }
    const handleChangeSection=(e)=>{
        setSection(e)
      setData(record[0])
       if(niveau==1){
        setNom(niveau+'ère '+data?.nom)
       }
       else{
        setNom(niveau+'ème '+data?.nom)
       }
       
    }
    function handleBlurSection(e){
       setSection(e)
       setData(record[0])
       if(niveau==1){
        setNom(niveau+'ère '+data?.nom)
       }
       else{
        setNom(niveau+'ème '+data?.nom)
       }
       
    }
    function handleChangeNiveau(e){
        setNiveau(e)
       setData(record[0])
       if(niveau==1){
            setNom(niveau+'ère '+data?.nom)
           }
           else{
            setNom(niveau+'ème '+data?.nom)
           }
        
    }
    function handleBlurNiveau(e){
        setNiveau(e)
        
        setData(record[0])
        if(niveau==1){
            setNom(niveau+'ère '+data?.nom)
           }
           else{
            setNom(niveau+'ème '+data?.nom)
           }
     }
    const HandleSubmit=async (e)=>{
                await axios.post('/api/classeinitiale',{nom:nom.toLowerCase(),section:section,niveau:niveau}).then((res)=>{
                    setNom("")
                    setNiveau("")
                    setSection("")
                    if(res.data.status_code===200){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:'La classe a été enregistrée avec succès',
                            icon:'success'
                            
                        })
                    }
                    else{
                        Swal.fire({
                            title:'Message d\'erreur',
                            text:res.data.status_message,
                            icon:'error'
                            
                        })
                    }
                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    return(
    <>
    <Formulaire title="Formulaire d'inscription" handleBlurNiveau={handleBlurNiveau} handleBlurSection={handleBlurSection} confirmSave={confirmSave} setNom={setNom}   nom={nom}   Count={Count} setCount={setCount} HandleSubmit={HandleSubmit} niveau={niveau} setNiveau={setNiveau} section={section} setSection={setSection} sections={sections} setSections={setSections} handleChangeNiveau={handleChangeNiveau} handleChangeSection={handleChangeSection} >
        <div className="w-full md:w-1/1 px-3  md:mb-0">
        
        <div id="resultat" className="block text-red-700"></div>
        <div id="erreur" className="block text-red-500"></div>
    </div>
    </Formulaire>
    </>
    )
}
export default Insertion