import { useParams } from "react-router-dom";
import axios from "../../../../api/axios";
import { useState } from "react";


function DeleteEncodage(){

const [encodages,setEncodage]=useState([])
const [dateevaluation,setDateevaluation]=useState("")
//const evaluations=encodages.filter()
const {id}=useParams()

return(
    <>
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Suppression d'un encodage</h3>
                </div>
                <div className="card-body">
                    <form >
                        <div className="col-md-12">
                            <div className="form-outline">
                                <label htmlFor="" className="form-label">Date de l'évaluation</label>
                                <select name="dateevaluation" id="" className="form-select form-select-lg" value={dateevaluation} onChange={(e)=>setDateevaluation(e.target.value)}>
                                    <option value="" selected disabled>Choisir la date de l'évaluation</option>
                                </select>
                            </div>
                        </div>
                      
                    </form>
                </div>
                 </div>
            </div>
        </div>
    </div>
       
    </>
)
}
export default DeleteEncodage