import { useDispatch } from "react-redux"
import axios from "../api/axios"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import CryptoJS from 'crypto-js';
import { sessionuser } from "../actions/user.action";


export const isEmpty=(value)=>{
    return(
        value===undefined || value===null || (typeof value==="object" && Object.keys(value).length===0) || (typeof value==="string" && value.trim().length===0)
    )
}
export const getCookie=(name)=>{
    const cookies=document.cookie.split(';')
    const value=cookies.find(c=>c.startsWith(name))?.split('=')[1]
      if(value===undefined){
        return null
      }
      return decodeURIComponent(value)
  }
  /**
 * @param {string} name 
 * @param {string} value 
 * @param {number} days 
 */
export const setcookie=(name,value,days)=>{
    const date=new Date()
   date.setDate(date.getDate()+days)
   //document.cookie=`${name}=${encodeURIComponent(value)};expires=${date.toUTCString()};`
   document.cookie=`${name}=${encodeURIComponent(value)};max-age=600`
  }
  export const removecookie=(name)=>{
    const date=new Date()
    date.setDate(date.getDate()-1)
    document.cookie=`${name}="";expires=${date.toUTCString()};`
  }
  export const removedataclient=()=>{
    removecookie("sygenacote_session")
   removecookie("XSRF-TOKEN")
    removecookie("gesco_inf")
    localStorage.removeItem('id')
    localStorage.removeItem('email')
    localStorage.removeItem("lastpage")
    localStorage.removeItem("idprogramme")
    localStorage.removeItem("idenseignant")
    localStorage.removeItem("idecole")
    localStorage.removeItem("annee_scolaire")
    sessionStorage.removeItem("lastpage")
    localStorage.removeItem("guest")
    localStorage.removeItem("ideleve")
    localStorage.removeItem("navbar")
    sessionStorage.removeItem("id")
    localStorage.removeItem("username")
  }
  export const setdataclient=()=>{
    
  }
 export const session_callback=()=>{
  const session_user=false
  return !isEmpty(localStorage.getItem("email")) && !isEmpty(localStorage.getItem("id")) && !isEmpty(getCookie("gesco_inf")) ?  !session_user : session_user 
    //return(dispatch(getsession(localStorage.getItem('email'),localStorage.getItem("id"),getCookie("gesco_inf"))))
  
 }
export const encryptdata=(input,secretKey)=>{
  const encryptedData = CryptoJS.AES.encrypt(input, secretKey).toString();
  return (encryptdata)
}
export const decryptdata=(input,secretKey)=>{
  const bytes = CryptoJS.AES.decrypt(input, secretKey);
  const originalData = bytes.toString(CryptoJS.enc.Utf8);
  return (originalData)
}
export const checkCookie=async (dispatch)=>{
  await axios.get("api/verifie-cookie").then((res)=>{
    if(res.data.valid){
      dispatch(sessionuser())
    }      
  }).catch((err)=>{
    console.log(err)
  })
}