import axios from "../api/axios"

export const ADD_SECTIONS="ADD_SECTIONS"
export const GET_SECTIONS="GET_SECTIONS"

export const addsection=(data)=>{
    return (dispatch)=>{
        return axios.post('/api/Sectionorganisee',{data:data}).then((res)=>{
            dispatch({type:ADD_SECTIONS,payload:data})
        }).catch((err)=>{

        })
    }
}
export const getsection=(idecole)=>{
    return(dispatch)=>{
        return axios.get(`/api/sectionorg/${idecole}`).then((res)=>{
            dispatch({type:GET_SECTIONS,payload:res.data.items})

        }).catch((err)=>{

        })
    }
}