import { Link } from "react-router-dom"
function Body(){
    
    return (
        <>
        <main class="page landing-page">
            <section class="clean-block clean-hero" style={{backgroundImage:"url(assets/img/autochtones/image2.png)"}}>
                <div class="text">
                    <h2>Système de gestion nationale des cotes</h2>
                    <p>Les études est un moyen adéquat pour l'émergence de notre pays, pour nous rassurer de son réel impact sur notre développement, commençons d'abord par nous assurer de la véracité du parcour de nos élèves</p>
                    <Link to="/register" class="btn btn-outline-primary btn-lg">Créez votre école ici</Link>
                </div>
            </section>
            <section class="clean-block clean-info dark" >
                <div class="container">
                    <div class="block-heading">
                        <h2 class="text-info">Futures</h2>
                        <p>Les jours ne passent sans que nous ne pensions à eux, ils sont inestimables de part de leur bonté et de leur manière si impressionante de vivre. Ainsi, nous provoyons :</p>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <img class="img-thumbnail" src="assets/img/eleves/images1.png"/>
                        </div>
                        <div class="col-md-6">
                            <h3>Louage des terres cultivables</h3>
                            <div class="getting-started-info">
                                <p>Au cours de ce mois, nous prevoyons octroyer des terrains aux autochtones pour leur permettre une bonne cultivation en leur portant de même une assistance pour la bonne croissance de leur semence. il faudra pour ce faire, trouver les moyens nécessaires pour louer autant d'hectares que possible que nous leur distribuerons après et faire aussi l'achat des semences et des angrais qu'ils recevrons de nos mains après redistribution des champs</p>
                            </div>
                            <div class="justify-content-center ">
                                <button class="btn btn-outline-primary btn-lg mx-2" type="button">Join Now</button>
                                <button class="btn btn-outline-success btn-lg mx-2" type="button">Learn More</button>    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="clean-block features dark"><div class="container">
                <div class="block-heading">
                    <h2 class="text-info">Nos recentes réalisations</h2>
                    <p>On ne se lassera pas de leur venir en aide d'autant qu'ils ne se lasseront pas de même de nous étonner de part de leur personnalité et de l'autre part de leur sympathie. </p>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6 feature-box">
                        <div class="card text-center clean-card" >

                                <img class="card-img-top w-100 d-block" src="assets/img/autochtones/champ1.png"/>
                            <div class="card-body">
                                    
                                
                                    <h4 class="card-title">Alphabétisation des autochtones</h4>
                                    <p class="card-text">Une mission réalisée avec succès au cours du quel tous les autochtones se sentent compris, aimés et soutenus. cette mission nous a fait comprendre combien le bien que nous faisons laisses de trace qu'importe à qui vous le faites et comment vous le faites.</p>    
                            
                            </div>
                            <div class="justify-content-center mx-auto mb-4">
                                <button class="btn btn-outline-success">Commentaires</button>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-md-6 feature-box">
                        <div class="card text-center clean-card" >

                                <img class="card-img-top w-100 d-block" src="assets/img/autochtones/champ1.png"/>
                            <div class="card-body">
                                    
                                
                                    <h4 class="card-title">Alphabétisation des autochtones</h4>
                                    <p class="card-text">Une mission réalisée avec succès au cours du quel tous les autochtones se sentent compris, aimés et soutenus. cette mission nous a fait comprendre combien le bien que nous faisons laisses de trace qu'importe à qui vous le faites et comment vous le faites.</p>    
                            
                            </div>
                            <div class="justify-content-center mx-auto mb-4">
                                <button class="btn btn-outline-success">Commentaires</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
        </div>
    </section><section class="clean-block slider dark"><div class="container">
        <div class="block-heading"><h2 class="text-info">Différents moments avec les autochtones</h2>
            <p>Ils existent aussi, ne les excluons pas car ils font partis des humains remplissant cette terre, ils sont à nos côtes, ils sont là, pas très loin de nous. Rapprochons-nous d'eux</p>
        </div>
        <div class="carousel slide" data-bs-ride="carousel" id="carousel-1">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="w-100 d-block" src="assets/img/autochtones/image2.png" alt="Slide Image"/>
                 <div class="carousel-caption   text-light">
                    <h4>L'Agriculture au centre du monde</h4>
                 </div>
                </div>
                <div class="carousel-item">
                    <img class="w-100 d-block" src="assets/img/autochtones/image3.png" alt="Slide Image"/>
                    <div class="carousel-caption  text-light">
                        <h4>Aucune vie sans agriculture</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <img class="w-100 d-block" src="assets/img/autochtones/image1.png" alt="Slide Image"/>
                    <div class="carousel-caption d-positive  text-light py-auto">
                        <h4>Tout le monde a droit à la vie</h4>
                    </div>

                </div>
            </div>
            <div><a class="carousel-control-prev" href="#carousel-1" role="button" data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span><span class="visually-hidden">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carousel-1" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
                <span class="visually-hidden">Next</span></a></div><div class="carousel-indicators"><button type="button" data-bs-target="#carousel-1" data-bs-slide-to="0" class="active"></button> 
                    <button type="button" data-bs-target="#carousel-1" data-bs-slide-to="1"></button> 
                    <button type="button" data-bs-target="#carousel-1" data-bs-slide-to="2"></button>
                </div>
            </div>
        </div>
    </section>
    <section class="clean-block about-us dark">
        <div class="container"><div class="block-heading"><h2 class="text-info">About Us</h2>
        <p>Nos membres sont les plus convivials que vous puissiez rencontrés, ils sont ce que l'on puisse trouver de mieux pour se sentir heureux</p>
    </div>
    <div class="row justify-content-start col-md-justify-content-center">
        <div class="col-sm-6 col-lg-4">
            <div class="card text-center clean-card">
                <img class="card-img-top w-100 d-block" src="assets/img/avatars/jcaV2.png"/>
                <div class="card-body info">
                    <h4 class="card-title">Jean-Claude Akonkwa</h4>
                    <p class="card-text"><span class="text-bold"><strong> IT Manager </strong></span> for our association is the one to whom count when you are in murders because he will be around to save sitution. No comment about him</p>
                <div class="icons"><a href="#"><i class="icon-social-facebook">

                </i></a><a href="#"><i class="icon-social-instagram">

                </i></a><a href="#"><i class="icon-social-twitter"></i></a>
            </div>
        </div>
    </div>
</div>
<div class="col-sm-6 col-lg-4">
    <div class="card text-center clean-card">
        <img class="card-img-top w-100 d-block" src="assets/img/avatars/jcav3.png"/>
        <div class="card-body info">
            <h4 class="card-title">bonheur M</h4>
            <p class="card-text"><strong> Our big Human resource</strong>, y'll never see a nother kind of this chief who taking care of his owns. Sometimes he makes you confuse, you still asking about how he does this and like this </p>
            <div class="icons">
                <a href="#"><i class="icon-social-facebook"></i></a><a href="#"><i class="icon-social-instagram"></i></a>
                <a href="#"><i class="icon-social-twitter"></i></a>
            </div>
        </div>
    </div>
</div>
<div class="col-sm-6 col-lg-4">
    <div class="card text-center clean-card">
        <img class="card-img-top w-100 d-block" src="assets/img/avatars/avatar3.jpg?h=4c77b34bb68eaede08fdd5dfec8752b0"/>
        <div class="card-body info"><h4 class="card-title">Lysa Barns</h4>
            <p class="card-text"><strong> Communication Manager.</strong> The one best of their field, any more you will find like her. she comminicates with you as if she is reading your mind</p>
            <div class="icons">
                <a href="#"><i class="icon-social-facebook"></i></a>
                <a href="#"><i class="icon-social-instagram"></i></a>
                <a href="#"><i class="icon-social-twitter"></i></a>
            </div>
        </div>
    </div>
</div>
</div>
</div>
<div class="row justify-content-center mt-4 mx-auto">
    <div class="col-md-2">
        <button class="btn btn-outline-primary btn-lg mx-auto">Know more</button>

    </div>
</div>
</section>
</main>

        </>
    )
}
export default Body