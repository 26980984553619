import React from "react";
import axios from "../../../../../api/axios";
import { isEmpty } from "../../../../../Utilities/Utils";
import {Document,Page,View,Text,Font,StyleSheet,PDFViewer } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";


 const Mytableau=({classe,periode,cour,moyenne,dateformate,somme,reinitialisation,nbrevaluation,maximumEval,total,ecole,coursencours,recordseleves,recordmyevaluations,detailcotes,totalmax})=>{
    const columnWidth=65/(nbrevaluation+2)
    const styles = StyleSheet.create({
        page: {
          flexDirection: 'column',
          backgroundColor: '#fff',
          padding: 10
        },
        table: {
          display: "table",
          width: "auto",
          borderStyle: "solid",
          borderWidth: 1,
          borderRightWidth: 0,
          borderBottomWidth: 0
        },
        tableRow: {
          margin: "auto",
          flexDirection: "row"
        },
        tableColHeader: {
           
        width: `${columnWidth}%`,
          borderStyle: "solid",
          borderWidth: 1,
          borderLeftWidth: 0,
          borderTopWidth: 0,
          backgroundColor: '#f3f3f3'
        },  
        tableColHeaderEleve: {
           
            width: "30%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#f3f3f3'
          },
          tableColHeaderNum: {
           
            width: "5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#f3f3f3'
          },
        tablColEntete:{
            margin:"auto",
            width:"100%",
            borderStyle:"solid",
            borderWidth:1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            fontWeight:"bold",
        },
        tablColousEntete:{
            margin:"auto",
            width:"50%",
            borderStyle:"solid",
            borderWidth:1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableCol: {
        width: `${columnWidth}%`,
          borderStyle: "solid",
          borderWidth: 1,
          borderLeftWidth: 0,
          borderTopWidth: 0
        },
        tableColEleve: {
            width: "30%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
          },
          tableColNum: {
            width: "5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
          },
        tableCellHeaderEntete: {
            margin: "auto",
            margin: 5,
            fontSize: 12,
            fontWeight: 500,
            textAlign:"center"
          },
        tableCellHeader: {
          margin: "auto",
          margin: 5,
          fontSize: 12,
          fontWeight: 500
        },
        tableCellHeader2: {
            margin: "auto",
            margin: 5,
            fontSize: 10,
            fontWeight: 400
          },
        tableCell: {
          margin: "auto",
          margin: 5,
          fontSize: 10
        }
      });
    
    return(
        <>
            <Document >
            <Page size="A4" style={styles.page}>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tablColEntete}><Text style={styles.tableCellHeaderEntete}>{ecole?.[0]?.nom.toUpperCase()}</Text></View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tablColousEntete}>
                        <Text style={styles.tableCellHeader}>Enseignant : {coursencours?.[0]?.enseignant}</Text>
                        <Text style={styles.tableCellHeader}>Cours : {coursencours?.[0]?.cours}</Text>
                        <Text style={styles.tableCellHeader}>Classe : {coursencours?.[0]?.my_classe} </Text>s
                        <Text style={styles.tableCellHeader}>Nbre d'élève : {recordseleves?.length}</Text>
                    </View>
                    <View style={styles.tablColousEntete}>
                        <Text style={styles.tableCellHeader}>Période : {periode}</Text>
                        <Text style={styles.tableCellHeader}>Ponderation : {(periode==="Examen1" || periode==="Examen2") ? coursencours?.[0]?.ponderation*2 : coursencours?.[0]?.ponderation}</Text>
                        <Text style={styles.tableCellHeader}>Nombre d'évaluation : {nbrevaluation}</Text>
                        <Text style={styles.tableCellHeader}>Maximum évaluation :{maximumEval()} </Text>
                    </View>

                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeaderNum}><Text style={styles.tableCellHeader2}>#</Text></View>
                    <View style={styles.tableColHeaderEleve}><Text style={styles.tableCellHeader2}>Elève</Text></View>
                    {
                                recordmyevaluations.map((evaluation)=>{totalmax=somme(parseInt(evaluation.ponderation))
                                    return(
                                        <>
                                          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader2}>{dateformate(evaluation.date_evaluation)} <br/>{"Max:"+evaluation.ponderation}</Text></View>

                                        </>
                                    )
                                }
                                  
                                )
                             
                            }
                    <View style={styles.tableColHeader}><Text style={styles.tableCellHeader2}>Total <br /> /{totalmax} </Text></View>
                  

                    <View style={styles.tableColHeader}><Text style={styles.tableCellHeader2}>Moyenne <br /> /{(periode==="Examen1" || periode==="Examen2") ? coursencours?.[0]?.ponderation*2 : coursencours?.[0]?.ponderation}</Text></View>
                    {reinitialisation()}
                </View>
            {
                            recordseleves.map((elev,index)=>
                                   {
                                    return(
                                        <>
                                            {
                                                <View style={styles.tableRow} key={index}>
                                                    <View style={styles.tableColNum}><Text style={styles.tableCell}>{index+1}</Text></View>
                    
                                                    <View style={styles.tableColEleve}><Text style={styles.tableCell}>{elev.nom_complet}</Text></View>
                                                 {
                                                 recordmyevaluations.map((evaluation)=>
                                                 {
                                                     return(
                                                         <>
                                                         {
                                                              detailcotes.filter((detail)=>detail.idinscription==elev.id && detail.idcour==cour && detail.date_evaluation==evaluation.date_evaluation && detail.created_at==evaluation.created_at).map((det)=>{total=somme(parseInt(det.point))
                                                                 return(
                                                                     
                                                                     <>
                                                                         <View style={styles.tableCol}><Text style={styles.tableCell}>{det.point}</Text></View>
                                                                     </>
                                                                 )
                                                             })
                                                         }
                                                        
                                                         </>
                                                     )
                                                 }
                                                 )}
                                                 
                                                  <View style={styles.tableCol}><Text style={styles.tableCell}>{total}</Text></View>
                                                  <View style={styles.tableCol}><Text style={styles.tableCell}>{ !isEmpty(periode) && moyenne(parseInt(total),parseInt(totalmax),parseInt(coursencours?.[0]?.ponderation))}</Text></View>
                                                  {reinitialisation()}
                                            </View>
                                           
                                             }
                                                                           
                                        </>
                                    )
                            
                                })
                        }
            </View>
            </Page>
        </Document>
        </>
    )
 }
export default Mytableau
