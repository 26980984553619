import axios from '../../../../api/axios'
import React,{useState,useEffect} from 'react'
import {useParams} from 'react-router-dom'
import Formulaire from '../../../ModelesPages/Modele1/Formulaire'
import Sweetalert2 from 'react-sweetalert2'
import {useNavigate} from 'react-router-dom'


function Update(){
    const [eleve,setEleve]=useState([])
    const [adresse,setAdresse]=useState("");
    const [Count,setCount]=useState(0);
    const [lieu_naissance,setLieu_naissance]=useState("")
    const [date_naissance,setDate_naissance]=useState("")
    const [nom,setNom]=useState("");
    const [postnom,setPostnom]=useState("");
    const [prenom,setPrenom]=useState("");
    const [sexe,setSexe]=useState("");
    const [numero_serni,setNumero_serni]=useState("");
    const [nom_complet,setNom_complet]=useState("")
    const [status_code,setStatus_code]=useState()
    const [status_message,setStatus_message]=useState("")
    const [swalProps,setSwalProp]=useState({})
    const navigate=useNavigate()
    function HandleAlert(){
        setSwalProp({
            show:true,
            title:'Mis à jour',
            icon:'success',
            didClose:()=>{
                navigate('/eleve')
            }
        })
    }
    const {id}=useParams()
    useEffect(()=>{
         HandleRequest()
    },[])
    
    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    const HandleSubmit= async (e)=>{
        e.preventDefault()
        await axios.put(`api/eleves/${id}`,{nom:nom.toLowerCase(),postnom:postnom.toLowerCase(),prenom:prenom.toLowerCase(),sexe:sexe,adresse:adresse.toLowerCase(),lieu_naissance:lieu_naissance.toLowerCase(),date_naissance:date_naissance,numero_serni:numero_serni,nom_complet:nom_complet.toLowerCase()}).then((res)=>{
            setStatus_message(res.data.status_message)
            setStatus_code(res.data.status_code)
                if(status_code===200){
                   {HandleAlert()}
                  
               }
        }).catch((err)=>{
            console.log(err)
        })
    }
   const HandleRequest=async ()=>{
        await axios.get(`api/eleves/${id}`).then((res)=>{
           console.log(res)
           if(res.data.status_code === 200) {
                setStatus_code(res.data.status_code)
                setNom(res.data.eleve.nom)
                setPostnom(res.data.eleve.postnom)
                setPrenom(res.data.eleve.prenom)
                setSexe(res.data.eleve.sexe)
                setAdresse(res.data.eleve.adresse)
                setLieu_naissance(res.data.eleve.lieu_naissance)
                setDate_naissance(res.data.eleve.date_naissance)
                setNom_complet(res.data.eleve.nom_complet)
                setNumero_serni(res.data.eleve.Numero_serni)
                
           }
           else{

           } 
          
        }).catch((err)=>{
            console.log(err)
        })
    }
    const RenderData=()=>{
           if(status_code===200){
                return(
                    <Formulaire title="Formulaire de Mise à jour" onclick={handleclick} setNom={setNom} handlekeyUpgain={handlekeyUpgain} setPostnom={setPostnom} setPrenom={setPrenom} setSexe={setSexe} setDate_naissance={setDate_naissance} setLieu_naissance={setLieu_naissance} nom={nom} postnom={postnom} prenom={prenom} sexe={sexe} date_naissance={date_naissance} lieu_naissance={lieu_naissance} handleMouse={handleMouse} Count={Count} setCount={setCount} handleMouseleave={handleMouseleave} HandleSubmit={HandleSubmit} setNumero_serni={setNumero_serni} numero_serni={numero_serni} adresse={adresse} setAdresse={setAdresse} setNom_complet={setNom_complet} >
                    <div className="w-full md:w-1/1 px-3  md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            NOM COMPLET : <span class="obligatoire">*</span>
                        </label>
                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Akonkwa Ganywamulume Jean-Claude" name="nom_complet" value={nom_complet} />
                    <div id="resultat" className="block text-red-700"></div>
                    <div id="erreur" className="block text-red-500"></div>
                    </div>
                </Formulaire>
                )
                
         }
    }
   return(
        <>
        <div className="">
            <h1 className='text-primary'>
                Eleve N° : {id}
            </h1>
        </div>
          
            <div>
                {RenderData()}
            </div>
           <Sweetalert2 {...swalProps}>
                    <h3 className='text-primary'>
                        {status_message}
                    </h3>
           </Sweetalert2>
          
        </>
    )
}
export default Update