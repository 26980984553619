import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Formulaire from '../../../ModelesPages/Modele1/FormulaireClasse_initiale'
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { getsession } from '../../../../actions/user.action';
import { getCookie } from '../../../../Utilities/Utils';

function Insertion(){
    const [sections,setSections]=useState([])
    const [section,setSection]=useState("")
    const [niveau,setNiveau]=useState("")
    const [lettre,setLettre]=useState("")
    const [nom,setNom]=useState("")
    const [classe_initiales,setClasses_initiales]=useState([])
    const [data,setData]=useState([])
    const record=sections.filter((secti)=>{return secti.id==section})
    const dispatch=useDispatch()
    const datainitial=classe_initiales.filter(clas=>clas.idsection==section & clas.niveau==niveau)
    
    const csrf=()=>axios.get('sanctum/csrf-cookie')
    useEffect(()=>{            
        HandleRequest()
        Classes_initialesRequest()
    },[])
    const HandleRequest=async()=>{
        await axios.get('/api/Section').then((res)=>{
            setSections(res.data.items)
        }).catch((err)=>{
        })
    }
    const Classes_initialesRequest=async ()=>{
        await axios.get('/api/classeinitiale').then(
            (res)=>{
                setClasses_initiales(res.data.items)
               
            }
        ).catch((err)=>{
            console.log(err)
        })
    }
    function confirmSave(e){
        e.preventDefault()
        if(nom !=""){
        Swal.fire({
            title:'Demande de confirmation',
            text:'Voulez-vous vraiment enregistrer ce cours ?',
            icon:'question',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            }
        })  
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord confirmer le nom du cours',
                icon:'warning'
            })
        }
    }
    function AffichageOption(){
        if(section==3){
            return(
                <>
                    <option value="7">7ème </option>
                    <option value="8">8ème</option>
                </>
                
            )
            
            
        }
        if(section!="" && section!=3){
            return(
                <>
                    <option value="1">1ère</option>
                    <option value="2">2ème</option>
                    <option value="3">3ème</option>
                    <option value="4">4ème</option>
                </>
                
            )
            
        }
      }
    const handleChangeSection=(e)=>{
        setSection(e)
    }
    const handleBlurLettre=(e)=>{
        setLettre(e)
        setData(record[0])
            if(niveau==1){
                setNom(niveau+'ère '+data?.nom+"/"+lettre)
            }
            else{
                setNom(niveau+'ème '+data?.nom+"/"+lettre)
            }     
    }
    function handleBlurSection(e){
       setSection(e)
       setData(record[0])
       if(niveau==1){
        setNom(niveau+'ère '+data?.nom+"/"+lettre)
       }
       else{
        setNom(niveau+'ème '+data?.nom+"/"+lettre)
       }
    }
    function handleChangeNiveau(e){
        setNiveau(e)
       setData(record[0])
        
    }
    function handleBlurNiveau(e){
        setNiveau(e)
        
        setData(record[0])
        if(niveau==1){
            setNom(niveau+'ère '+data?.nom+"/"+lettre)
           }
           else{
            setNom(niveau+'ème '+data?.nom+"/"+lettre)
           }
     }
    const HandleSubmit=async()=>{
       const table=[
            {
                classe:section,
                nbclasse:parseInt(1),
                nomclasse:nom,
                niveau:niveau,
                classe_initiale:datainitial[0]?.id,
                lettre:lettre
            }
        ]
        const dataform=([
            {
                'idsection':parseInt(section),
                'idecole':parseInt(localStorage.getItem("idecole")),
                'annee_scolaire':localStorage.getItem("annee_scolaire"),
                'items':table
            }  ])
                await axios.post('/api/classe',{data:dataform}).then((res)=>{
                    setNom("")
                    setNiveau("")
                    setSection("")
                    setLettre("")
                    if(res.data.status_code==200){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:'La classe a été enregistrée avec succès',
                            icon:'success'
                        })
                    }
                    else{
                        Swal.fire({
                            title:'Message d\'erreur',
                            text:res.data.status_message,
                            icon:'error'
                        })
                    }                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    return(
    <>
    <div className="container align-items-center">
                <form  onSubmit={confirmSave}>
                    <legend>
                        <h3 className="font-bold text-blue-500 mb-4 text-xl">
                            FOrmulaire d'ajout des classes
                        </h3>
                    </legend>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-outline">
                                <label class="form-label" for="firstName">Section</label>
                                <select name="section" id="section" className="form-select form-select-lg" onChange={(e)=>handleChangeSection(e.target.value)} value={section} onBlur={(e)=>handleBlurSection(e.target.value)} required>
                                <option value="" selected disabled>Choix de la section</option>
                                {
                                    sections.map((sect)=>
                                        <option value={sect.id} key={sect.id} >{sect.nom}</option>
                                    )
                                }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-outline">
                                <label class="form-label" for="firstName">NIVEAU</label>
                                <select name="section" id="section" className="form-select form-select-lg" value={niveau} onChange={(e)=>handleChangeNiveau(e.target.value)} onBlur={(e)=>handleBlurNiveau(e.target.value)} required>
                                <option value="" selected disabled>Niveau de la classe</option>
                                    {
                                        AffichageOption()
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                  
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-outline">
                                <label htmlFor="lettre" className="form-label">Lettre</label>
                                <select name="lettre" id="" className="form-select form-select-lg" value={lettre} onChange={(e)=>setLettre(e.target.value)} onBlur={(e)=>handleBlurLettre(e.target.value)} required>
                                    <option value="" selected disabled>Choix de la lettre</option>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                    <option value="E">E</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-outline">
                                <label class="form-label" for="firstName">CLASSE</label>
                                <input type="text" id="firstName" class="form-control form-control-lg" value={nom} required/>                          
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <button className="btn btn-primary" type="submit" name="soumettre" id="submit">Submit</button>
                        </div>
                    </div>

                </form>
            </div>
    </>
    )
}
export default Insertion