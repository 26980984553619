import { useSelector, useDispatch} from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { getCookie, isEmpty,removedataclient,checkCookie } from "../../Utilities/Utils"
import axios from "../../api/axios"
import {logoutuser} from '../../actions/user.action'
import { useState } from "react"
const Navbar=({userType})=>{

    const menus={
        Visiteur:["Nos écoles","Nos lauréats","Contacts","Connexion"],
        SuperAdmin:["Mes_Inspections","Mes_Ecoles","Mes_Cours","Mes_Sections","Mes_Classes","Mes_Cours-Classe","Programme"],
        Administrateur:["Sections","Classes","Cours","Elèves","Inscription","Enseignants","Délibération"],
        Enseignant:["Encodage","Proclamation","Fiches"]
    }
const navigate=useNavigate()
const userselector=useSelector((state)=>state.userReducer)
    const csrf=()=>axios.get('sanctum/csrf-cookie')
    const dispatch=useDispatch()
   // const [mytype_compte,setType_compte]=useState("")
const Logout=async()=>{
    try{
        dispatch(logoutuser())
        removedataclient()
      //  checkCookie(setType_compte)
        navigate("/")
    }
    catch(e){

    }
   
}

    return(
        <>
        {
            
            //!isEmpty(localStorage.getItem("navbar")) &&
           <nav className="navbar navbar-expand-lg  bg-dark clean-navbar navbar-dark mb-2">
            <div className="container">
                <Link className="navbar-brand logo" to="/">Gesco</Link>
                <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-1">
                    <span className="visually-hidden">Toggle navigation</span>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navcol-1">
                    <ul className="navbar-nav ms-auto">
                        {menus[userType].map((menu, index) => (
                            <li key={index} className="nav-item">
                                <Link className="nav-link" to={menu}>{menu}</Link>
                            </li> 
                        ))
                        
                        }
                        {
                            userType!=="Visiteur" &&
                            <li className="nav-item">
                                <button className="btn btn-danger" onClick={Logout}>Logout</button>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </nav>
        
        }
        </>
    )
}
export default Navbar