import axios from '../../../../api/axios'
import {useParams,useNavigate} from 'react-router-dom'
import {useEffect,useState} from 'react'
import Swal from 'sweetalert2'
import Sweetalert2 from 'react-sweetalert2'

import User from '../../../../Images/User.png'

function Suppression(){
    const {id}=useParams()
    const navigate=useNavigate()
    const [status_code,setStatus_code]=useState()
    const [nom,setNom]=useState('')
    const [postnom,setPostnom]=useState('')
    const [prenom,setPrenom]=useState('')
    const [sexe,setSexe]=useState('')
    const [adresse,setAdresse]=useState('')
    const [date_naissance,setDatenaissance]=useState('')
    const [lieu_naissance,setLieu_naissanc]=useState('')
    const [motif,setMotif]=useState('')
    const [swalProps,setSwalProp]=useState({})
    const [status_message,setStatus_message]=useState('')

    useEffect(()=>{
        handleRequest()
    })
    const handleRequest=async ()=>{
        await axios.get(`api/eleves/${id}`).then((res)=>{
            setStatus_code(res.data.status_code)
            setNom(res.data.eleve.nom)
            setPostnom(res.data.eleve.postnom)
            setPrenom(res.data.eleve.prenom)
            setSexe(res.data.eleve.sexe)
            setAdresse(res.data.eleve.adresse)
            setDatenaissance(res.data.eleve.date_naissance)
            setLieu_naissanc(res.data.eleve.lieu_naissance)
              
        }).catch((err)=>{
            console.log(err)
        })
    }
    function handleclick(e){
        e.preventDefault()
        motif === '' ?  
                Swal.fire({
                    title:'Erreur d\'exécution',
                    text:'Aucun motif de revoi n\a été fourni',
                    icon: 'error'
                })
        : 
        Swal.fire({
            title:'Finalisation Suppression',
            text:'Vous êtes sur le point de le supprimer definitivement',
            icon:'warning',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
            showLoaderOnConfirm:true,
        }).then((res)=>{
            if(res.isConfirmed){
                handledelete() 
            }
        })
    }
    const handledelete=async()=>{
        await axios.delete(`api/eleves/${id}`).then((res)=>{
            setStatus_code(res.data.status_code)
            setStatus_message(res.data.status_message)
            setSwalProp({
                show:true,
                title:'Suppression',
                text:{status_message},
                icon:'success',
                didClose:()=>{
                    navigate('/eleve')
                }
            })
                }).catch((err)=>{
                    console.log(err)
                })
    }
    
  
    return(
        <>
        
        {

           status_code === 200 ?
                <div className="card w-25 align-middle mx-auto mt-8" >
            <img src={User} className="card-img-top w-25 mt-2 mx-auto" alt="..."/>
            <div className="card-body">
                <h5 className="card-title text-primary text-center">{nom+' '+postnom+' '+prenom}</h5>
                <div className="card-text mx-0">
                    <ul className='mx-0'>
                        <li><span className=''>Sexe : </span>{sexe}</li>
                        <li><span>Adresse :</span>{adresse}</li>
                        <li><span>Date de naissance :</span>{date_naissance}  </li>
                        <li><span>Lieu de naissance :</span>{lieu_naissance}</li>
                    </ul>
                </div>
                <div className="">
                    <select name="motif" id="motif" className='form-control mx-2' onChange={(e)=>setMotif(e.target.value)}>
                        <option value="" selected disabled>Motif de suppression</option>
                        <option value="Renvoi definitif sur faute grave">Renvoi sur faute grave</option>
                        <option value="Abandon de l'élève">Abandon de l'élève</option>
                        <option value="Triplement de la classe">Triplement de la classe</option>
                        <option value="Renvoi sur absences exagérées non justifiées">Renvoi sur absences exagérées non justifiées</option>
                        <option value="Manque de niveau de l'élève">Manque de niveau de l'élève</option>
                        <option value="Réorientation de l'élève ailleurs">Réorientation de l'élève ailleurs</option>
                    </select>
                </div>
            </div>
            
            <div className="card-footer">
                <a href="#" className="card-link btn btn-danger px-2 mr-12" onClick={handleclick}>Supprimer</a>
                <a href="#" className="card-link btn btn-primary px-2 mr-2" onClick={()=>navigate('/eleve')}>Annuler</a>
            </div>
            </div>
            :
            <div className="">
                <h3 className='text-danger'>
                    Aucun enregistrement correspondant!
                </h3>
            </div>
            }

            <Sweetalert2 {...swalProps}>
                    <h3 className='text-primary'>
                        {status_message}
                    </h3>
           </Sweetalert2>
        </>
    )
}
export default Suppression