import { combineReducers } from "@reduxjs/toolkit";
import classeReducer from "./classe.reducer"
import userReducer from "./user.reducer"
import sectionReducer from './section.reducer'
import eleveReducer from "./eleve.reducer";

export default combineReducers({
   
    userReducer,
  
    
})