import axios from '../../../../api/axios';
import React, {useState,useEffect} from 'react'
import {EyeFill,PencilFill,Trash3Fill,PersonPlus,HouseAddFill} from "react-bootstrap-icons"
import { useNavigate,useParams } from 'react-router-dom';
import loadingIcon from '../../../../Images/loading.gif'
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { getsession } from '../../../../actions/user.action';
import { getCookie } from '../../../../Utilities/Utils';
function Ecole(){
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const [ecoles,setEcoles]=useState([])
    const [loading,setLoading]=useState(false)
    const [curentpage,setCurentpage]=useState(1)
    const [npperpage,setNbrperpage]=useState(20)
    const lastIndex=npperpage * curentpage
    const firstIndex=lastIndex-npperpage 
    const [search,setSearch]=useState('')
    const csrf=()=>axios.get('sanctum/csrf-cookie')
   // const records=search.toLowerCase() === '' ? ecoles.slice(firstIndex,lastIndex) : ecoles.filter((ecole)=>{return ecole.nom.toLowerCase().includes(search)}).slice(firstIndex,lastIndex)
    const records= search.toLowerCase() === '' ? ecoles.slice(firstIndex, lastIndex) : ecoles.filter((ecole)=>{return search.toLowerCase() === '' ? ecole : ecole.nom.toLowerCase().includes(search)}).slice(firstIndex, lastIndex)
    useEffect(()=>{
            HandleRequest()
          //  dispatch(getsession(localStorage.getItem('email'),localStorage.getItem("id"),getCookie("gesco_inf")))

    },[])
    const HandleRequest=async()=>{
        setLoading(true)

        await axios.get('api/ecole').then((res)=>{
            setLoading(false)
            console.log(res)
            setEcoles(res.data.items)
        }).catch((err)=>{
        })
    }
    function Iteration(){
        return loading ? (
            <div className='w-100 mx-auto'>
              <center>  <img src={loadingIcon} alt='loading' className='align-middle'/></center>
            </div>
        )   : (
            
            records.filter((ecole)=>{
                return search.toLowerCase() === '' ? ecole : ecole.nom.toLowerCase().includes(search)
            }).map((ecole,indexV)=>
            {
              return(
                    <tr key={indexV} className='ligne'>
                        <td>{indexV+1}</td>
                        <td>{ecole.nom.toLowerCase()}</td>
                        <td>{ecole.province.toLowerCase()}</td>
                        <td>{ecole.province_educationnelle}</td>
                        
                        <td>{ecole.ville}</td>
                        <td>{ecole.adresse.toLowerCase()}</td>

                        <td>{ecole.Numero_agreement}</td>
                        <td>{ecole.nom_complet}</td>

                        <td>{ecole.etat}</td>
                        <td><EyeFill color="lightgreen" size={20} className='d-inline-flex mx-2 icone'  /><PencilFill color="royalblue" size={20} className='d-inline-flex mx-2 icone' onClick={()=>navigate(`${ecole.id}`)}/>  <Trash3Fill color='brown' size={20} className='d-inline-flex mx-2 icone' onClick={()=>AlertNotification(ecole.id)} /> </td>
                    </tr>
              )
                
            }
            )
        
    )
        
       }
       function AlertNotification(idecole){
        Swal.fire({
            title:'Suppression d\'une école ',
            text:'Etes-vous de vouloir supprimer cette école ?',
            icon:'warning',
            showDenyButton:true,
            showCancelButton:true,
            confirmButtonText:'Confirmer',
            denyButtonText:'Annuler'
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                navigate(`/ecole/suppression/${idecole}`)
                    }
        })
    }
    console.log(ecoles)
    return(
        <>
            <div className="bg-primary text-light py-8 px-2">
                <div className="d-inline-flex w-75">
                    <h4 className='d-inline-flex w-50 font-weight-bold'>Liste des écoles du système (Total : {ecoles.length})</h4>
                <div className="d-inline-flex w-50 mx-auto text-center">
                    <button name="ajouter" id="ajouter" onClick={()=>navigate('insertion')} className='btn btn-success d-inline-flex w-75 align-middle mx-auto  ' ><span className='text-center'> <HouseAddFill color='white' size={25} className='d-inline-flex' /> Ajouter une école </span></button>

                </div>
                </div>
                <div className="d-inline-flex w-25 mx-auto px-2">
                    <input type="text" name='search' id='search' placeholder='Rechercher une école' className='form-control' value={search} onChange={(e)=>setSearch(e.target.value.toLowerCase())} />
                </div>
            </div>
            <div className="w-100 mx-auto px-4 mt-2">
                <table className='table table-straped'>
                    <thead className='thead-dark'>
                        <tr className=''>
                            <th>#</th>
                            <th>Ecole</th>
                            <th>Province</th>
                            <th>Province éducationnelle</th>
                            <th>Ville</th>
                            <th>Adresse</th>
                            <th>Numero Agréement</th>
                            <th>Administrateur</th>
                            <th>Etat</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Iteration()}
                    </tbody>
                </table>
            </div>
            <div className="w-100 px-4 align-end">

            </div>
        </>
    )
}
export default Ecole