import {Routes,Route,Link, useNavigate, useSearchParams} from 'react-router-dom'
import Cookies from 'js-cookie'


import Accueil from './Composants/Pages/Public/Accueil'

//import Welcome from './Composants/Pages/WelcomeApp'
import Mybulletin from "./Composants/Pages/Enseignant/Encodage/Etat_de_sortie/PDFrender"
import InsertionDeliberation from './Composants/Pages/Admin_ecole/Deliberation/Insertion'
import Enseignant from './Composants/Pages/Admin_ecole/Enseignant/enseignant'
import InsertionEnseignant from './Composants/Pages/Admin_ecole/Enseignant/Insertion'
import UpdateEnseignant from './Composants/Pages/Admin_ecole/Enseignant/Update'
import DeleteEnseignant from './Composants/Pages/Admin_ecole/Enseignant/Insertion'
import Programme from './Composants/Pages/Admin_general/Programme'
import InsertionProgramme from './Composants/Pages/Admin_general/Programme/insertion'
import Bulletinsortie from './Composants/Pages/Enseignant/Encodage/Etat_de_sortie/bulletinsorti'
import InsertionEleve from './Composants/Pages/Admin_ecole/Eleve/Insertion'
import UpdateEleve from './Composants/Pages/Admin_ecole/Eleve/Update'
import Eleve from './Composants/Pages/Admin_ecole/Eleve/Eleve'
import SuppressionEleve from './Composants/Pages/Admin_ecole/Eleve/Suppression'
import Ecole from './Composants/Pages/Admin_general/Ecole/Ecole'
import InsertionEcole from './Composants/Pages/Admin_general/Ecole/Insertion'
import UpdateEcole from './Composants/Pages/Admin_general/Ecole/Update'
import SuppressionEcole from './Composants/Pages/Admin_general/Ecole/Suppression'
import Cours from './Composants/Pages/Admin_general/Cours/Cours'
import InsertionCours from './Composants/Pages/Admin_general/Cours/Insertion'
import UpdateCours from './Composants/Pages/Admin_general/Cours/Update'
import DeleteCours from './Composants/Pages/Admin_general/Cours/Delete'
import Classe_initiale from './Composants/Pages/Admin_general/Classe_initiale/Classe_initiale'
import InsertionClasse_initiale from './Composants/Pages/Admin_general/Classe_initiale/Insertion'
import UpdateClasse_initiale from './Composants/Pages/Admin_general/Classe_initiale/Update'
import DeleteClasse_initiale from './Composants/Pages/Admin_general/Classe_initiale/Delete'

import Inspection from './Composants/Pages/Admin_general/Inspection/Inspection'
import InsertionInspection from './Composants/Pages/Admin_general/Inspection/Insertion'
import UpdateInspection from './Composants/Pages/Admin_general/Inspection/Update'

import Classe from './Composants/Pages/Admin_ecole/Classe/Classe'
import InsertionClasse from './Composants/Pages/Admin_ecole/Classe/Insertion'
import UpdateClasse from './Composants/Pages/Admin_ecole/Classe/Update'
import DeleteClasse from './Composants/Pages/Admin_ecole/Classe/Delete'

import InsertionTitulariat from './Composants/Pages/Admin_ecole/Titulariat/insertion'
import Encodage from './Composants/Pages/Enseignant/Encodage/encodage'
import Bulletin from './Composants/Pages/Enseignant/Encodage/Etat_de_sortie/bulletin'
import Fichepdf from './Composants/Pages/Enseignant/Encodage/Etat_de_sortie/fichecotationpdf'
import Listeproclamation from './Composants/Pages/Enseignant/Encodage/Etat_de_sortie/Listeproclamation'
import Listepdfproclamation from './Composants/Pages/Enseignant/Encodage/Etat_de_sortie/Listepdfproclamation'

import Section from './Composants/Pages/Admin_general/Section/Section'
import InsertionSection from './Composants/Pages/Admin_general/Section/Insertion'
import UpdateSection from './Composants/Pages/Admin_general/Section/Update'
import DeleteSection from './Composants/Pages/Admin_general/Section/Delete'
import Cour_enseigne_classe from './Composants/Pages/Admin_general/CoursEnseigneClasse/Cours_enseigne_classe'
import InsertionCour_enseigne_classe from './Composants/Pages/Admin_general/CoursEnseigneClasse/Insertion'
import UpdateCour_enseigne_classe from './Composants/Pages/Admin_general/CoursEnseigneClasse/Update'
import DeleteCour_enseigne_classe from './Composants/Pages/Admin_general/CoursEnseigneClasse/Delete'
//Dispatching cours aux enseignants
import Cour_classe_ecole from './Composants/Pages/Admin_ecole/CoursEnseigneClasse/Cours_enseigne_classe'
import InsertionCour_classe_ecole from './Composants/Pages/Admin_ecole/CoursEnseigneClasse/Insertion'
import UpdateCour_classe_ecole from './Composants/Pages/Admin_ecole/CoursEnseigneClasse/Update'
import DeleteCour_classe_ecole from './Composants/Pages/Admin_ecole/CoursEnseigneClasse/Delete'

import DeleteEncodage from "./Composants/Pages/Enseignant/Encodage/delete_encodage"

import InsertInscription from "./Composants/Pages/Admin_ecole/Inscription/Insertion"
import UpdateInscription from "./Composants/Pages/Admin_ecole/Inscription/Update"
import DeleteInscription from "./Composants/Pages/Admin_ecole/Inscription/Suppression"

import SectionOrganisee from './Composants/Pages/Admin_ecole/SectionOrgnisee/Section'
import InsertionSectionOrganisee from './Composants/Pages/Admin_ecole/SectionOrgnisee/Insertion'
import UpdateSectionOrganisee from './Composants/Pages/Admin_ecole/SectionOrgnisee/Update'
import DeleteSectionOrganisee from './Composants/Pages/Admin_ecole/SectionOrgnisee/Delete'
import Fiches from './Composants/Pages/Enseignant/Encodage/Etat_de_sortie/ficheCotation'
import Connexion from './Connexion/connexion'
import Register from './Connexion/register'
import Navbar from './Composants/utilitaires/Navbar'
import Notfund from './Composants/Pages/Public/notfound'
import GuestLayout from './Layouts/GuestLayout'
import AuthLayout from './Layouts/AuthLayout'
import { getCookie, isEmpty, removedataclient,checkCookie } from './Utilities/Utils'
import { useDispatch, useSelector } from 'react-redux'
import { getsession } from './actions/user.action'
import axios from './api/axios'
import { useEffect,useState } from 'react'

import TestPdf from "./Composants/Pages/Enseignant/Encodage/Etat_de_sortie/testpdf"
//import './App.css';
//import { AuthProvider, useAuth } from './Composants/Pages/auth'
//import { RequireAuth } from './Composants/Pages/RequireAuth'





function App() {
//Récupération du type de l'utilisateur qui se connecte à notre système
const myuserselector=useSelector((state)=>state.userReducer)
const dispatch=useDispatch()
const mytype_compte=!isEmpty(myuserselector?.data?.type_compte) ? myuserselector?.data?.type_compte : "Visiteur"

//const [mytype_compte,setType_compte]=useState("Visiteur")
useEffect(()=>{
  if(isEmpty(myuserselector?.data?.type_compte)){
    
    checkCookie(dispatch)
    const interval = setInterval(checkCookie, 1000 * 3600); // vérifie toutes les heures
    return () => clearInterval(interval); // Nettoyage de l'intervalle  
  } 
},[])

    return (
   
<>
<Navbar userType={mytype_compte} />
  <Routes>
        <Route path='*' element={<Notfund/>}/>
        <Route path='/connexion' element={<Connexion />}/>
        <Route path='/register' element={<Register />} />
        <Route path='/' element={<Accueil />} />    
        <Route path='/ecole/insertion' element={<InsertionEcole />} />
            <Route path='/testpdf' element={<TestPdf/>} />
            <Route path='/mybulletin/:classetoute/:idclasse/:id' element={<Mybulletin/>}/>
            <Route path='/Elèves' element={<Eleve />}/>
            <Route path='/Encodage' element={<Encodage />}/>
            <Route path='/Proclamation' element={<Bulletin />}/>
            <Route path='/Elèves/insertion' element={<InsertionEleve />}/>
            <Route path='/titulariat/insertion/' element={<InsertionTitulariat />}/>
            <Route path='/Mes_Ecoles/insertion' element={<InsertionEcole />} />
            <Route path='Elèves/:id' element={<UpdateEleve />} />
            <Route path='/Elèves/suppression/:id' element={<SuppressionEleve/>}/>
            <Route path='/bulletinsorti' element={<Bulletinsortie/>}/>
            <Route path='/Enseignants' element={<Enseignant />}/>
            <Route path='/Enseignants/insertion' element={<InsertionEnseignant />}/>
            <Route path='Enseignants/:id' element={<UpdateEnseignant />} />
            <Route path='/Enseignants/suppression/:id' element={<DeleteEnseignant/>}/>
            <Route path='/Délibération' element={<InsertionDeliberation/>}/>
            <Route path='Encodage/:id' element={<DeleteEncodage />} />
            <Route path='/Fiches'element={<Fiches/>}/>
            <Route path='/Fiches/:myclasse/:mycour/:myperiode?' element={<Fichepdf/>} />
            <Route path="/listeproclamation/:classe/:periode" element={<Listeproclamation/>} />
            <Route path="/listepdfproclamation/:classe/:periode" element={<Listepdfproclamation/>} />
            <Route path='/Programme' element={<Programme/>} />
            <Route path='/Programme/insertion' element={<InsertionProgramme/>} />


            <Route path='/Mes_Ecoles' element={<Ecole />}/>
          
            <Route path='/Mes_Ecoles/:id' element={<UpdateEcole />} />
            <Route path='/Mes_Ecoles/suppression/:id' element={<SuppressionEcole />} />
            <Route path='/Mes_Cours' element={<Cours />}/>
            <Route path='/Mes_Cours/:id' element={<UpdateCours />} />
            <Route path='/Mes_Cours/insertion' element={<InsertionCours />} />
            <Route path='/Mes_Cours/delete/:id' element={<DeleteCours />} />
            <Route path='/Mes_Classes' element={<Classe_initiale />}/>
            <Route path='/Mes_Classes/insertion' element={<InsertionClasse_initiale />}/>
            <Route path='/Mes_Classes/:id' element={<UpdateClasse_initiale />}/>

            <Route path='/Classes' element={<Classe />}/>
            <Route path='/Classes/insertion' element={<InsertionClasse />}/>
            <Route path='/Classes/:id' element={<UpdateClasse />}/>

            <Route path='/Classes/delete/:id' element={<DeleteClasse />}/>
            <Route path='/Mes_Sections' element={<Section />}/>
            <Route path='/Mes_Sections/insertion' element={<InsertionSection />}/>
            <Route path='/Mes_Sections/:id' element={<UpdateSection />}/>
            <Route path='/Mes_Sections/delete/:id' element={<DeleteSection />}/>
            <Route path='/Mes_Cours-Classe' element={<Cour_enseigne_classe />}/>
            <Route path='/Mes_Cours-Classe/insertion' element={<InsertionCour_enseigne_classe />}/>
            <Route path='/Mes_Cours-Classe/:id' element={<UpdateCour_enseigne_classe/>}/>
            <Route path='/Mes_Cours-Classe/delete/:id' element={<DeleteCour_enseigne_classe />}/>

            <Route path='/Mes_Inspections' element={<Inspection/>} />
            <Route path='/Mes_Inspections/insertion' element={<InsertionInspection/>} />
            <Route path='/Mes_Inspections/:id' element={<UpdateInspection/>} />

            <Route path='/Cours' element={<Cour_classe_ecole />}/>
            <Route path='/Cours/insertion' element={<InsertionCour_classe_ecole/>}/>
            <Route path='/Cours/:id' element={<UpdateCour_classe_ecole/>}/>
            <Route path='/Cours/delete/:id' element={<DeleteCour_classe_ecole />}/>

            <Route path='/Sections' element={<SectionOrganisee />}/>
            <Route path='/Sections/insertion' element={<InsertionSectionOrganisee />}/>
            <Route path='/Sections/:id' element={<UpdateSectionOrganisee />}/>
            <Route path='/Sections/delete/:id' element={<DeleteSectionOrganisee />}/>

            <Route path='/Inscription' element={<InsertInscription />}/>
            <Route path='/Inscription/:id' element={<UpdateInscription />}/>
            <Route path='/Inscription/delete/:id' element={<DeleteInscription />}/>
  </Routes>
 
  </>
 
  )
 
}

export default App;
