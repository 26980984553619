


//function Formulaire({children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,count,setCount,nom,postnom,prenom,sexe,telephone,Image,setNom,setPostnom,setPrenom,setSexe,setImage,setTelephone,setNomcomplet}){
    function Formulaire({HandleSubmit,children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,nom,setNom,confirmSave}){
  
        return(
            <>
            <div className="container">
                <div className="row">

             
                <form className="w-full max-w-lg sm:mx-auto items-center" onSubmit={confirmSave}>
                    <legend>
                        <h3 className="font-bold text-blue-500 mb-4 text-xl">
                            {title}
                        </h3>
                    </legend>
    
                    <div className="row">
                       
                        <div className="col-md-6 mb-2">
                            <div className="form-outline">
                                <label className="form-label" for="grid-first-name">
                                    NOM : <span class="obligatoire">*</span>
                                </label>
                            <input className="form-control form-control-lg" id="grid-first-name" name="nom" type="text" placeholder="Entrer le nom du cours ici" value={nom} onChange={(e)=>setNom(e.target.value.toLowerCase())} onKeyUp={handlekeyUpgain}/>
                            
                            </div>
                        
                        </div>
                        
                    </div>
                    {children}

                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-outline">
                                 <button className="btn btn-primary" type="submit" name="soumettre" id="submit"  onMouseLeave={handleMouseleave}>Submit</button>
                            </div>
                        </div>
                    </div>

                </form>
                </div>
            </div>
            </>
        )
    }
    export default Formulaire;