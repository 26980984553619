import React from "react";
import axios from "../../../../../api/axios"
import { PDFViewer,  PDFDownloadLink } from "@react-pdf/renderer";
import MyBulletin from "./bulletinsorti";
import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { getCookie, isEmpty } from "../../../../../Utilities/Utils"
import { getsession } from "../../../../../actions/user.action"
const Myrender=()=>{
    localStorage.removeItem("navbar")
let{classetoute,idclasse,id}=useParams()
const [eleves,setEleves]=useState([])
const [My_ecole,setMy_ecole]=useState("")
const [classes,setClasses]=useState([])
const [cotes,setCotes]=useState([])
const [bulletin,setBulletin]=useState([]) 
const [periode,setPeriode]=useState("")
const eleve=id
const classe=idclasse
const classetou=classetoute
const [date_evaluation,seTableCellate_evaluation]=useState()
const [loading,setLoading]=useState(false)

const [cours,setCours]=useState([])
const [mes_cotes,setMes_cotes]=useState([])
const [p1,setP1]=useState([])
const [totalite,setTotalite]=useState([])
let cot=0
const [p2,setP2]=useState([])
//const record_classe=classes.filter((clas)=>clas.id==classe)
const recordseleves=eleves.filter((eleve)=>eleve.idclasse==idclasse)
const dataeleve=recordseleves.filter((elev)=>elev.id==id)
const dataclasse=classes.filter((clas)=>clas.id==idclasse)
const classe_initiale=!isEmpty(dataclasse) && dataclasse[0].idclasse_initiale 
const mes_cours=cours.filter((cour)=>cour.idclasse_initiale==classe_initiale)
const name_file=classetoute==="true" ? dataclasse?.[0]?.nom+'.pdf' : dataeleve?.[0]?.nom_complet+'.pdf'
useEffect(()=>{
    HandleRequest()
    elevesrequest()
    getbulletin()
    getEcole()

},[])

const HandleRequest=async()=>{
    await axios.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{
        setClasses(res.data.items)
    }).catch((err)=>{
        
    })
}
const getEcole=async()=>{
    await axios.get(`api/getEcole/${localStorage.getItem("idecole")}`).then((res)=>{
        setMy_ecole(res.data.items)
    }).catch((err)=>{

    })
}
const elevesrequest=async()=>{

    await axios.post("api/allinscription",{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setEleves(res?.data?.items)
  
    }).catch((err)=>{

    })
}
const getbulletin=async()=>{
    await axios.post("api/getbulletin",{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setCours(res?.data?.cours)
        setCotes(res?.data?.tableau)
        setTotalite(res?.data?.total)
    }).catch((err)=>{

    })
}
function getponderation(){
    let table_ponderation=[]
    if(mes_cours.length>0){
     
        for(let i=0;i<mes_cours.length;i++){
            table_ponderation=[...table_ponderation,mes_cours[i].ponderation]
           
        }
        const unique_ponderation=[...new Set(table_ponderation)]
        return unique_ponderation
    }
    
}
function total(idinscription,idcour,ponderation){
  let cot1= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="P1");
  let cot2= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="P2");
  let cot3= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="Examen1");

  let resultat=cot1[0]?.somme_point*ponderation/cot1[0]?.ponderation+cot2[0]?.somme_point*ponderation/cot2[0]?.ponderation+cot3[0]?.somme_point*ponderation*2/cot3[0]?.ponderation
return(parseInt(resultat))
}
function total2(idinscription,idcour,ponderation){
    let cot1= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="P3");
    let cot2= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="P4");
    let cot3= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="Examen2");
  
    let resultat=cot1[0]?.somme_point*ponderation/cot1[0]?.ponderation+cot2[0]?.somme_point*ponderation/cot2[0]?.ponderation+cot3[0]?.somme_point*ponderation*2/cot3[0]?.ponderation
  return(parseInt(resultat))
  }

function getpoint_eleve(){
    let my_table=[]
    if(!isEmpty(eleve)){
        mes_cours.map((cour)=>{
           const my_cote_now=cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cour.id)
           const p1=my_cote_now.filter((cote)=>cote.periode=="P1")
           const p2=my_cote_now.filter((cote)=>cote.periode=="P2")
           const p3=my_cote_now.filter((cote)=>cote.periode=="P3")
           const p4=my_cote_now.filter((cote)=>cote.periode=="P4")
           const Exam1=my_cote_now.filter((cote)=>cote.periode=="Examen1")
           const Exam2=my_cote_now.filter((cote)=>cote.periode=="Examen2")
           my_table=[
                ...my_table,
                {
                    mon_cour:cour.nom,
                    idcour:cour.id,
                    p1:parseInt(p1[0]?.somme_point),
                    p2:parseInt(p2[0]?.somme_point),
                    tot1:parseInt(p1[0]?.somme_point)+parseInt(p2[0]?.somme_point)+parseInt(Exam1[0]?.somme_point),
                    p3:parseInt(p3[0]?.somme_point),
                    p4:parseInt(p4[0]?.somme_point),
                    Examen1:parseInt(Exam1[0]?.somme_point),
                    Examen2:parseInt(Exam2[0]?.somme_point),


                },
            ]
            
            
        }
          
        )
    }
   return my_table
}
function maximum(){
    let sum=0
   const nombre_cour=mes_cours.length
   if(nombre_cour>0){
    for(let i=0;i<nombre_cour;i++){
        sum=parseInt(sum)+parseInt(mes_cours[i]?.ponderation)
       
    }
   }

   return(sum)
}
function totaux(eleve,periode){
    let tot=0
    
    if(periode=="Examen1" || periode=="Examen2"){
    for(let i=0;i<mes_cours.length;i++){
        let cour_pond=mes_cours.filter((cour)=>cour.id==mes_cours[i]?.id)
        const mes_points=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode==periode && cote.my_course==mes_cours[i].id)
            tot=parseInt(tot)+parseInt((parseInt(mes_points[0]?.somme_point)*cour_pond[0]?.ponderation*2)/parseInt(mes_points[0]?.ponderation))
        }
        }
        else{
            for(let i=0;i<mes_cours.length;i++){
                let cour_pond=mes_cours.filter((cour)=>cour.id==mes_cours[i]?.id)
                const mes_points=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode==periode && cote.my_course==mes_cours[i].id)

            tot=parseInt(tot)+parseInt((parseInt(mes_points[0]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points[0]?.ponderation))
            }
        }
        
   
return (tot)
}
const totalitete=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P1" && cote.my_course==11)
function totauxgen(eleve,periode){
    let tot1=0
    let tot2=0
    let tot3=0
    if(periode=="tot1"){
        const mes_points1=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P1")
        const mes_points2=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P2")
        const mes_points3=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="Examen1")
        for(let i=0;i<mes_points1.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points1[i]?.my_course)
            tot1=parseInt(tot1)+parseInt((parseInt(mes_points1[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points1[i]?.ponderation))
        }
        for(let i=0;i<mes_points2.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points2[i]?.my_course)
            tot2=parseInt(tot2)+parseInt((parseInt(mes_points2[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points2[i]?.ponderation))
        }
        for(let i=0;i<mes_points3.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points3[i]?.my_course)
            tot3=parseInt(tot3)+parseInt((parseInt(mes_points3[i]?.somme_point)*cour_pond[0]?.ponderation*2)/parseInt(mes_points3[i]?.ponderation))
        }
    }
    else{
        const mes_points1=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P3")
        const mes_points2=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P4")
        const mes_points3=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="Examen2")
        for(let i=0;i<mes_points1.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points1[i]?.my_course)
            tot1=parseInt(tot1)+parseInt((parseInt(mes_points1[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points1[i]?.ponderation))
        }
        for(let i=0;i<mes_points2.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points2[i]?.my_course)
            tot2=parseInt(tot2)+parseInt((parseInt(mes_points2[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points2[i]?.ponderation))
        }
        for(let i=0;i<mes_points3.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points3[i]?.my_course)
            tot3=parseInt(tot3)+parseInt((parseInt(mes_points3[i]?.somme_point)*cour_pond[0]?.ponderation*2)/parseInt(mes_points3[i]?.ponderation))
        }
    }
    
return(tot1+tot2+tot3)
}
function place(eleve,periode){
    tableaupourcent(periode)
    //const total_point=totalite.filter((tot)=>tot.periode==periode)
    
    let my_place=""
 
    if(tableaupercent.length>0){
        my_place=tableaupercent.findIndex(element=>element.idinscription==eleve)
    }
    return (my_place)
}

const HandleClick=async()=>{
    await axios.post("api/personnalbulletin").then((res)=>{

    }).catch((err)=>{

    })
}
let tableaupercent = [];
const tableaupourcent = (periode) => {
    tableaupercent=[]
    let pourcent = 0;
    let totalpart=0;
    let totalpart2=0;
    const recordtotalite=totalite.filter((tota)=>tota.periode==periode && tota.idclasse==classe)
    const recordtotalitesem=totalite.filter((tota)=>tota.idclasse==classe)
    if(periode==="Examen1" || periode==="Examen2"){
        recordtotalite.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                pourcent = ((totaux(elv.id, periode) / maximum()*2) * 100).toFixed(2);
                tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
            });
        });
    }
    else if(periode==="tot1"){
        recordtotalitesem.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                totalpart=totauxgen(elv.id,"tot1")
                //totalpart=totaux(elv.id,"P1")+totaux(elv.id,"P2")+totaux(elv.id,"Examen1")
                pourcent = ((parseInt(totalpart) / (maximum()*4)) * 100).toFixed(2);
                if (!tableaupercent.some(e => e.idinscription === elv.id && e.pourcent === pourcent)) {
                    tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
                  }
            });
        });
    }
    else if(periode==="tot2"){
        recordtotalitesem.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                totalpart=totauxgen(elv.id,"tot2")
                //totalpart=totaux(elv.id,"P1")+totaux(elv.id,"P2")+totaux(elv.id,"Examen1")
                pourcent = ((parseInt(totalpart) / (maximum()*4)) * 100).toFixed(2);
                if (!tableaupercent.some(e => e.idinscription === elv.id && e.pourcent === pourcent)) {
                    tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
                  }
            });
        });
    }
    else if(periode==="totGen"){
        recordtotalitesem.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                totalpart=totauxgen(elv.id,"tot1")
                totalpart2=totauxgen(elv.id,"tot2")
                //totalpart=totaux(elv.id,"P1")+totaux(elv.id,"P2")+totaux(elv.id,"Examen1")
                pourcent = (((parseInt(totalpart)+parseInt(totalpart2)) / (maximum()*8)) * 100).toFixed(2);
                if (!tableaupercent.some(e => e.idinscription === elv.id && e.pourcent === pourcent)) {
                    tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
                  }
            });
        });
    }
    else{
        recordtotalite.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                pourcent = ((totaux(elv.id, periode) / maximum()) * 100).toFixed(2);
                tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
            });
        });
    }
    
    tableaupercent.sort((a, b) => b.pourcent - a.pourcent);
};


    return(
        <div>
            {
                /*
                <PDFViewer style={{ width: '100%', height: "600px" }}>
                    <MyBulletin total2={total2} recordseleves={recordseleves} dataclasse={dataclasse} My_ecole={My_ecole} classetoute={classetoute} dataeleve={dataeleve} classe={classe} totauxgen={totauxgen} maximum={maximum} totaux={totaux} place={place} cotes={cotes} total={total} cours={cours} mes_cours={mes_cours} eleve={id} eleves={eleves} idclasse={idclasse}  isEmpty={isEmpty} getponderation={getponderation}/>
                </PDFViewer>
                */

            }
            <PDFDownloadLink document={<MyBulletin total2={total2} recordseleves={recordseleves} dataclasse={dataclasse} My_ecole={My_ecole} classetoute={classetoute} dataeleve={dataeleve} classe={classe} totauxgen={totauxgen} maximum={maximum} totaux={totaux} place={place} cotes={cotes} total={total} cours={cours} mes_cours={mes_cours} eleve={id} eleves={eleves} idclasse={idclasse}  isEmpty={isEmpty} getponderation={getponderation}/>
} fileName={name_file}>
                {({ blob, url, loading, error }) => (loading ? 'Chargement du document...' : 'Télécharger maintenant!')}
            </PDFDownloadLink>
        </div> 
    )
}
export default Myrender