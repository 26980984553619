


//function Formulaire({children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,count,setCount,nom,postnom,prenom,sexe,telephone,Image,setNom,setPostnom,setPrenom,setSexe,setImage,setTelephone,setNomcomplet}){
    function Formulaire({setMy_enseignant,my_enseignant,enseignants,title,confirmSave}){
      return(
            <>
            <div className="col-md-6">
            <form className="" onSubmit={confirmSave}>
                <div className="row">
                <div className="card-header">
                            
                            <h3 className=" card-title fw-bold">
                                {title}
                            </h3>
                        
                    </div>
                </div>
             
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="form-outline">
                                    <label className="form-label" for="grid-state">
                                        ENSEIGNANT  <span class="obligatoire">*</span>
                                    </label>
                                    <select className="form-select form-select-lg" id="grid-state" name="niveau"  onChange={(e)=>setMy_enseignant(e.target.value)} value={my_enseignant}>
                                    <option value="" selected disabled className="fw-bold">Choix de l'enseignant</option>
                                        {
                                            enseignants.map((ense)=>
                                            <option value={ense.id} key={ense.id} className="fw-bold">{ense.nom_complet}</option>
                                        )
                                        }
                                    
                                    
                                    </select>
                            </div>
                        </div>
                    </div>
                </form>
                </div>

            </>
        )
    }
    export default Formulaire;