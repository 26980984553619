import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Swal from 'sweetalert2';
import {useParams,useNavigate} from 'react-router-dom'
import { isEmpty } from '../../../../Utilities/Utils';

function Update(){


    const {id}=useParams()
    const navigate=useNavigate()
    const [enseignants,setEnseignants]=useState([]);
    const [titulaire,setTitulaire]=useState("")
    const [encadreur,setEncadreur]=useState("")
    const records=enseignants.filter(enseign=>enseign.idenseignant!=titulaire?.idenseignant)
    useEffect(()=>{
        HanleRequest()
        HandleRequestTitulaire()
    },[])
    const HanleRequest=async(e)=>{
        await axios.post('api/Classetitulaire',{idclasse:id,annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
          setTitulaire(res.data.titulaire[0])  
         
        }).catch((err)=>{
            console.log(err)
        })
    }
    console.log(titulaire)
    const HandleRequestTitulaire=async(e)=>{
        await axios.post(`api/classeenseignants/${id}`,{annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
                setEnseignants(res.data.enseignants)
        }).catch((err)=>{
            console.log(err)
        })
    }
    function confirmSave(e){
        e.preventDefault()
        if(!isEmpty(titulaire)){
        Swal.fire({
            title:'Demande de confirmation',
            text:'Voulez-vous vraiment modifier le titulaire de cette classe ?',
            icon:'question',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            }
        })  
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord sélectionner le nouveau titulaire',
                icon:'warning'
            })
        }
    }
    const HandleSubmit=async (e)=>{
       
                await axios.put(`api/Titulariat/${titulaire?.id}`,{idenseignant:encadreur,idclasse:id,idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
                    if(res.data.status_code===205){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:'Le nouveau titulaire a été ajouté avec succès',
                            icon:'success',
                            
                        }).then((res)=>{
                            
                        })
                        navigate('/classe')
                    }
                    else{
                        Swal.fire({
                            title:'Message d\'erreur',
                            text:res.data.message,
                            icon:'error'
                            
                        })
                    }
                    console.log(res)
                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    return(
    <>
        <div className='container'>
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="form">
                        <div className="form-group mb-2">
                            <div className="form-outline">
                                <label htmlFor="enseignant" className='form-label'>Choix du titulaire</label>
                                    <select name="enseignant" id="enseignant" className='form-select form-select-lg' onChange={(e)=>setEncadreur(e.target.value)} value={encadreur}>
                                            <option value="" selected disabled>Sélection du titulaire</option>
                                            {
                                                records.map(record=>
                                                <option value={record.idenseignant}>{record.nom_complet}</option>  
                                                    )
                                            }
                                    </select>
                                </div>
                        </div>
                        <div className="form-group mb-2">
                            <div className="form-outline">
                                <button className="btn btn-primary btn-lg" type='button' onClick={confirmSave}>Modifier</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </div>
    </>
    )
}
export default Update