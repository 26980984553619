import React from "react";
import { PDFViewer,Document,Page,View,Text,StyleSheet } from "@react-pdf/renderer";
import {Table,TableRow,TableCell,TableHeader} from "@ag-media/react-pdf-table"

const styles=StyleSheet.create({
    page:{
        flexDirection:"row",

    },
    section:{
        margin:10,
        textAlign:"justify",
        fontSize:"12"
    },
    titre:{
        fontSize:"16",
        fontWeight:"bold",
        color:"blue"
    }

})
const data=[
    {
        "nom":"akonkwa",
        "postnom":"ganywamulume",
        "prenom":"jean-claude"
    },
    {
        "nom":"aganze",
        "postnom":"ganywamulume",
        "prenom":"daniel"  
    },
    {
        "nom":"nabintu",
        "postnom":"ganywamulume",
        "prenom":"alice"
    }
]

const MyPdf=()=>{
    return(
        <PDFViewer style={{ width: '100%', height: "600px" }}>
            <Document>
                
            {
                                data.map(dat=>
                        <Page style={styles.page} size="A4" wrap={false}>
                        <View  style={styles.section}>
                        
                       
                        
                            <Text>
                               
                                   {dat.nom} 
                                
                               
                                
                                
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam laborum eum quas. Nostrum, illum nemo in inventore possimus, dolorum voluptate ipsum fuga nihil a incidunt! Expedita exercitationem alias nesciunt. Dicta.
                                
                            </Text>
                             
                        </View>
                    </Page>
                   )
                } 
               
                
            </Document>
        </PDFViewer>
    )
}
export default MyPdf