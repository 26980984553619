
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
//import './style.css'
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput
}
from 'mdb-react-ui-kit';
import { getuser } from "../actions/user.action";
import { getCookie, isEmpty,setcookie,setdataclient } from "../Utilities/Utils";
import userReducer from "../reducers/user.reducer";
//const userselect=(state)=>state.userReducer
function Connexion(){
const dispatch=useDispatch()
const userselect=useSelector((state)=>state?.userReducer)
const usererrors=useSelector((state)=>state?.userReducer?.response)
const [email,setEmail]=useState("")
const [password,setPassword]=useState("")
const [errors,setErrors]=useState({})
const navigate=useNavigate()
const [remember,setRemember]=useState(false)
const [mydata,setMydata]=useState([])
const csrf=()=>axios.get('sanctum/csrf-cookie')
const [token,setToken]=useState("")
const [my_id,setMy_id]=useState("")
const usercookie=getCookie("XSRF-TOKEN")
//userselect?.status===200 && userselect?.data?.status===210 && navigate('/eleve')


const handleLogin=async()=>{
 await csrf()
  try{
    dispatch(getuser(email,password))
   // dispatch(updateafter(my_id,token))



//  userselect.response.status===200 && setMydata(userselect)
   /* await axios.post('login',{email,password})
    //.then((res)=>{
      //console.log(res)
      setEmail("")
      setPassword("")
      navigate("/eleve")
    
*/
  }catch(e){
    //setErrors(e.response.data.errors)
  //  console.log(e)
  }
}
/*if(userselect?.status===200 && userselect?.data?.status===210 && !isEmpty(usercookie) ){
  dispatch(updateafter(localStorage.getItem("id"),usercookie))
setcookie("gesco_inf",usercookie,2)
} */
if(userselect?.status===200 && userselect?.data?.status===210){
  userselect?.data?.type_compte==="SuperAdmin" ? 
    navigate("/Mes_Cours") :
  userselect?.data?.type_compte==="Administrateur" && userselect?.data?.have_ecole===true ? 
    navigate("/Elèves")
  : userselect?.data?.type_compte==="Administrateur" && userselect?.data?.have_ecole===false ? 
    navigate("/ecole/insertion")
  :
  navigate('/encodage')
}
  return (
    <MDBContainer className="my-5">
      <MDBCard>
        <MDBRow className='g-0'>
          <MDBCol md='6'>
            <MDBCardImage src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp' alt="login form" className='rounded-start w-100'/>
          </MDBCol>
          <MDBCol md='6'>
            <MDBCardBody className='d-flex flex-column'>
              <div className='d-flex flex-row mt-2'>
                <MDBIcon fas icon="cubes fa-3x me-3" style={{ color: '#ff6219' }}/>
                <span className="h1 fw-bold mb-0">Logo</span>
              </div>
              <h5 className="fw-normal my-4 pb-3" style={{letterSpacing: '1px'}}>Sign into your account</h5>
              <div className="text-danger">
                  {
                   usererrors?.status===422 && !isEmpty(usererrors?.data?.errors?.email)  && 
                    <span>{usererrors?.data?.errors?.email}</span>
                  }
                </div>
                <MDBInput wrapperClass='mb-4' label='Email address' id='formControlLg' type='email' onChange={(e)=>setEmail(e.target.value)} value={email} size="lg" required/>
                <div className="text-danger">
                {
                  usererrors?.status===422 && !isEmpty(usererrors?.data?.errors?.password) && 
                    <span>{usererrors?.data?.errors?.password}</span>
                  }
                </div>
                <MDBInput wrapperClass='mb-4' label='Password' id='formControlLg'  type='password' onChange={((e)=>setPassword(e.target.value))} size="lg" required/>
                  <div className="text-danger">
                  {
                 userselect?.status===200 && userselect?.data?.status===400 &&
                    <span>{userselect?.data?.message}</span>
                  }
                  </div>
                  <div className="text-danger">
                  {
                 userselect?.status===200 && userselect?.data?.status===210 && 
                    <span>{userselect?.data?.message}</span>
                  }
                  </div>
                <div class="form-group form-check mb-4">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" onChange={(e)=>setRemember(e.target.checked)}/>
                  <label class="form-check-label" for="exampleCheck1">Remember me</label>
                </div>
              <MDBBtn className="mb-4 px-5" color='dark' size='lg' onClick={handleLogin}>Login</MDBBtn>
              <a className="small text-muted" href="#!">Forgot password?</a>
              <p className="mb-5 pb-lg-2" style={{color: '#393f81'}}>Don't have an account? <Link to="/register" style={{color: '#393f81'}}>Register here</Link></p>
              <div className='d-flex flex-row justify-content-start'>
                <a href="#!" className="small text-muted me-1">Terms of use.</a>
                <a href="#!" className="small text-muted">Privacy policy</a>
              </div>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  );
}
export default Connexion
