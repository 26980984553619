import axios from "../../../api/axios"
function Notfund(){
    return(
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10 text-center">
                        <p className="text-danger">
                            <h1>
                                La page que vous cherchez est introuvable!
                            </h1>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Notfund