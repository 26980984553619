
import { Document, Page, Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import { Table,TableRow,TableCell,TableHeader } from '@ag-media/react-pdf-table';
import Drapeau from "../photos/drapeau.png"
import Embleme from "../photos/embleme.png"  

const Tableau=({total2,annee_scolaire,num_permanent,sexe,My_ecole,dataclasse,key,nom,datenaissance,lieunaissance,recordseleves,dataeleve,classe,totauxgen,maximum,totaux,place,cotes,total,cours,mes_cours,eleve,eleves,idclasse,isEmpty,getponderation})=>{
    const styles=StyleSheet.create({
        page:{
          flexDirection:"row",
          border:"3px  black solid",
        },
        section:{
         paddingHorizontal:"10px",
         paddingVertical:"10px",
          width:"100%",
          border:"1px solid black",
          justifyContent:"start",
          
          
          
        },
        theader:{
          fontWeight:'bold',
          fontSize:10,
          textAlign:"center"
        
        },
        othertext:{
          fontSize:12,
          textAlign:"left",
          width:"100%",
          display:"block",
          flex:1,
        },
        texta:{
          fontSize:12,
          textAlign:"center",
          width:"100%",
          display:"block",
          flex:1,
        },
        noborder:{
          borderStyle:"none",
        },
        tbranche:{
          width:"50%"
        },
        tother:{
          width:"5%"
        }
      
      })
      
  return(
    <Page size="A4" style={styles.page} wrap={false}>
      <View style={styles.section}>
        <View style={styles.tbranche}>
            <Text> JE SUIS LA PAGE N ° {key} </Text>
        </View>
      </View>
    </Page>
  )
}
export default Tableau