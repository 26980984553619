import axios from '../../../../api/axios'
import {useParams,useNavigate} from 'react-router-dom'
import {useEffect,useState} from 'react'
import { HouseDashFill } from 'react-bootstrap-icons'
import Swal from 'sweetalert2'
import Sweetalert2 from 'react-sweetalert2'


function Suppression(){
    const [inspections,setInspections]=useState([])
    const [ecoles,sestEcoles]=useState([])
    const [adresse,setAdresse]=useState("")
    const [Count,setCount]=useState(0)
    const [ville,setVille]=useState("")
    const [type,setType]=useState("")
    const [nom,setNom]=useState("");
    const [province,setProvince]=useState("");
    const [province_educationnelle,setProvince_educationnelle]=useState("");
    const [arrete,setArrete]=useState("");
    const [numero_agreement,setNumero_agreement]=useState("");
    const [inspection,setInspection]=useState("")
    const [status_code,setStatus_code]=useState()
    const [status_message,setStatus_message]=useState("")
    const [swalProps,setSwalProp]=useState({})
    const [motif,setMotif]=useState("")
    const {id}=useParams()
    const navigate=useNavigate()
    useEffect(()=>{
        HandleRequest()
    },[])
    const HandleRequest=async()=>{
        await axios.get(`api/ecole/${id}`).then((res)=>{
            setStatus_code(res.data.status_code)
            sestEcoles(res.data.items)
            setNom(res.data.items.nom)
            setProvince(res.data.items.province)
            setProvince_educationnelle(res.data.items.province_educationnelle) 
            setType(res.data.items.type)
            setAdresse(res.data.items.adresse)
            setVille(res.data.items.ville)
            setInspection(res.data.items.inspection)
            setNumero_agreement(res.data.items.Numero_agreement)
            setArrete(res.data.items.Arrete)
console.log(res)
        
            //console.log(res)
        }).catch((err)=>{
            console.log(err)
        })
    }
 
    function handleclick(e){
        e.preventDefault()
        motif === '' ?  
                Swal.fire({
                    title:'Erreur d\'exécution',
                    text:'Aucun motif de suppression n\a été fourni',
                    icon: 'error'
                })
        : 
        Swal.fire({
            title:'Finalisation Suppression',
            text:'Vous êtes sur le point de  supprimer cette école definitivement',
            icon:'warning',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
            showLoaderOnConfirm:true,
        }).then((res)=>{
            if(res.isConfirmed){
                handledelete() 
            }
        })
    }
    const handledelete=async()=>{
        await axios.delete(`api/ecole/${id}`).then((res)=>{
            setStatus_code(res.data.status_code)
            setStatus_message(res.data.status_message)
            setSwalProp({
                show:true,
                title:'Suppression',
                text:{status_message},
                icon:'success',
                didClose:()=>{
                    navigate('/ecole')
                }
            })
                }).catch((err)=>{
                    console.log(err)
                })
    }
    
  
    return(
        <>
        
        {

           status_code === 200 ?
        <div className="card w-50 align-middle mx-auto mt-8" >
            <HouseDashFill color='blue' size={96} />
            <div className="card-body">
                <h5 className="card-title text-primary text-center">{nom}</h5>
                <div className="card-text mx-0">
                    <ul className='mx-0'>
                        <li><span>Adresse :</span>{adresse}</li>
                        <li><span>Ville :</span>{ville}  </li>
                        <li><span>Province :</span>{province}</li>
                        <li><span>Province educationnelle : </span>{province_educationnelle}</li>
                        <li><span>Numéro agréement :</span>{numero_agreement}</li>
                        <li><span>Arrêté :</span>{arrete}</li>
                    </ul>
                </div>
                <div className="">
                    <select name="motif" id="motif" className='form-control mx-2' onChange={(e)=>setMotif(e.target.value)}>
                        <option value="" selected disabled>Motif de suppression</option>
                        <option value="Ecole inexistante">Ecole inexistante</option>
                        <option value="Ecole fictive">Ecole fictive</option>
                        <option value="Ecole sans droit d'exploitation">Ecole sans droit d'exploitation</option>
                        <option value="Ecole sans viabilité">Ecole sans viabilité</option>
                        <option value="Ecole ne remplissant pas les normes">Ecole ne remplissant pas les normes</option>
                    </select>
                </div>
            </div>
            
            <div className="card-footer">
                <a href="#" className="card-link btn btn-danger px-2 mr-12" onClick={handleclick}>Supprimer</a>
                <a href="#" className="card-link btn btn-primary px-2 mr-2" onClick={()=>navigate('/ecole')}>Annuler</a>
            </div>
            </div>
            :
            <div className="">
                <h3 className='text-danger'>
                    Aucun enregistrement correspondant!
                </h3>
            </div>
            }

            <Sweetalert2 {...swalProps}>
                    <h3 className='text-primary'>
                        {status_message}
                    </h3>
           </Sweetalert2>
        </>
    )
}
export default Suppression