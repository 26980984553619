import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Formulaire from '../../../ModelesPages/Modele1/FormulaireCours'
import Swal from 'sweetalert2';

function Insertion(){

    const [Count,setCount]=useState(0);

    const [nom,setNom]=useState("");
    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    function confirmSave(e){
        e.preventDefault()
        if(nom !=""){
        Swal.fire({
            title:'Demande de confirmation',
            text:'Voulez-vous vraiment enregistrer ce cours ?',
            icon:'question',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            }
        })  
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord confirmer le nom du cours',
                icon:'warning'
            })
        }
    }
    const HandleSubmit=async (e)=>{
       
                await axios.post('api/Cours',{nom:nom.toLowerCase()}).then((res)=>{
                    setNom("")
                    if(res.data.status_code===200){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:'Le cours a été enregistré avec succès',
                            icon:'success'
                            
                        })
                    }
                    else{
                        Swal.fire({
                            title:"Erreur d'enregistrement",
                            text:res.data.status_message,
                            icon:"error"
                        })
                    }
                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    return(
    <>
    <Formulaire title="Formulaire d'inscription" onclick={handleclick} confirmSave={confirmSave} setNom={setNom} handlekeyUpgain={handlekeyUpgain}  nom={nom}  handleMouse={handleMouse} Count={Count} setCount={setCount} handleMouseleave={handleMouseleave} HandleSubmit={HandleSubmit}  >
    <div className="w-full md:w-1/1 px-3  md:mb-0">

    </div>
    </Formulaire>
    </>
    )
}
export default Insertion