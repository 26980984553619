import { useEffect, useState } from "react"
import axios from "../../../../api/axios"
import { useDispatch, useSelector } from "react-redux"
import { getsession } from "../../../../actions/user.action"
import { getCookie, isEmpty } from "../../../../Utilities/Utils"
import loadingIcon from '../../../../Images/loading.gif'
import Swal from "sweetalert2"
import { Link, Navigate, useNavigate } from "react-router-dom"

function Encodage(){
const userselector=useSelector((state)=>state.userReducer)
const [eleves,setEleves]=useState([])
const [classes,setClasses]=useState([])
const [cotes,setCotes]=useState([])
const [cours,setCours]=useState([])
const [ponderation,setPonderation]=useState("")
const [periode,setPeriode]=useState("")
const navigate=useNavigate()
const [date_evaluation,setDate_evaluation]=useState("")

const [loading,setLoading]=useState(false)
const [classe,setClasse]=useState("")
const [cour,setCour]=useState("")
const dispatch=useDispatch()
const recordcours=cours.filter((cr)=>cr.idclasse==classe && cr.idenseignant==localStorage.getItem("idenseignant"))
const actual_cour=cours.filter((cr)=>cr.id==cour)
const recordseleves=eleves.filter((eleve)=>eleve.idclasse==classe)
const recordscotes=cotes.filter((cote)=>cote.idcour==cour && cote.periode==periode)
useEffect(()=>{
    HandleRequest()
    elevesrequest()
    coursRequest()
    handleallcotes()
   // dispatch(getsession(localStorage.getItem('email'),localStorage.getItem("id"),getCookie("gesco_inf")))
},[])
useEffect(()=>{
    setCour("")
},[classe])

const HandleRequest=async()=>{
    await axios.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{
        setClasses(res.data.items)
    }).catch((err)=>{
        
    })
}
const handlechange=(e)=>{
    if(isEmpty(ponderation)){
        Swal.fire({
            title:'Erreur',
            text:"Veuillez d'abord entrer la pondération de l'évaluation",
            icon:'error'
        })
        e.target.value=""
       
    }
    else{
        if(parseFloat(e.target.value)>parseFloat(ponderation)){
            
            Swal.fire({
                title:'Erreur de saisie',
            text:"Impossible d'avoir une note supérieur à la note de l'évaluation",
            icon:'error'
            })
            e.target.value=""
        }
        if(parseFloat(e.target.value)<0){
            Swal.fire({
            title:'Erreur de saisie',
            text:"Impossible d'avoir une note inférieur à 0",
            icon:'error'
            })
            e.target.value=""
        }
    }
}
const coursRequest=async()=>{
    await axios.post('api/getcours',{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setCours(res.data.items)
    }).catch((err)=>{
        
    })
}
const elevesrequest=async()=>{
    setLoading(true)
    await axios.post("api/allinscription",{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setEleves(res?.data?.items)
        setLoading(false)
    }).catch((err)=>{

    })
}
function emptyfield(){
    const nombre=document.getElementById("tableau").getElementsByTagName("input").length
    let my_table=""
    let my_count=0

    for(let i=0;i<nombre;i++){
     
        if(document.getElementById("tableau").getElementsByTagName("input")[i].value==""){
            const elev=document.getElementById("tableau").getElementsByTagName("span")[i].innerHTML
            my_table=my_table+elev+";\n"
        }
    }
    return (my_table)
}
function data_complete(){
    let my_data=[]
    const nombre=document.getElementById("tableau").getElementsByTagName("input").length
    for(let i=0;i<nombre;i++){
        const etat=!isEmpty(document.getElementById("tableau").getElementsByTagName("input")[i].value) ? true:false
    my_data=[
        ...my_data,
        {
            etat: etat,
            idinscription:parseInt(document.getElementById("tableau").getElementsByTagName("input")[i].name),
            point:parseFloat(document.getElementById("tableau").getElementsByTagName("input")[i].value),

        }
    ]
    }
return (my_data)
}

const handleclick=()=>{
    if(date_evaluation!=="" ){
    let message=""
    isEmpty(emptyfield()) ? message="Etes-vous sûr de vouloir enregistrer cette fiche de côte ?" : message="Vous n'avez pas renseigner des points pour certains élèves comme :\n"+emptyfield()+".\n Voulez-vous toujours enregistrer cette fiche des côtes ?"
    Swal.fire({
     title:"Demande de confirmation",
     text:message,
     icon:"question",
     showConfirmButton:true,
     confirmButtonText:"Confirmer",
     showCancelButton:true
    }).then((resultat)=>{
        if(resultat.isConfirmed){
            handlesave()
        }
    })
}
else{
    Swal.fire({
        title:"Erreur",
        text:"Veuillez d'abord renseigner la date de l'évaluation",
        icon:"error",
       }) 
}
}
const handleallcotes=async()=>{
    await axios.post('api/getallcotes',{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setCotes(res?.data?.items)
    }).catch((err)=>{

    })
}
const handlesave=async()=>{
    await axios.post('api/evaluation',{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire"),idclasse:classe,idcour:cour,idenseignant:localStorage.getItem("idenseignant"),ponderation:ponderation,date_evaluation:date_evaluation,periode:periode,data:data_complete()}).then((res)=>{
        if(res?.data?.status==200){
            Swal.fire({
                title:"Message d'enregistrement",
                text:res?.data?.message,
                icon:'success'
            })
            setClasse("")
            setCour("")
            setPonderation("")
            handleallcotes()
        }
    }).catch((err)=>{

    })
}

const Iteration=()=>{
    return (loading) ? (
        <div className='w-100 mx-auto'>
            <img src={loadingIcon} alt='loading'/>
        </div>
    ) : (
        recordseleves.length > 0 &&
        recordseleves.map((elve,indexV)=>
           /* records.filter((enseignant)=>{
                return search === '' ? enseignant : enseignant.nom_complet.includes(search)
            }).map((enseignant,indexV)=>*/
            {
              return(
                <tr>
                    <td>{indexV+1}</td>
                    <td><span>{elve.nom_complet}</span></td>
                    <td><input type="number" name={elve.id} className="form-control" onBlur={(e)=>handlechange(e)} /></td>
                </tr>   
              )
                
            }
            )
      
    )
}
const Allcote_eleve=()=>{
    return (loading) ? (
        <div className='w-100 mx-auto'>
            <img src={loadingIcon} alt='loading'/>
        </div>
    ) : (
        recordscotes.length > 0 &&
        recordscotes.map((cote,indexV)=>
           /* records.filter((enseignant)=>{
                return search === '' ? enseignant : enseignant.nom_complet.includes(search)
            }).map((enseignant,indexV)=>*/
            {
              return(
                <tr>
                    <td>{indexV+1}.</td>
                    <td><span>{cote.nom_complet}</span></td>
                    <td>{cote.somme}/{cote.somme_ponderation}</td>
                </tr>   
              )
                
            }
            )
      
    )
}
    return(
        <>
        <div className="container">
            <div className="row justify-content-start">
                <div className="col-md-3 card">
                <div className="col-md-12 card-header bg-dark text-light">
                    <h3 className="card-title">
                        Seléction des choix
                    </h3>
                </div>
                <div className="col-md-12 mb-2">
                    <div className="form-outline">
                        <label htmlFor="" className="form-label">Classe</label>
                        <select name="classe" id="classe" className="form-select form-select-lg" onChange={(e)=>setClasse(e.target.value)} value={classe}>
                            <option value="">Seléctionner la classe</option>
                            {
                                classes.map((clas)=>
                                    <option value={clas.id}>{clas.nom}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-12 mb-2">
                    <div className="form-outline">
                        <label htmlFor="" className="form-label">Cours</label>
                        <select name="cours" id="cours" className="form-select form-select-lg" onChange={(e)=>setCour(e.target.value)} value={cour}>
                            <option value="">Seléctionner le cours</option>
                            {
                                recordcours.map((cr)=>
                                    <option value={cr.id}>{cr.cours}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-12 mb-2">
                    <div className="form-outline">
                        <label htmlFor="" className="form-label">Période</label>
                        <select name="periode" id="periode" className="form-select form-select-lg" onChange={(e)=>setPeriode(e.target.value)} value={periode}>
                            <option value="">Seléction de la période</option>
                            <option value="P1">Première période</option>
                            <option value="P2">Deuxième période</option>
                            <option value="Examen1">Examen Ier Semestre</option>
                            <option value="P3">Troisième période</option>
                            <option value="P4">Quatrième période</option>
                            <option value="Examen2">Examen 2nd Semestre</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-12 mb-2">
                        <div className="form-outline">
                            <label htmlFor="" className="form-label">Pondération</label>
                            <input type="number" className="form-control form-control-lg" placeholder="Pondération de l'évaluation ici" onChange={(e)=>setPonderation(e.target.value)} value={ponderation} />
                        </div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <div className="form-outline">
                            <label htmlFor="" className="form-label">Date de passation</label>
                            <input type="date" className="form-control form-control-lg" placeholder="La date de l'évaluation ici" onChange={(e)=>setDate_evaluation(e.target.value)} value={date_evaluation} />
                        </div>
                    </div>
                </div>
                
                    <div className="col-md-5">
                            <div className="col-md-12 mb-2">
                            <table className="table table-bordered table-striped table-responsive table-hover">
                                <thead className="table-dark text-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Nom & Postnom</th>
                                        <th>Point</th>
                                    </tr>
                                    
                                </thead>
                                <tbody id="tableau">
                                    {
                                        !isEmpty(classe) && !isEmpty(cour) && !isEmpty(periode) &&
                                            Iteration()
                                    }
                                </tbody>
                            </table>
                            <div className="row justify-content-start">
                                    {
                                        !isEmpty(ponderation) && !isEmpty(cour) && !isEmpty(periode) &&
                                        <button className="btn btn-primary" onClick={handleclick}>Enregistrer</button>
                                    }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 card">
                        <div className="card-header bg-dark text-light">
                            <h3 className="card-title text-center">
                                    Total fiche des cotes 
                            </h3>
                            {
                                !isEmpty(cour) && !isEmpty(periode) &&
                           <div className="div">
                                <h5>
                                    Cours : {actual_cour[0]?.cours}
                                </h5>
                                <h5>
                                    Période : {periode}
                                </h5>
                                <h5>
                                    Ponderation : 
                                </h5>
                                <h5 className="text-danger">
                                    { recordscotes.length > 0 &&
                                        <button onClick={()=>navigate(`${cour}`)}>Supprimer une cote</button>
                                    }
                                </h5>
                            </div>
                            }
                            <div className="card-body">
                            {
                                        !isEmpty(classe) && !isEmpty(cour) && !isEmpty(periode) &&
                      
                                        Allcote_eleve()
                                    
                                    }
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        </>
    )
}
export default Encodage