import axios from '../../../../api/axios';
import React, {useState,useEffect} from 'react'
import {EyeFill,PencilFill,Trash3Fill,PersonPlus} from "react-bootstrap-icons"
import { useNavigate,useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import loadingIcon from '../../../../Images/loading.gif'

function Inspection() {
    let i=0
    const csrf=()=>axios.get('sanctum/csrf-cookie')

    const [inspections,setInspections]=useState([])
    const [curentpage,setCurentpage]=useState(1)
    const [search,setSearch]=useState('')
    const [recordperpage,setRecordperpage]=useState(50)
    let navigate=useNavigate()
    const lastIndex=curentpage * recordperpage
    const firstIndex=lastIndex - recordperpage
    const {id}=useParams()
    const records= search.toUpperCase() === '' ? inspections.slice(firstIndex, lastIndex) : inspections.filter((inspection)=>{return search.toUpperCase() === '' ? inspection : inspection.province_educationnelle.toUpperCase().includes(search)}).slice(firstIndex, lastIndex)
    const recordNpage=search.toUpperCase() === '' ? inspections : inspections.filter((inspection)=>{ return inspection.province_educationnelle.toUpperCase().includes(search)})
    const npage=Math.ceil(recordNpage.length/recordperpage)
    const numbers=[...Array(npage+1).keys()].slice(1)      
   //const recordperpage=2
    
    //const numbers=[1,2,3,4,5]
    const [loading,setLoading]=useState(false)
    

    useEffect(
        ()=>{
                InspectionRequest()
        },[])
    const InspectionRequest=async ()=>{
        await axios.get('/api/Inspection').then(
            (res)=>{
                setInspections(res.data.items)
                setLoading(false)
                console.log(res)
            }
        ).catch((err)=>{
            console.log(err)
        })
    }
  const deleteinspection=async(id)=>{
    await axios.delete(`api/Inspection/${id}`).then((res)=>{
        if(res?.data?.status_code==200){
            Swal.fire(
                {
                    titre:"Suppression de l'inspection",
                    text:"Votre inspection a été supprimée avec succès",
                    icon:"success"
                }
            )
            InspectionRequest()
        }
    }).catch((err)=>{

    })
  }
    const Iteration=()=>{
        return loading ? (
            <div className='w-100 mx-auto'>
                <img src={loadingIcon} alt='loading'/>
            </div>
        ) : (
            
                records.filter((inspection)=>{
                    return search.toUpperCase() === '' ? inspection : inspection.province_educationnelle.toUpperCase().includes(search)
                }).map((inspection,indexV)=>
                {
                  return(
                        <tr key={indexV} className='ligne'>
                            <td>{indexV+1}</td>
                            <td>{inspection.province_educationnelle.toUpperCase()}</td>
                            <td>{inspection.province}</td>
                            <td>{inspection.code_province}</td>
                            <td>{inspection.arrete}</td>
                            <td><EyeFill color="lightgreen" size={20} className='d-inline-flex mx-2 icone'  /><PencilFill color="royalblue" size={20} className='d-inline-flex mx-2 icone' onClick={()=>navigate(`${inspection.id}`)}/>  <Trash3Fill color='brown' size={20} className='d-inline-flex mx-2 icone' onClick={()=>AlertNotification(inspection.id)} /> </td>
                        </tr>
                  )
                    
                }
                )
            
        )
        
            }   
            function AlertNotification(idinspection){
                Swal.fire({
                    title:'Etes-vous sûr de vouloir supprimer cette inspection ?',
                    text:'Cette inspection sera supprimée',
                    icon:'warning',
                    showDenyButton:true,
                    showCancelButton:true,
                    confirmButtonText:'Confirmer',
                    denyButtonText:'Annuler'
                }).then((resultat)=>{
                    if(resultat.isConfirmed){
                       deleteinspection(idinspection)
                            }
                })
            }
            
    return(
        <>
        <div className="mt-2 mx-auto px-2 py-8 bg-primary">
            
            <div className='d-inline-flex w-75'> 
               <h3 className="text-light text-lg d-inline-flex w-50">
                Liste des inspections existantes (Total : {inspections.length})
                </h3>
                <div className="d-inline-flex w-50 mx-auto text-center">
                    <button name="ajouter" id="ajouter" onClick={()=>navigate('insertion')} className='btn btn-success d-inline-flex w-50 mx-auto  ' ><span className='text-center'> <PersonPlus color='white' size={25} className='d-inline-flex' /> Ajouter une inspection </span></button>

                </div>
            </div>
            <div className='d-inline-flex w-25'>
                <input type="text" name="recherche" id="recherche" placeholder='Rechercher un élève' className='form-control form-control-sm'  onChange={(e)=>setSearch(e.target.value.toLowerCase())} value={search} />

            </div>
        </div>
        <div>
           <table className="table table-striped table-hover">
                <thead className='thead-light'>
                    <tr className=''>
                        <th >#</th>
                        <th>Inspection</th>
                        <th>Province</th>
                        <th>Code de la province</th>
                        <th>Arrete</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody className="text-slate-700 text-xs">
                    {Iteration()}
                </tbody>
           </table>

        </div>
        <nav>
                <ul className='pagination mt-2 mx-4'>
                    <li className='page-item  '>
                        <a href="#" className='page-link' onClick={Prepage}>Prev</a>
                    </li>
                {
                    numbers.map((number,index)=>{
                        return(
                            <li className={`page-item ${curentpage === number ? 'active': '' }`} key={index}>
                                <a href="#" className='page-link' onClick={()=>changeCPage(number)}>{number}</a>
                            </li>
                        )
                    })
                    
                }
                    <li className='page-item '>
                        <a href="#" className='page-link' onClick={Nextpage}>Next</a>
                    </li>
                    <span className='mr-8'>
                    <input type="number" name="nbrperpag" className='form-control' id="nbrperpag" placeholder='Nombre par page' value={recordperpage} onChange={(e)=>setRecordperpage(e.target.value !== '' ? e.target.value : 1)} onBlur={()=>setCurentpage(1)} />
                    </span>
                </ul>
        </nav>

        </>
    )
function Prepage(){
    if(curentpage > 1){
        setCurentpage(curentpage-1)
    }
}
function changeCPage(id){
   setCurentpage(id)
}
function Nextpage(){
    if(curentpage < npage){
       // alert(curentpage)
        setCurentpage(curentpage+1)
    }
}

}
export default Inspection

