import axios from '../../../../api/axios';
import React, {useState,useEffect} from 'react'
import {EyeFill,PencilFill,Trash3Fill,PersonPlus} from "react-bootstrap-icons"
import { useNavigate,useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import loadingIcon from '../../../../Images/loading.gif'
import { useDispatch, useSelector } from 'react-redux';
import { getsession } from '../../../../actions/user.action';
import { getCookie } from '../../../../Utilities/Utils';

function Section() {
    let i=0
    const dispatch=useDispatch()
    const userselector=useSelector((state)=>state.userReducer)
    const [sections,setSections]=useState([])
    const [curentpage,setCurentpage]=useState(1)
    const [search,setSearch]=useState('')
    const [recordperpage,setRecordperpage]=useState(50)
    let navigate=useNavigate()
    const lastIndex=curentpage * recordperpage
    const firstIndex=lastIndex - recordperpage
    const {id}=useParams()
    const records= search.toLowerCase() === '' ? sections.slice(firstIndex, lastIndex) : sections.filter((section)=>{return search.toLowerCase() === '' ? section : section.nom.toLowerCase().includes(search)}).slice(firstIndex, lastIndex)
    const recordNpage=search.toLowerCase() === '' ? sections : sections.filter((section)=>{ return section.nom.toLowerCase().includes(search)})
    const npage=Math.ceil(recordNpage.length/recordperpage)
    const numbers=[...Array(npage+1).keys()].slice(1)      
   //const recordperpage=2
    const csrf=()=>axios.get('sanctum/csrf-cookie')
    //const numbers=[1,2,3,4,5]
    const [loading,setLoading]=useState(false)
    

    useEffect(
        ()=>{
           // dispatch(getsession(localStorage.getItem('email'),localStorage.getItem("id"),getCookie("gesco_inf")))
            Classes_initialesRequest()
                
        },[])
    const Classes_initialesRequest=async ()=>{
        await axios.get(`/api/sectionorg/${localStorage.getItem('idecole')}`).then(
            (res)=>{
                setSections(res.data.items)
                setLoading(false)
                console.log(res)
            }
        ).catch((err)=>{
            console.log(err)
        })
    }
  const Delete=async(section)=>{
    await axios.delete(`api/Sectionorganisee/${section}`).then((res)=>{
        Classes_initialesRequest()
            Swal.fire({
                title:"Réponse du serveur",
                text:res.data.message,
                icon:"success"
            })


    }).catch((err)=>{

    })
  }
    const Iteration=()=>{
        return loading ? (
            <div className='w-100 mx-auto'>
                <img src={loadingIcon} alt='loading'/>
            </div>
        ) : (
            
                sections.filter((section)=>{
                    return search.toLowerCase() === '' ? section : section.nom.toLowerCase().includes(search)
                }).map((section,indexV)=>
                {
                  return(
                    <div class="card col-md-4 mx-2 mb-2" >
                    <div class="card-body">
                      <h5 class="card-title">{section.nom}</h5>
                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      <a onClick={()=>navigate(`${section.id}`)} class="btn btn-primary">Update section</a>
                      {//<a className='btn btn-danger float-end' onClick={()=>navigate(`delete/${section.id}`)}>Delete Section</a>
                      }
                      <a className='btn btn-danger float-end' onClick={()=>AlertNotification(section.id)}>Delete Section</a>
                    </div>
                  </div>
                  )
                    
                }
                )
            
        )
        
            }   
            function AlertNotification(idsection){
                Swal.fire({
                    title:'Etes-vous sûr de le supprimer ?',
                    text:'Ce cours sera supprimé',
                    icon:'warning',
                    showDenyButton:true,
                    showCancelButton:true,
                    confirmButtonText:'Confirmer',
                    denyButtonText:'Annuler'
                }).then((resultat)=>{
                    if(resultat.isConfirmed){
                      Delete(idsection)

                            }
                })
            }

    return(
        <>
        <div className="mx-2">
        <div className="w-full max-w-lg sm:mx-auto items-center   bg-primary">
            <div className="flex flex-wrap mx-1 mb-2">
                <div className='w-full md:w-1/3 px-3 mb-2 md:mb-2'> 
                <h3 className="text-light text-lg ">
                    Liste des sections existantes (Total : {sections.length})
                    </h3>
                
                </div>
                <div className="w-full md:w-1/3 px-3  mb-2 md:mb-2">
                        <button name="ajouter" id="ajouter" onClick={()=>navigate('insertion')} className='btn btn-success  ' ><span className='text-center'> <PersonPlus color='white' size={25} className='d-inline-flex' /> Ajouter une section </span></button>
                </div>
                <div className='w-full md:w-1/3 px-3 mb-2 md:mb-2'>
                    <input type="text" name="recherche" id="recherche" placeholder='Rechercher un élève' className='form-control form-control-sm'  onChange={(e)=>setSearch(e.target.value.toLowerCase())} value={search} />
                </div>
            </div>
        </div>
        <div>
        </div>
        <div className="row">
                 {Iteration()}
        </div>
      
        </div>
        </>
    )

}
export default Section

