import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Formulaire from '../../../ModelesPages/Modele1/FormulaireInspection'
import Swal from 'sweetalert2';

function Insertion(){
  

    const [Count,setCount]=useState(0);

    const [province_educationnelle,setProvince_educationnelle]=useState("");
    const [province,setProvince]=useState("")
    const [code_province,setCode_province]=useState("")
    const [arrete,setArrete]=useState("")

    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    function confirmSave(e){
        e.preventDefault()
        if(province_educationnelle !="" && province!=""){
        Swal.fire({
            title:'Demande de confirmation',
            text:'Voulez-vous vraiment enregistrer cette inspection ?',
            icon:'question',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            }
        })  
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord entrer le nom de la province éducationnelle',
                icon:'warning'
            })
        }
    }
    const HandleSubmit=async (e)=>{ 
                await axios.post('/api/Inspection',{province_educationnelle:province_educationnelle.toUpperCase(),province:province,code_province:code_province,arrete:arrete}).then((res)=>{
                    setProvince_educationnelle("")
                    setProvince("")
                    setCode_province("")
                    setArrete("")
                    if(res.data.status_code===200){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:'Votre inspection a été enregistrée avec succès',
                            icon:'success'
                            
                        })
                    }
                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    return(
    <>
    <Formulaire title="Formulaire d'insertion" setProvince_educationnelle={setProvince_educationnelle} setProvince={setProvince} setCode_province={setCode_province} setArrete={setArrete}  confirmSave={confirmSave} province={province} province_educationnelle={province_educationnelle} code_province={code_province} arrete={arrete}   >
    <div className="w-full md:w-1/1 px-3  md:mb-0">
       
    <div id="resultat" className="block text-red-700"></div>
    <div id="erreur" className="block text-red-500"></div>
    </div>
    </Formulaire>
    </>
    )
}
export default Insertion