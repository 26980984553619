import axios from '../../../../api/axios'
import React,{useState,useEffect} from 'react'
import {Navigate, useNavigate,useParams} from 'react-router-dom'
import Formulaire from '../../../ModelesPages/Modele1/FormulaireEcole'
import Swal from 'sweetalert2'

function Update(){
    const [inspections,setInspections]=useState([])
    const [ecoles,sestEcoles]=useState([])
    const [adresse,setAdresse]=useState("")
    const [Count,setCount]=useState(0)
    const [ville,setVille]=useState("")
    const [type,setType]=useState("")
    const [commune,setCommune]=useState("")
    const [code,setCode]=useState("")
    const [nom,setNom]=useState("");
    const [province,setProvince]=useState("");
    const [province_educationnelle,setProvince_educationnelle]=useState("");
    const [arrete,setArrete]=useState("");
    const [numero_agreement,setNumero_agreement]=useState("");
    const [inspection,setInspection]=useState("")
    const [status_code,setStatus_code]=useState()
    const navigate=useNavigate()
    const {id}=useParams()
    console.log(id)
    const csrf=()=>axios.get('sanctum/csrf-cookie')
    useEffect(()=>{
        HandleRequest()
        HandleRequestInspection()
    },[])
    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    const confirmSave=(e)=>{
        e.preventDefault()
        if(nom !=""  && type!="" && province!="" && province_educationnelle!="" && adresse!="" && numero_agreement!=""){
        Swal.fire({
            title:'Confirmation de soumission',
            icon:'question',
            text:'Voulez-vous vraiment enregistrer votre école sur notre plateforme  ?',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonColor:'success',
            confirmButtonText:'Confirmer'

        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            }
        })
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord compléter tous les champs obligatoires',
                icon:'error'
                    
        })
        }
    }
    const HandleSubmit=async (e)=>{
            await axios.put(`/api/ecole/${id}`,{code:code,commune:commune,nom:nom.toLowerCase(),province:province.toLowerCase(),province_educationnelle:province_educationnelle,type:type.toLowerCase(),adresse:adresse.toLowerCase(),ville:ville.toLowerCase(),arrete:arrete.toLowerCase(),numero_agreement:numero_agreement}).then((res)=>{
            setNom("")
            setProvince("")
            setProvince_educationnelle("") 
            setType("")
            setAdresse("")
            setVille("")
            setInspection("")
            setNumero_agreement("")
            setArrete("")
            setCommune("")
            setCode("")
            document.getElementById("resultat").innerHTML=""
            if(res.data.status_code===200){
                Swal.fire({
                    title:'Message de confirmation',
                    icon:'success',
                    text:'Votre école a été enregistré sur notre plateforme, nous vous reviendrons incessament par message',
                }).then((resultat)=>{
                    navigate('/ecole')
                })
            }
            else{
                Swal.fire({
                    title:'Message d\'erreur',
                    icon:'error',
                    text:'Votre école n\'a pas été mise à jour suite à un problème sur le serveur',
                })
            }
            
           
             
        }).catch((err)=>{
                
                
                
        })

    
       //alert("i want to submit your form don't be worry")
    }
    const HandleRequestInspection=async()=>{
        await axios.get('/api/Inspection').then((res)=>{
            console.log(res)
            setInspections(res.data.items)
            
        }).catch((err)=>{
            console.log(err)
        })
    }
    const HandleRequest=async()=>{
        await axios.get(`/api/ecole/${id}`).then((res)=>{
            sestEcoles(res.data.items)
            setNom(res.data.items.nom)
            setProvince(res.data.items.province)
            setProvince_educationnelle(res.data.items.inspection) 
            setType(res.data.items.type)
            setAdresse(res.data.items.adresse)
            setVille(res.data.items.ville)
            setInspection(res.data.items.inspection)
            setNumero_agreement(res.data.items.Numero_agreement)
            setArrete(res.data.items.Arrete)
            setCommune(res.data.items.commune)
            setCode(res.data.items.code)

        
            //console.log(res)
        }).catch((err)=>{

        })
    }
 
    return(
        <>
            <div className="">
            <Formulaire title="Mise à jour de mon école" code={code} setCode={setCode} commune={commune} setCommune={setCommune} confirmSave={confirmSave} setInspections={setInspections} inspections={inspections} onclick={handleclick} setNom={setNom} handlekeyUpgain={handlekeyUpgain} setProvince={setProvince} setProvince_educationnelle={setProvince_educationnelle} setType={setType} setVille={setVille} setAdresse={setAdresse} nom={nom} province={province} province_educationnelle={province_educationnelle} type={type} ville={ville} arrete={arrete} handleMouse={handleMouse} Count={Count} setCount={setCount} handleMouseleave={handleMouseleave} HandleSubmit={HandleSubmit} setNumero_agreement={setNumero_agreement} numero_agreement={numero_agreement} adresse={adresse} inspetion={inspection} setInspection={setInspection} setArrete={setArrete} >
                <div className="w-full md:w-1/1 px-3  md:mb-0">
                   
                <div id="resultat" className="block text-red-700"></div>
                <div id="erreur" className="block text-red-500"></div>
                </div>
            </Formulaire>
            </div>
        </>
    )
}
export default Update