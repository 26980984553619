import { ADD_ELEVE, ADD_USER, GET_ERRORS_REGISTER, GET_ERRORS_USER, GET_SESSION_USER, GET_USER, LOGOUT_USER, UPDATEAFTER } from "../actions/user.action";


const state_initial=[]
export default function userReducer(state=state_initial,action){
           switch(action.type){
            case GET_USER:
                return action.payload
            case ADD_USER:
                return [action.payload,...state]
           case GET_ERRORS_USER:
                return action.payload
            case GET_ERRORS_REGISTER:
                return action.payload
            case LOGOUT_USER:
                return state_initial
            case UPDATEAFTER:
                return action.payload
            case GET_SESSION_USER:
                return action.payload
            default :
                return state
           }
}