import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Formulaire from '../../../ModelesPages/Modele1/FormulaireSection'
import Swal from 'sweetalert2';

function Insertion(){
  

    const [Count,setCount]=useState(0);

    const [nom,setNom]=useState("");
    const [litterature,setLitterature]=useState("")

    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    function confirmSave(e){
        e.preventDefault()
        if(nom !="" && litterature!=""){
        Swal.fire({
            title:'Demande de confirmation',
            text:'Voulez-vous vraiment enregistrer cette section ?',
            icon:'question',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            }
        })  
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord confirmer le nom de la section',
                icon:'warning'
            })
        }
    }
    const HandleSubmit=async (e)=>{ 
                await axios.post('/api/Section',{nom:nom.toLowerCase(),litterature:litterature.toLowerCase()}).then((res)=>{
                    setNom("")
                    setLitterature("")
                    if(res.data.status_code===200){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:'La section a été enregistrée avec succès',
                            icon:'success'
                            
                        })
                    }
                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    return(
    <>
    <Formulaire title="Formulaire d'inscription" onclick={handleclick} litterature={litterature} setLitterature={setLitterature} confirmSave={confirmSave} setNom={setNom} handlekeyUpgain={handlekeyUpgain}  nom={nom}  handleMouse={handleMouse} Count={Count} setCount={setCount} handleMouseleave={handleMouseleave} HandleSubmit={HandleSubmit}  >
    <div className="w-full md:w-1/1 px-3  md:mb-0">
       
    <div id="resultat" className="block text-red-700"></div>
    <div id="erreur" className="block text-red-500"></div>
    </div>
    </Formulaire>
    </>
    )
}
export default Insertion