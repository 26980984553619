import { useParams } from "react-router-dom";
import axios from "../../../../api/axios";
import { useEffect } from "react";

function Delete(){

    const {id}=useParams()
    useEffect(()=>{

    },[])
return(
    <>
    
    </>
)
}

export default Delete