import { useSelector } from "react-redux"
import { Navigate,Outlet } from "react-router-dom"
import { getCookie, isEmpty } from "../Utilities/Utils"
import Navbar from "../Composants/utilitaires/Navbar"
function AuthLayout(){
    if(!isEmpty(localStorage.getItem("email")) && !isEmpty(localStorage.getItem("id")) && !isEmpty(localStorage.getItem("idecole"))){
       localStorage.setItem("navbar",true)
        return(
                 <Outlet/>
        )
    }
    else{
        localStorage.setItem("navbar",true)

        return(
            <Navigate to="/connexion"/>
        )
    }
   /// return !isEmpty(localStorage.getItem("email")) && !isEmpty(localStorage.getItem("id")) && !isEmpty(localStorage.getItem("idecole")) ? <Outlet/> : <Navigate to="/connexion"/>
}
export default AuthLayout