import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../../api/axios";
import { useEffect,useState } from "react";
import { isEmpty } from "../../../../Utilities/Utils";
import Swal from "sweetalert2";

function Delete(){

    const {id}=useParams()
    const [classe,setClasse]=useState([])
    const [etat,setEtat]=useState("")
    const navigate=useNavigate()
    useEffect(
      ()=>{
            HandleRequest()
        },[]
    )
    const confirmDelete=()=>{
        
        Swal.fire({
            title:"Confirmation de suppression",
            text:"Voulez-vous vraiment supprimer cette classe ?",
            icon:"question",
            confirmButtonText:"Supprimer",
            showConfirmButton:true,
            showCancelButton:true,
            confirmButtonColor:"red",
        }).then((res)=>{
                if (res.isConfirmed){
                    HandleDelete()
                }
        })
    }
    const HandleDelete=async()=>{
        
        if(etat==true){
            await axios.delete(`api/classe/${id}`).then((res)=>{
                Swal.fire({
                    title: "Message de confirmation",
                    text:res.data.message,
                    icon:"information"
                })
            }).catch((err)=>{
                Swal.fire({
                    title: "Erreur",
                    text:err.message,
                    icon:"error"
                })
            })
           
        }
        else{
            await axios.post(
                `api/classe/${id}`
            ).then((res)=>{
                Swal.fire({
                    title: "Message de confirmation",
                    text:res.data.message,
                    icon:"information"
                })
            }).catch((err)=>{
                Swal.fire({
                    title: "Erreur",
                    text:err.message,
                    icon:"error"
                })
            })
        }
        navigate("/Classes")
    }
    const HandleRequest=async()=>{
        
        await axios.get(
            `api/myclasse/${id}`
        ).then((res)=>{

            setClasse(res.data.myclasse)
            setEtat(res.data.classedeletable)
        }).catch((err)=>{

        })
    }
    return(
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form">
                            <legend>
                                Classe :  <span className="text-danger">{ !isEmpty(classe?.nom) ? classe?.nom : "Aucune classe ne correspond à votre requête" } </span>
                            </legend>
                            <div className="form-group mb-2">
                                <label htmlFor="motif" className="form-label">Motif de suppression</label>
                                <select name="motif" id="motif" className="form-select">
                                    <option value="" selected disabled>Sélectionnez votre motif</option>
                                    <option value="erreur d'ajout">La classe a été ajoutée par erreur</option>
                                    <option value="fermeture de la classe">Fermeture definitive de la classe</option>
                                    <option value="fermeture temporaire">Fermeture temporaire de la classe</option>
                                </select>
                            </div>
                            <div className="form-group mb-2">
                                
                                <button className="btn btn-danger float-start" type="button" onClick={confirmDelete}>Supprimer</button>
                                <button className="btn btn-primary float-end" onClick={()=>navigate("/Classes")}>Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Delete