import axios from "../../../../api/axios"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import loadingIcon from '../../../../Images/loading.gif'

import { isEmpty } from "../../../../Utilities/Utils"
import Swal from "sweetalert2"
function Insertion(){
    const {id}=useParams()
    const [enseignants,setEnseignants]=useState([])
    const [loading,setLoading]=useState(false)
    const [classes,setClasses]=useState([])
    const [classe,setClasse]=useState("")
    const [msg,setMsg]=useState("")
    const records=classes.filter((clas)=>clas.idtitulaire==null)
   // const recordsenseignants=enseignants.filter((ensei)=>ensei.idclasse==classe)
  // const uniqueenseignant=[...new Set(recordsenseignants.flatMap(({enseignants})=>enseignants))]
    const navigate=useNavigate()
    useEffect(()=>{
        handleclasses()
    },[])
    const handleclasses=async()=>{
        await axios.post('api/getclasses',{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
            setClasses(res.data.items)
        }).catch((err)=>{
    })
    }
 
   /* const handleenseignants=async()=>{
            await axios.post('api/getenseignantclasse',{idecole:localStorage.getItem('idecole'),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
                setEnseignants(res.data.items)
            }).catch((err)=>{

            })
    }
*/
const handleenseignants=async(clas)=>{
    await axios.post(`api/classeenseignants/${clas}`,{idecole:localStorage.getItem('idecole'),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setEnseignants(res.data.enseignants)
        if(res.data.enseignants.length==0){
            setMsg("Aucun cour n'a encore été attribué à un enseignant dans cette classe")
        }
        else{
            setMsg("")
        }
    }).catch((err)=>{

    })
}

    const handlchek=()=>{
        document.querySelector('input[name=enseignant]:checked')===null ?
            Swal.fire({
                title:"Erreur de soumission",
                text:"Aucun titulaire n'a été seléctionné",
                icon:"error"
            })
        :
            Swal.fire({
                title:"Demande de confirmartion",
                text: "Etes-vous sûr de vouloir confirmer ?",
                icon:'question',
                showConfirmButton:true,
                showCancelButton:true,
                confirmButtonText:"Confirmer"
            }).then((res)=>{
                res.isConfirmed &&
                   handlesubmit()
            })
    }
    const handlesubmit=async()=>{
        const valeur=document.querySelector('input[name=enseignant]:checked').value
        await axios.post('api/Titulariat',{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire"),idclasse:classe,idenseignant:valeur}).then((res)=>{
             if( res?.data?.status==200){
                Swal.fire({
                    title:"Confirmation",
                    text:res?.data?.message,
                    icon:"success"
                })
                setClasse("")
                handleclasses()

             }
              else if (res?.data?.status==410) {
                Swal.fire({
                    title:"Erreur d'enregistrement",
                    text:res?.data?.message,
                    icon:"error"
                })
              }
                    
        }).catch((err)=>{

        })
    }
    const Iteration=()=>{
        return (loading) ? (
            <div className='w-100 mx-auto'>
                <img src={loadingIcon} alt='loading'/>
            </div>
        ) : (
            enseignants.length > 0 &&
            enseignants.map((enseign,indexV)=>
               /* records.filter((enseignant)=>{
                    return search === '' ? enseignant : enseignant.nom_complet.includes(search)
                }).map((enseignant,indexV)=>*/
                {
                  return(
                       <div className="col-md-4 card border-0">
                            <div className="card-body">
                                <div className="form-check fw-bold fs-4 text-capitalize mb-2" key={indexV} >
                                    <input type="radio" name="enseignant" className="form-check-input" value={enseign.idenseignant} />
                                    <label htmlFor="enseignant" className="form-check-label">{ enseign.nom_complet}</label>
                                </div>
                            </div>
                       </div>
                            
                            
                        
                  )
                    
                }
                )
            
        )
        
    }
    return(
        <>
            <div className="container">
                <div className="row justify-content-start">
                    <div className="col-md-6 mb-2">
                        <div className="form-outline">
                            <label htmlFor="" className="form-label">Classe</label>
                            <select name="" id="" className="form-select form-select-lg" onChange={(e)=>setClasse(e.target.value)}  onBlur={(e)=>handleenseignants(e.target.value)} value={classe}>
                                <option value="" selected disabled >Choix de la classe</option>
                                {
                                    records.map((clas)=>
                                        <option value={clas.id}>{clas.nom}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>
                {
                    !isEmpty(classe) && 
                
                <div className="row justify-content-start" id="liste_enseignant">
                  <legend className="fw-bold text-primary card-title">Choix du titulaire de la classe</legend>
                                
                                {
                                  
                                        Iteration()
                                } 
                                <div className="text-danger">{msg}</div>
                    <div className="row justify-content-start">
                        {
                            enseignants.length>0 &&
                        <div className="col-md-3 col-6 mt-4">
                            <button className="btn btn-primary" type="button" onClick={handlchek}>Soumettre</button>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>
        
        </>
    )
}
export default Insertion