import axios from '../../../../api/axios';
import React, {useState,useEffect} from 'react'
import {EyeFill,PencilFill,Trash3Fill,PersonPlus} from "react-bootstrap-icons"
import { useNavigate,useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import loadingIcon from '../../../../Images/loading.gif'

function Classe() {
    let i=0
    const [cours_enseignes,setCours_enseignes]=useState([])
    const [curentpage,setCurentpage]=useState(1)
    const [search,setSearch]=useState('')
    const [my_classe,setMy_classe]=useState("")
        const [classes,setClasses]=useState([])


    const [recordperpage,setRecordperpage]=useState(50)
    let navigate=useNavigate()
    const lastIndex=curentpage * recordperpage
    const firstIndex=lastIndex - recordperpage
    const {id}=useParams()
    const records= search.toLowerCase() === '' ? cours_enseignes.slice(firstIndex, lastIndex) : cours_enseignes.filter((cours_enseigne)=>{return search.toLowerCase() === '' ? cours_enseigne : cours_enseigne.cours.includes(search) || cours_enseigne.classe.toLowerCase().includes(search)}).slice(firstIndex, lastIndex)
    const recordNpage=search.toLowerCase() === '' ? cours_enseignes : cours_enseignes.filter((cours_enseigne)=>{ return cours_enseigne.cours.includes(search) || cours_enseigne.classe.toLowerCase().includes(search)})
    const npage=Math.ceil(recordNpage.length/recordperpage)
    const numbers=[...Array(npage+1).keys()].slice(1)      
   //const recordperpage=2
    
    //const numbers=[1,2,3,4,5]
    const [loading,setLoading]=useState(false)
    

    useEffect(
        ()=>{
                Classes_initialesRequest()
                handle_classe_request()
        },[])
    const Classes_initialesRequest=async ()=>{
        await axios.get('api/Coursenseigneclasse').then(
            (res)=>{
                setCours_enseignes(res.data.items)
                setLoading(false)
                console.log(res)
            }
        ).catch((err)=>{
            console.log(err)
        })
    }
    const handle_classe_request=async()=>{
        try{
            await axios.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{

                setClasses(res.data.items)
            })
        }
        catch(e){

        }
    }
    const Iteration=()=>{
        return loading ? (
            <div className='w-100 mx-auto'>
                <img src={loadingIcon} alt='loading'/>
            </div>
        ) : (
            
                records.filter((cours_enseigne)=>{
                    return search.toLowerCase() === '' ? cours_enseigne : cours_enseigne.cours.toLowerCase().includes(search) || cours_enseigne.classe.toLowerCase().includes(search)
                }).map((cours_enseigne,indexV)=>
                {
                  return(
                        <tr key={indexV} className='ligne'>
                            <td>{indexV+1}</td>
                            <td>{cours_enseigne.cours.toLowerCase()}</td>
                            <td>{cours_enseigne.classe}</td>
                            <td>{cours_enseigne.ponderation}</td>
                            <td><EyeFill color="lightgreen" size={20} className='d-inline-flex mx-2 icone'  /><PencilFill color="royalblue" size={20} className='d-inline-flex mx-2 icone' onClick={()=>navigate(`${cours_enseigne.id}`)}/>  <Trash3Fill color='brown' size={20} className='d-inline-flex mx-2 icone' onClick={()=>AlertNotification(cours_enseigne.id)} /> </td>
                        </tr>
                  )
                    
                }
                )
            
        )
        
            }   
            function AlertNotification(idcours_enseigne){
                Swal.fire({
                    title:'Etes-vous sûr de le supprimer ?',
                    text:'Ce cours sera supprimé',
                    icon:'warning',
                    showDenyButton:true,
                    showCancelButton:true,
                    confirmButtonText:'Confirmer',
                    denyButtonText:'Annuler'
                }).then((resultat)=>{
                    if(resultat.isConfirmed){
                        navigate(`/coursenseigneclasse/delete/${idcours_enseigne}`)
                            }
                })
            }
            console.log(records)
    return(
        <>
        
        <div className="row justify-content-start mx-2">
            
            <div className='col-md-4 mb-2'> 
               <h4 className="text-dark">
                Liste des cours (Total : {cours_enseignes.length})
                </h4>
                
            </div>
            <div className="col-md-3 mb-2">
                    <button name="ajouter" id="ajouter" onClick={()=>navigate('insertion')} className='btn btn-success' ><span className='text-center'> <PersonPlus color='white' size={25} className='d-inline-flex' /> Dispatcher un cours </span></button>

                </div>
            <div className='col-md-3 mb-2'>
                <input type="text" name="recherche" id="recherche" placeholder='Rechercher un cours' className='form-control form-control-sm'  onChange={(e)=>setSearch(e.target.value.toLowerCase())} value={search} />

            </div>
            <div className="col-md-2 mb-2">
                <select name="classe" id="" className='form-select' onChange={(e)=>setMy_classe(e.target.value)}>
                    <option value="" selected>Toutes les classes</option>
                    {
                                    classes.map((cla)=>(
                                        <option  value={cla.id} key={cla.id}  >{cla.nom}</option>
                                        )
                                    )
                                }
                </select>
            </div>
        </div>
        <div>
           <table className="table table-striped table-hover">
                <thead className='thead-light'>
                    <tr className=''>
                        <th >#</th>
                        <th>Cours</th>
                        <th>Classe</th>
                        <th>Pondération</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody className="text-slate-700 text-xs">
                    {Iteration()}
                </tbody>
           </table>

        </div>
        <nav>
                <ul className='pagination mt-2 mx-4'>
                    <li className='page-item  '>
                        <a href="#" className='page-link' onClick={Prepage}>Prev</a>
                    </li>
                {
                    numbers.map((number,index)=>{
                        return(
                            <li className={`page-item ${curentpage === number ? 'active': '' }`} key={index}>
                                <a href="#" className='page-link' onClick={()=>changeCPage(number)}>{number}</a>
                            </li>
                        )
                    })
                    
                }
                    <li className='page-item '>
                        <a href="#" className='page-link' onClick={Nextpage}>Next</a>
                    </li>
                    <span className='mr-8'>
                    <input type="number" name="nbrperpag" className='form-control' id="nbrperpag" placeholder='Nombre par page' value={recordperpage} onChange={(e)=>setRecordperpage(e.target.value !== '' ? e.target.value : 1)} onBlur={()=>setCurentpage(1)} />
                    </span>
                </ul>
        </nav>

        </>
    )
function Prepage(){
    if(curentpage > 1){
        setCurentpage(curentpage-1)
    }
}
function changeCPage(id){
   setCurentpage(id)
}
function Nextpage(){
    if(curentpage < npage){
       // alert(curentpage)
        setCurentpage(curentpage+1)
    }
}

}
export default Classe

