import axios from '../../../../api/axios'
import React,{useState,useEffect} from 'react'
import Swal from 'sweetalert2'
import { useParams,useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getCookie, isEmpty } from '../../../../Utilities/Utils'
import { addsection } from '../../../../actions/section.action'
import { addclasse } from '../../../../actions/classe.action'
import { getsession } from '../../../../actions/user.action'


function Insertion(){
    //const sectionsB=useSelector((state)=>state.classeReducer)
   // 
   
    const [sectiona,setSectiona]=useState("")
    const [sectionchange,setSectionchange]=useState(false)
    const [sectionOrg,setSectionorg]=useState([])
    const [classe_initiales,setClasses_initiales]=useState([])
    const [status_code,setStatus_code]=useState("")
    const {id}=useParams()
    const [sectionsC,setSectionsC]=useState([])
    const classeselector=useSelector((state)=>state.classeReducer)
   // const statuscode=useSelector((state)=>state.sectionReducer.status_code)
    const userselector=useSelector((state)=>state.userReducer)
    const dispatch=useDispatch()
    const csrf=()=>axios.get('sanctum/csrf-cookie')
    
    useEffect(()=>{
        
        HandleRequest()
        Classes_initialesRequest()
    },[])
    useEffect(()=>{
       
        handleRequestSection_org()
    },[sectionchange])
        const Classes_initialesRequest=async ()=>{
        await axios.get('/api/classeinitiale').then(
            (res)=>{
                setClasses_initiales(res.data.items)
               
            }
        ).catch((err)=>{
            console.log(err)
        })
    }
   const handleRequestSection_org=async()=>{
        await axios.get(`/api/sectionorg/${userselector?.data?.idecole}`).then((res)=>{
            setSectionorg(res.data.items)
            
        }).catch((err)=>{
            console.log(err)
        })
    }
    const HandleRequest=async()=>{
        await axios.get('/api/Section').then((res)=>{
            setSections(res.data.items)
        }).catch((err)=>{
            console.log(err)
        })
    }
    
    function handleChecked(e){
        setFirstEB(e.target.value)
    }
    const [section,setSection]=useState("")
    const [classe1,setClasse1]=useState("")
    const [classe2,setClasse2]=useState("")
    const [classe3,setClasse3]=useState("")
    const [classe4,setClasse4]=useState("")
    const [sections,setSections]=useState([])
    const [nbclasse1,setNBClasse1]=useState(0)
    const [nbclasse2,setNBClasse2]=useState(0)
    const [nbclasse3,setNBClasse3]=useState(0)
    const [nbclasse4,setNBClasse4]=useState(0)
    const [firstE4,setFirstEB]=useState(false)
    const [datasoumission,setDatasoumission]=useState({})
    const [status_code_classe,setStatus_code_classe]=useState("")
    const [status_message,setStatus_message]=useState("")
    const [status_message_classe,setStatus_message_classe]=useState("")

    const HandleChange=(e)=>{
        setSection(e)
        
    }
const Data=classe_initiales.filter(classe=>classe.idsection==section)
const Datasection=sections.filter(sectio=>sectio.id==section)
   function errorI(errchk,errinp){
        if(errchk==true){
            if(errinp=="" || errinp<1){
            return(
            <span className='text-danger'>
                Entrer un nombre positif
            </span>
            )
        }
        }
   }
    const Affichage=()=>{
    if(section!==""){  
      if(section==3){
        return(
            <div className="row mx-4">
                <div className='row mb-2'>
                    <div class="form-check col-md-8">
                        <label class="form-check-label" for="flexCheckChecked">
                                la 7ème Education de base  
                        </label>
                        <input class="form-check-input" type="checkbox"  onChange={(e)=>setClasse1(e.target.checked)} value={classe1} id="flexCheckChecked"/>
                    </div>
                    <div className="form-input col-md-4">
                        <input type="number" className='form-control' placeholder='nombre des classes ici'   onChange={(e)=>setNBClasse1(classe1===true ? e.target.value : "")} value={classe1===true ? nbclasse1 : ""}  />
                    <div className="">{
                           errorI(classe1,nbclasse1)
                           }
                    </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div class="form-check col-md-8">
                    <label class="form-check-label" for="flexCheckChecked">
                    la 8ème Education de base  
                    </label>
                    <input class="form-check-input" type="checkbox" id="flexCheckChecked" onChange={(e)=>setClasse2(e.target.checked)} value={classe2} />
                    </div>
                    <div className="form-input col-md-4">
                    <input type="number" className='form-control' placeholder='nombre des classes ici' onChange={(e)=>setNBClasse2(classe2===true ? e.target.value:"")} value={classe2===true ? nbclasse2 : ""} />
                    {
                           errorI(classe2,nbclasse2)
                           }
                    </div>
                </div>
               
                
            </div>
            
        )
      }else{
        return(
            
            <div className="row mx-4">
                
            <div className='row mb-2'>
                <div class="form-check col-md-8">
                <label class="form-check-label" for="flexCheckChecked">
                {Data[0]?.nom}
                
                </label>
                <input class="form-check-input" type="checkbox"  onChange={(e)=>setClasse1(e.target.checked)} value={classe1} id="flexCheckChecked"/>
                </div>
                <div className="form-input col-md-4">
                <input type="number" className='form-control' placeholder='nombre des classes ici'   onChange={(e)=>setNBClasse1(classe1===true ? e.target.value:"")} value={classe1===true ? nbclasse1 : ''} />
                <div className="">{
                           errorI(classe1,nbclasse1)
                           }
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div class="form-check col-md-8">
                <label class="form-check-label" for="flexCheckChecked">
                {Data[1]?.nom}
                </label>
                <input class="form-check-input" type="checkbox" id="flexCheckChecked" onChange={(e)=>setClasse2(e.target.checked)} value={classe2} />
                </div>
                <div className="form-input col-md-4">
                <input type="number" className='form-control' placeholder='nombre des classes ici' onChange={(e)=>setNBClasse2(classe2===true ? e.target.value:"")} value={classe2===true ?nbclasse2:""} />
                <div className="">{
                           errorI(classe2,nbclasse2)
                           }
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                    <div class="form-check col-md-8">
                    <label class="form-check-label" for="flexCheckChecked">
                        {Data[2]?.nom}
                    </label>
                    <input class="form-check-input" type="checkbox" id="flexCheckChecked" onChange={(e)=>setClasse3(e.target.checked)} value={classe3} />
                    </div>
                    <div className="form-input col-md-4">
                    <input type="number" className='form-control' placeholder='nombre des classes ici' onChange={(e)=>setNBClasse3(classe3===true ? e.target.value:"")} value={classe3===true ?nbclasse3:""} />
                    <div className="">{
                           errorI(classe3,nbclasse3)
                           }
                    </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div class="form-check col-md-8">
                    <label class="form-check-label" for="flexCheckChecked">
                    {Data[3]?.nom}
                    </label>
                    <input class="form-check-input" type="checkbox" id="flexCheckChecked" onChange={(e)=>setClasse4(e.target.checked)} value={classe4} />
                    </div>
                    <div className="form-input col-md-4">
                    <input type="number" className='form-control' placeholder='nombre des classes ici' onChange={(e)=>setNBClasse4(classe4===true ?e.target.value:"")} value={classe4===true ?nbclasse4:""} />
                    <div className="">{
                           errorI(classe4,nbclasse4)
                           }
                    </div>
                    </div>
                </div>
           
        </div>
                
               
        )
            
            
            
        
      }
    } 
   }
  /* const handlesoumissio=async()=>{
    //const nombre_classe=parseInt(nbclasse1)+parseInt(nbclasse2)+parseInt(nbclasse3)+parseInt(nbclasse4)
    await axios.post('http://localhost:8000/api/Sectionorganisee',{section:section,section_nom:Datasection[0].nom,idecole:idecole,nombre_classe:nombre_classe}).then((res)=>{
         
        setStatus_code(res.data.status_code)
        setStatus_message(res.data.status_message)
        if(res.data.status_code==200){
            Swal.fire({
                title:'Message',
                text:'Votre requête a été exécutée avec succès',
                icon:'success'
            })
        }
         console.log(res)
    }).catch((err)=>{
        console.log(err)
    })
   }*/
   /*const soumission=async()=>{
    await axios.post('/api/classe',{idsection:parseInt(section),idecole:parseInt(localStorage.getItem("idecole")),data:datasoumission}).then((res)=>{
        setStatus_code_classe(res.data.status_code)
        setStatus_message_classe(res.data.status_message)
        if(status_code_classe==200){
            Swal.fire({
                title:'Message de confirmation',
                text:'Vos classes ont été ajoutées',
                icon:'success'
            }).then((res)=>{
                setSection("")
                setClasse1("")
                setClasse2("")
                setClasse3("")
                setClasse2("")
                setNBClasse1("")
                setNBClasse2("")
                setNBClasse3("")
                setNBClasse4("")
            })
        }
        else{
            Swal.fire({
                title:'Message d\'erreur',
                text:'Une erreur est survenue sur le serveur',
                icon:'error'
            })
        }
    }).catch((err)=>{
    })
   }*/
   function handleClick(){
       if(classe1==false && classe2==false && classe3==false && classe4==false){
        Swal.fire({
            title:'Erreur de soumission',
            text:'Impossible d\'ajouter une section sans classe',
            icon:'error'
        })
       }
       else{
        if(section==3){
          /*  setDatasoumission([
                {
                    classe:classe1,
                    nbclasse:parseInt(nbclasse1),
                    nomclasse:Data[0].nom,
                    niveau:Data[0].niveau

                },
                {
                    classe:classe2,
                    nbclasse:parseInt(nbclasse2),
                    nomclasse:Data[1].nom,
                    niveau:Data[1].niveau

                }
            ])*/
            const table=[
                {
                    classe:classe1,
                    nbclasse:parseInt(nbclasse1),
                    nomclasse:Data[0].nom,
                    niveau:Data[0].niveau,
                    classe_initiale:Data[0].id 
                },
                {
                    classe:classe2,
                    nbclasse:parseInt(nbclasse2),
                    nomclasse:Data[1].nom,
                    niveau:Data[1].niveau,
                    classe_initiale:Data[1].id, 

                }
            ]
            if(classe1==true && nbclasse1<1){
                Swal.fire({                                                                                                                                     
                    title:'Erreur de soumission',
                    text:'Veuillez d\'abord entrer le nombre des classes de la 7ème',
                    icon:'error'
                })
            }
            else if(classe2==true && nbclasse2<1){
                Swal.fire({
                    title:'Erreur de soumission',
                    text:'Veuillez d\'abord entrer le nombre des classes de la 8ème',
                    icon:'error'
                })
            }
            else{
                
                Swal.fire({
                    title:'Confirmation de soumission',
                    text:'Etes-vous sûr de vouloir ajouter cette section ?',
                    icon:'question',
                    showConfirmButton:true,
                    confirmButtonText:'Confirmer',
                    showCancelButton:true,
                    cancelButtonColor:'red',

                }).then((resultat)=>{
                    if(resultat.isConfirmed){
                    const nombre_classe=parseInt(nbclasse1)+parseInt(nbclasse2)
                       const elements=([{
                            'idsection':parseInt(section),
                            'idecole':parseInt(localStorage.getItem("idecole")),
                            'section_nom':Datasection[0].nom,
                            'nombre_classe':nombre_classe
                        }
                        ])
                    
                        const dataform=([
                            {
                                'idsection':parseInt(section),
                                'idecole':parseInt(localStorage.getItem("idecole")),
                                'annee_scolaire':localStorage.getItem("annee_scolaire"),
                                'items':table
                            }  ])
                      

                        dispatch(addsection(elements))

                        dispatch(addclasse(dataform))
                        if(classeselector?.status===200 && classeselector?.data?.status===235){
                            setSection("")
                            setClasse1("")
                            setClasse2("")
                            setClasse3("")
                            setClasse2("")
                            setNBClasse1("")
                            setNBClasse2("")
                            setNBClasse3("")
                            setNBClasse4("")
                        }
                        
                       
                        
                  
                        
                        
                       
                    }
                })
            }
        }
        else{
            if(classe1==true && nbclasse1<1){
                Swal.fire({
                    title:'Erreur de soumission',
                    text:'Veuillez d\'abord entrer le nombre des classes de la 1ère',
                    icon:'error'
                })
            }
            else if(classe2==true && nbclasse2<1){
                Swal.fire({
                    title:'Erreur de soumission',
                    text:'Veuillez d\'abord entrer le nombre des classes de la 2ème',
                    icon:'error'
                })
            }
            else if(classe3==true && nbclasse3<1){
                Swal.fire({
                    title:'Erreur de soumission',
                    text:'Veuillez d\'abord entrer le nombre des classes de la 3ème',
                    icon:'error'
                })
            }
            else if(classe4==true && nbclasse4<1){
                Swal.fire({
                    title:'Erreur de soumission',
                    text:'Veuillez d\'abord entrer le nombre des classes de la 4ème',
                    icon:'error'
                })
            }
            else{
               const table=[
                    {
                        classe:classe1,
                        nbclasse:parseInt(nbclasse1),
                        nomclasse:Data[0].nom,
                        classe_initiale:Data[0].id,
                        niveau:Data[0].niveau

                    },
                    {
                        classe:classe2,
                        nbclasse:parseInt(nbclasse2),
                        nomclasse:Data[1].nom,
                        classe_initiale:Data[1].id,
                        niveau:Data[1].niveau

                    },
                    {
                        classe:classe3,
                        nbclasse:parseInt(nbclasse3),
                        nomclasse:Data[2].nom,
                        classe_initiale:Data[2].id,
                        niveau:Data[2].niveau

                    },
                    {
                        classe:classe4,
                        nbclasse:parseInt(nbclasse4),
                        nomclasse:Data[3].nom,
                        classe_initiale:Data[3].id,
                        niveau:Data[3].niveau

                    }
                ]
                Swal.fire({
                    title:'Confirmation de soumission',
                    text:'Etes-vous sûr de vouloir ajouter cette section ?',
                    icon:'question',
                    showConfirmButton:true,
                    confirmButtonText:'Confirmer',
                    showCancelButton:true,
                    cancelButtonColor:'red',

                }).then((resultat)=>{
                    if(resultat.isConfirmed){
                    const nombre_classe=parseInt(nbclasse1)+parseInt(nbclasse2)+parseInt(nbclasse3)+parseInt(nbclasse4)
                     const elements=([{
                            'idsection':parseInt(section),
                            'idecole':parseInt(localStorage.getItem("idecole")),
                            'section_nom':Datasection[0].nom,
                            'nombre_classe':nombre_classe
                        }
                        ])
                      dispatch(addsection(elements))
                      const dataform=([
                        {
                            'idsection':parseInt(section),
                            'idecole':parseInt(localStorage.getItem("idecole")),
                            'annee_scolaire':localStorage.getItem("annee_scolaire"),
                            'items':table
                        }  ])
                      dispatch(addclasse(dataform))
                      if(classeselector?.status===200 && classeselector?.data?.status===235){
                        Swal.fire({
                            title:'success',
                            text:'Enregistrement effectué avec succès',
                            icon:'success'
                        })
                            setSection("")
                            setClasse1("")
                            setClasse2("")
                            setClasse3("")
                            setClasse2("")
                            setNBClasse1("")
                            setNBClasse2("")
                            setNBClasse3("")
                            setNBClasse4("")

                        }
                    }
                })
            }
        }
       }
      
   }
    return(
        <>
    <div className="container-fluid mx-4">
        <div className="row g-3 justify-content-md-center  mb-2">
            
                <label for="inputEmail4" className="col-form-label col-md-2">Section organisée :</label>
                <div className="col-md-6 mr-4">
                    <select class="form-select" aria-label="Default select example" onChange={(e)=>{HandleChange(e.target.value)}} value={section}>
                        <option value="" selected>Sélectionner la section à ajouter</option>
                        { !isEmpty(sections) &&
                            sections.map((sect)=>
                                <option value={sect.id}>{sect.nom}</option>
                            )
                        }
                    </select>
                </div>
        </div>
        <div className="row g-3">
            <div className="col-md-6 " id="left">
                {
                    section!=="" ?
                    <div className="row mb-2">
                    <h3>Cochez les classes que vous organisez</h3>
                    </div> : ""
                }
                   {Affichage()}
                {
                    section!=="" ?
                   <div className="row mb-2 mt-2">
                    <div className="col-md-8">
                        <button className='btn btn-primary' onClick={handleClick}>Enregistrer les classes</button>
                    </div>
                    </div>
                :""
                }
            </div>
            <div className="col-md-6">
                        <h3 className='text-dark'>
                            Mes sections existantes (Total :{!isEmpty(sectionOrg) && sectionOrg.length})
                        </h3>
                <div className="row">
                        { !isEmpty(sectionOrg) &&
                            sectionOrg.map(sectio=>
                            <li key={sectio.id}>
                                {
                                    sectio.nom+' (Nombre de classe :'+sectio.nombre_classe+' )'
                                }
                            </li>
                            )
                        }
                </div>
            </div>

        </div>
        
    </div>
   
        </>
    )
}
export default Insertion