import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Formulaire from '../../../ModelesPages/Modele1/inscription'
import loadingIcon from '../../../../Images/loading.gif'
import { isEmpty } from '../../../../Utilities/Utils';


function Insertion(){
    const [adresse,setAdresse]=useState("");
    const [Count,setCount]=useState(0);
    const [lieu_naissance,setLieu_naissance]=useState("")
    const [date_naissance,setDate_naissance]=useState("")
    const [nom,setNom]=useState("");
    const [postnom,setPostnom]=useState("");
    const [prenom,setPrenom]=useState("");
    const [sexe,setSexe]=useState("");
    const [loading,setLoading]=useState(false)
    const [selectedvalue,setSelectedvalue]=useState("")

    const [numero_serni,setNumero_serni]=useState("");
    const [nom_complet,setNom_complet]=useState("")
    const [eleves,setEleves]=useState([])
    const [classes,setClasses]=useState([])
    const [my_classe,setMy_classe]=useState("")
    const [my_eleve,setMy_eleve]=useState("")
    const search=nom+' '+ postnom+' '+prenom
    const [status_code,setStatus_code]=useState()
    const [error_submission,setError_submission]=useState(false)
    const csrf=()=>axios.get('sanctum/csrf-cookie')
    const [submission,setSubmission]=useState(false)
    const [message,setMessage]=useState("")

    const [recordperpage,setRecordperpage]=useState(50)
    
    useEffect(()=>{
        handleRequest()
        handle_classe_request()
    },[])
    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    const handle_classe_request=async()=>{
        try{
            await axios.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{

                setClasses(res.data.items)
            })
        }
        catch(e){

        }
    }
    const handleRequest=async()=>{
        setLoading(true)
    
        await axios.get('api/eleves').then((res)=>{
            setEleves(res.data.items)
            setLoading(false)
            setStatus_code(res.data.status_code)
        }).catch((err)=>{
            console.log(err)
        })
    }
    const records=eleves.filter((eleve)=>{return nom === '' ? eleve.enseignant==null && eleve.agent==null : eleve.nom_complet.includes(nom) && eleve.enseignant==null && eleve.agent==null}).slice(0, 49)
    const dataselected= !isEmpty(selectedvalue)  && eleves.filter((eleve)=>{return !isEmpty(selectedvalue) && eleve.id.includes(selectedvalue)}).slice(0, 49)

    const handleselect=(idselect,nomeleve)=>{
        if(!isEmpty(dataselected) ){
            setMy_eleve(idselect)
            setNom(nomeleve)
        }
    }
    const Iteration=()=>{
        return (loading) ? (
            <div className='row justify-content-center'>
                <div className="col-md-6"></div>
                <img src={loadingIcon} alt='loading'/>
            </div>
        ) : (
            records.length > 0 &&
                records.filter((eleve)=>{
                    return nom === '' ? eleve : eleve.nom_complet.includes(nom)
                }).map((eleve,indexV)=>
                {
                  return(
                    <div className="row" key={indexV} onClick={()=>handleselect(eleve.id,eleve.nom_complet)}>
                        <div className="col-md-6">
                                <a>
                                    {eleve.nom_complet}
                                </a>
                        </div>
                    </div>
                        
                  )
                    
                }
                )
            
        )
        
            }   
    const HandleSubmit=async ()=>{
        
        if(!isEmpty(nom) && !isEmpty(my_classe) && !isEmpty(my_eleve)){
            await axios.post('api/Inscription',{ideleve:my_eleve,numero_serni:numero_serni,idclasse:my_classe,idecole:localStorage.getItem("idecole"),type_transact:"inscription",annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
                setNom("")
            setPostnom("")
            setPrenom("") 
            setSexe("")
            setMy_classe("")
            setMy_eleve("")
            setAdresse("")
            setDate_naissance("")
            setLieu_naissance("")
            setNom_complet("")
            setNumero_serni("")
            setMessage(res.data.message)
            setSubmission(true)
            setError_submission(false)
             
        }).catch((err)=>{
            setMessage(err)

                
        })
        }
        else{
            setError_submission(true)
            setSubmission(false)
        }
    
       //alert("i want to submit your form don't be worry")
    }
    
    return(
    <>
    <Formulaire title="Formulaire d'inscription" onclick={handleclick} message={message} Iteration={Iteration} my_eleve={my_eleve} setMy_eleve={my_eleve} classes={classes} my_classe={my_classe} setMy_classe={setMy_classe} error_submission={error_submission} submission={submission} setNom={setNom} handlekeyUpgain={handlekeyUpgain} setPostnom={setPostnom} setPrenom={setPrenom} setSexe={setSexe} setDate_naissance={setDate_naissance} setLieu_naissance={setLieu_naissance} nom={nom} postnom={postnom} prenom={prenom} sexe={sexe} date_naissance={date_naissance} lieu_naissance={lieu_naissance} handleMouse={handleMouse} Count={Count} setCount={setCount} handleMouseleave={handleMouseleave} HandleSubmit={HandleSubmit} setNumero_serni={setNumero_serni} numero_serni={numero_serni} adresse={adresse} setAdresse={setAdresse} setNom_complet={setNom_complet} >
    
    </Formulaire>
    </>
    )
}
export default Insertion