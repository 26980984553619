import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import loadingIcon from '../../../../Images/loading.gif'

import Formulaire from '../../../ModelesPages/Modele1/FormulaireCours_enseigne_classe'
import Swal from 'sweetalert2';

function Insertion(){

    const [Count,setCount]=useState(0);
    const [loading,setLoading]=useState(false)
    const [rechercher,setRechercher]=useState(false)
    const [classes,setClasses]=useState([])
    const [cours,setCours]=useState([])
    const [cour,setCour]=useState("")
    const [ponderation,setPonderation]=useState("")
    const [classe,setClasse]=useState("")
    const [cours_enseign,setCours_enseign]=useState([])
    

    
    
    
    useEffect(()=>{
        HandleRequest()
        HandleRequestCours()
        
    },[])
    useEffect(()=>{
        cours_classes()
    },[rechercher])
    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    const HandleRequest=async()=>{
        await axios.get('api/classeinitiale').then((res)=>{
           
            setClasses(res.data.items)
        }).catch((err)=>{
        })
    }
    const HandleRequestCours=async()=>{
        await axios.get('/api/Cours').then((res)=>{
           
            setCours(res.data.items)
        }).catch((err)=>{
        })
    }
    function confirmSave(e){
        e.preventDefault()
        if(classe !="" && cours!="" && ponderation!=""){
        Swal.fire({
            title:'Demande de confirmation',
            text:'Voulez-vous vraiment enregistrer ce cours dans cette classe ?',
            icon:'question',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            }
        })  
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord compléter tous les champs',
                icon:'warning'
            })
        }
    }

    
    const HandleSubmit=async (e)=>{
       
                await axios.post('api/Coursenseigneclasse',{cour:cour,classe:classe,ponderation:ponderation}).then((res)=>{
                    setCour("")
                    setPonderation("")
                    if(res.data.status_code===200){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:'Le cours a été dispaché avec succès',
                            icon:'success'
                            
                        })
                        setRechercher(!rechercher)
                    }
                    else{
                        Swal.fire({
                            title:'Message d\'erreur',
                            text:res.data.status_message,
                            icon:'error'
                            
                        })
                    }
                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    const cours_classes=async()=>{
        setLoading(true)
        await axios.get("api/Coursenseigneclasse").then((res)=>{
            setLoading(false)
            setCours_enseign(res.data.items)
        }).catch((err)=>{

        })
    }
    const records=cours_enseign.filter((cours)=>cours.idclasse_initiale==classe)
    const Iteration=()=>{
        return (loading) ? (
            <div className='w-100 mx-auto'>
                <img src={loadingIcon} alt='loading'/>
            </div>
        ) : (
            records.length > 0 &&
            records.map((cour,indexV)=>
               /* records.filter((enseignant)=>{
                    return search === '' ? enseignant : enseignant.nom_complet.includes(search)
                }).map((enseignant,indexV)=>*/
                {
                  return(
                        <tr key={indexV} className='fw-bold fs-4 text-capitalize' >
                            <td>{indexV+1}.</td>
                            <td> {cour.cours}</td>
                            <td> /{cour.ponderation}</td>
                           
                        </tr>
                  )
                    
                }
                )
            
        )
        
            }  

    return(
    <>
    <Formulaire title="Formulaire de gestion des cours" titlecours="Liste des cours de la classe" onclick={handleclick}   confirmSave={confirmSave} setClasse={setClasse} handlekeyUpgain={handlekeyUpgain}  classe={classe}  handleMouse={handleMouse} Count={Count} setCount={setCount} handleMouseleave={handleMouseleave} HandleSubmit={HandleSubmit} ponderation={ponderation} setPonderation={setPonderation} cour={cour} setCour={setCour} cours={cours}  classes={classes} >
       <div className="">
            {Iteration()}
       </div>
    </Formulaire>
    </>
    )
}
export default Insertion