


//function Formulaire({children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,count,setCount,nom,postnom,prenom,sexe,telephone,Image,setNom,setPostnom,setPrenom,setSexe,setImage,setTelephone,setNomcomplet}){
    function Formulaire({message,Iteration,my_eleve,setMy_eleve,classes,my_classe,setMy_classe,error_submission,submission,HandleSubmit,children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,nom,postnom,prenom,sexe,date_naissance,lieu_naissance,setNom,setPostnom,setPrenom,setSexe,setDate_naissance,setLieu_naissance,setNom_complet,numero_serni,setNumero_serni,adresse,setAdresse,ideleve}){
  
    return(
        <>
        <div className="container">
    <div className="row">
        <div className="col-md-5">
            <form className="mt-2" >
           
                <legend>
                    <h3 className="font-bold text-blue-500 mb-4 text-xl">
                        {title}
                    </h3>
                </legend>
                
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">ID ELEVE</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={my_eleve}  />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-12 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">ELEVE</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={nom} onChange={(e)=>setNom(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-12 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">CLASSE</label>
                            <select name="section" id="section" className="form-control form-select-lg" onChange={(e)=>setMy_classe(e.target.value)} value={my_classe} >
                                <option value="" selected disabled>Choix de la classe</option>
                                {
                                    classes.map((cla)=>(
                                        <option  value={cla.id} key={cla.id}  >{cla.nom}</option>
                                        )
                                    )
                                }
                                </select>                        </div>
                    </div>
              </div>
              <div className="row">
                    <div class="col-md-12 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Numéro Serni</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={numero_serni} onChange={(e)=>setNumero_serni(e.target.value)} />                          
                        </div>
                    </div>
              </div>
              <div className="row">
                {children}
              </div>
              {
                            error_submission && 
                      
              <div className="row justify-content-start">
              
                <div className="col-md-12 mb-2">
                    <span className="text-danger">
                        Veuillez compléter tous les champs obligatoires
                    </span>
                </div>
              </div>
              }
               {
                            submission && 
                      
              <div className="row justify-content-start">
              
                <div className="col-md-12 mb-2">
                    <span className="text-primary">
                        {message}
                    </span>
                </div>
              </div>
              }
              <div class="row">
                    <div class="col-md-12 mb-2">
                        <div class="form-outline">
                            <button type="button" className="btn btn-primary" onClick={HandleSubmit}>Enregistrer</button>
                        </div>
                    </div>
              </div>
              
                
                
            </form>
        </div>
        <div className="col-md-5">
                {Iteration()}
        </div>   
        </div>
    </div>
        </>
    )
}
export default Formulaire;