import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Formulaire from '../../../ModelesPages/Modele1/Formulaire'

function Insertion(){
    const [adresse,setAdresse]=useState("");
    const [Count,setCount]=useState(0);
    const [lieu_naissance,setLieu_naissance]=useState("")
    const [date_naissance,setDate_naissance]=useState("")
    const [nom,setNom]=useState("");
    const [postnom,setPostnom]=useState("");
    const [prenom,setPrenom]=useState("");
    const [sexe,setSexe]=useState("");
    const [numero_serni,setNumero_serni]=useState("");
    const [nom_complet,setNom_complet]=useState("")
    const [eleves,setEleves]=useState([])
    const [classes,setClasses]=useState([])
    const [my_classe,setMy_classe]=useState("")
    const search=nom+' '+ postnom+' '+prenom
    const [status_code,setStatus_code]=useState()
    const [error_submission,setError_submission]=useState(false)
    const csrf=()=>axios.get('sanctum/csrf-cookie')
    const [submission,setSubmission]=useState(false)
    useEffect(()=>{
        handleRequest()
        handle_classe_request()
    },[])
    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    const handle_classe_request=async()=>{
        try{
            await axios.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{

                setClasses(res.data.items)
            })
        }
        catch(e){

        }
    }
    const HandleSubmit=async ()=>{
        
        if(nom !="" && postnom !="" && sexe!="" && date_naissance!="" && lieu_naissance!="" && adresse!=""){
            await axios.post('api/eleves',{nom:nom.toLowerCase(),postnom:postnom.toLowerCase(),prenom:prenom.toLowerCase(),sexe:sexe,adresse:adresse.toLowerCase(),lieu_naissance:lieu_naissance.toLowerCase(),date_naissance:date_naissance,numero_serni:numero_serni,nom_complet:nom_complet.toLowerCase(),idclasse:my_classe,annee_scolaire:localStorage.getItem("annee_scolaire"),idecole:localStorage.getItem("idecole"),type_transact:"inscription"}).then((res)=>{
           console.log(res)
                setNom("")
            setPostnom("")
            setPrenom("") 
            setSexe("")
            setAdresse("")
            setDate_naissance("")
            setLieu_naissance("")
            setNom_complet("")
            setNumero_serni("")
            setSubmission(true)
            setError_submission(false)
             
        }).catch((err)=>{
                console.log(err)
                
        })
        }
        else{
            setError_submission(true)
            setSubmission(false)
        }
    
       //alert("i want to submit your form don't be worry")
    }
    const handleRequest=async()=>{
        await axios.get('api/eleves').then((res)=>{
            setEleves(res.data.items)
            setStatus_code(res.data.status_code)
        }).catch((err)=>{
            
        })
    }
    return(
    <>
    <Formulaire title="Formulaire d'inscription" onclick={handleclick} classes={classes} my_classe={my_classe} setMy_classe={setMy_classe} error_submission={error_submission} submission={submission} setNom={setNom} handlekeyUpgain={handlekeyUpgain} setPostnom={setPostnom} setPrenom={setPrenom} setSexe={setSexe} setDate_naissance={setDate_naissance} setLieu_naissance={setLieu_naissance} nom={nom} postnom={postnom} prenom={prenom} sexe={sexe} date_naissance={date_naissance} lieu_naissance={lieu_naissance} handleMouse={handleMouse} Count={Count} setCount={setCount} handleMouseleave={handleMouseleave} HandleSubmit={HandleSubmit} setNumero_serni={setNumero_serni} numero_serni={numero_serni} adresse={adresse} setAdresse={setAdresse} setNom_complet={setNom_complet} >
    <div className="col-md-6 mb-4">
        <div class="form-outline">
            <label class="form-label" for="firstName">NOM COMPLET</label>
            <input type="text"  class="form-control form-control-lg"  name="nom_complet" value={nom_complet} />                          
        </div>
    </div>
    </Formulaire>
    </>
    )
}
export default Insertion