import React from "react";
import { Document, Page, Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import { Table,TableRow,TableCell,TableHeader } from '@ag-media/react-pdf-table';
import Drapeau from "../photos/drapeau.png"
import Embleme from "../photos/embleme.png"  

const Tableau=({total2,classetoute,dataclasse,dataeleve,eleve,My_ecole,idclasse,isEmpty,getponderation,eleves,cours,mes_cours,cotes,total,totaux,place,maximum,classe,totauxgen})=>{
    const styles=StyleSheet.create({
        page:{
          flexDirection:"row",
          border:"3px  black solid",
        },
        section:{
         paddingHorizontal:"10px",
         paddingVertical:"10px",
          width:"100%",
          border:"1px solid black",
          justifyContent:"start",
          
          
          
        },
        theader:{
          fontWeight:'bold',
          fontSize:10,
          textAlign:"center"
        
        },
        othertext:{
          fontSize:12,
          textAlign:"left",
          width:"100%",
          display:"block",
          flex:1,
        },
        texta:{
          fontSize:12,
          textAlign:"center",
          width:"100%",
          display:"block",
          flex:1,
        },
        noborder:{
          borderStyle:"none",
        },
        tbranche:{
          width:"55%"
        },
        tother:{
          width:"5%"
        }
      
      })
      
  return(
    
    <Page size="A4" style={styles.page} wrap={false}>  
    <View style={styles.section}>
           <Table weightings={[0.25,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625,0.0625]}>
            <TableRow >
              <TableCell weighting={0.15} style={{borderRight:0,borderBottom:0,marginBottom:2}}>
              <Image src={Drapeau} style={{position:'absolute',width:'90%',top:5,right:5,height:"auto",bottom:1}} />
              </TableCell>
              <TableCell style={{borderLeft:0,borderRight:0,borderBottom:0}} weighting={0.7}>
                  <Text style={styles.texta}>
                    REPUBLIQUE DEMOCRATIQUE DU CONGO {"\n"}
                    MINISTERE DE L'ENSEIGNEMENT PRIMAIRE, SECONDAIRE ET {"\n"} INITIATION A LA NOUVELLE CITOYENNETE
                  </Text>
                
              </TableCell>
              <TableCell style={{borderLeft:0,borderBottom:0}} weighting={0.15}>
                <Image src={Embleme} style={{position:'absolute',width:'80%',top:5,right:5,height:"auto",bottom:1}} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell weighting={1}  style={{flexDirection:"column"}}>
                <Text style={styles.othertext}>
                  N°ID : 
                
                      <Table>
                          <TableRow>
                            <TableCell >.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                            <TableCell>.</TableCell>
                          </TableRow>
                      </Table>
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell weighting={1}>
                <Text style={styles.othertext}>
                  PROVINCE DE {!isEmpty(My_ecole) && My_ecole?.province?.toUpperCase()}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell weighting={0.5}>
                <Text style={styles.othertext}>
               VILLE :{!isEmpty(My_ecole) && My_ecole?.ville?.toUpperCase()}{"\n"}
               COMMUNE :{!isEmpty(My_ecole) && My_ecole?.commune?.toUpperCase()}{"\n"}
               ECOLE :{!isEmpty(My_ecole) && My_ecole?.nom?.toUpperCase()}{"\n"}
               CODE :{!isEmpty(My_ecole) && My_ecole?.code?.toUpperCase()}{"\n"}
               </Text>
              </TableCell>
                <TableCell weighting={0.5}>
                <Text style={styles.othertext}>
                  ELEVE : {!isEmpty(dataeleve) && dataeleve[0]?.nom_complet?.toUpperCase()} {"\n"}SEXE : {!isEmpty(dataeleve) && dataeleve[0]?.sexe?.toUpperCase()} {"\n"}
                  NE (E) A: {!isEmpty(dataeleve) && dataeleve[0]?.lieu_naissance?.toUpperCase()+" LE "+dataeleve[0]?.date_naissance} {"\n"}
                  CLASSE : {!isEmpty(dataclasse) && dataclasse[0]?.nom?.toUpperCase()} {"\n"}
                  N° PERM : {!isEmpty(dataeleve) && dataeleve[0]?.Numero_serni?.toUpperCase()} {"\n"}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell weighting={1}>
                <Text style={styles.texta}>
                  BULLETIN DE LA {!isEmpty(dataclasse) && dataclasse[0]?.maclasse?.toUpperCase()} {"\t"} ANNEE SCOLAIRE {!isEmpty(dataeleve) && dataeleve[0]?.annee_scolaire}
                </Text>
              </TableCell>
            </TableRow>
            <TableRow style={styles.theader} >
              <TableCell >
                BRANCHES
              </TableCell>
              <TableCell >
                1ère P
              </TableCell>
              <TableCell >
                2ème P
              </TableCell>
               <TableCell>
                EXAM
              </TableCell>
               <TableCell>
                TOT.
              </TableCell>
               <TableCell>
                3ème P
              </TableCell>
               <TableCell>
                4ème P
              </TableCell>
              <TableCell>
                EXAM
              </TableCell>
               <TableCell>
                TOT.
              </TableCell>
               <TableCell >
                TOT.GEN
              </TableCell>
              <TableCell >
               
              </TableCell>
              <TableCell >
                
              </TableCell>
              <TableCell >
                
              </TableCell>
            </TableRow>
         {
          !isEmpty(getponderation()) &&
            getponderation().map((ponderation)=>{
              return(
                <>
                <TableRow style={styles.theader}>
                    <TableCell>MAXIMA</TableCell>
                    <TableCell>{ponderation}</TableCell>
                    <TableCell>{ponderation}</TableCell>
                    <TableCell>{ponderation*2}</TableCell>
                    <TableCell>{ponderation*4}</TableCell>
                    <TableCell>{ponderation}</TableCell>
                    <TableCell>{ponderation}</TableCell>
                    <TableCell>{ponderation*2}</TableCell>
                    <TableCell>{ponderation*4}</TableCell>
                    <TableCell>{ponderation*8}</TableCell>
                    <TableCell style={{backgroundColor:"black"}}>
               
                    </TableCell>
                    <TableCell style={{backgroundColor:"black"}}>
                      
                    </TableCell>
                    <TableCell style={{backgroundColor:"black"}}>
                      
                    </TableCell>
                </TableRow>
                  {
                     mes_cours.filter((cr)=>cr.ponderation==ponderation).map((cr)=>
                      <TableRow style={styles.theader}>
                        <TableCell>{cr.nom}</TableCell>
                        {
                                                      cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P1").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P1").map((cote)=>
                                                      <TableCell>{parseInt((parseInt(cote.somme_point)*parseInt(cr.ponderation))/parseInt(cote.ponderation))}</TableCell>
                                                      )
                                                      : <TableCell></TableCell>
                                                  }
                                                 
                                                  {
                                                      cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P2").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P2").map((cote)=>
                                                      <TableCell>{parseInt(parseInt(cote.somme_point)*parseInt(cr.ponderation)/parseInt(cote.ponderation))}</TableCell>
                                                      )
                                                      : <TableCell></TableCell>
                                                  }
                                                  {
                                                      cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen1").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen1").map((cote)=>
                                                      <TableCell>{parseInt(parseInt(cote.somme_point)*parseInt(cr.ponderation*2)/parseInt(cote.ponderation))}</TableCell>
                                                      )
                                                      : <TableCell></TableCell>
                                                  }
                                                    {
                                                   cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P1").length>0 && cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P2").length>0 && cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen1").length>0  ? 
                                                         !isEmpty(total(eleve,cr.id,cr.ponderation)) &&
                                                      <TableCell>
                                                           {total(eleve,cr.id,cr.ponderation)}                   
                                                      </TableCell>
                                                      :
                                                      <TableCell></TableCell>
                                                    }
                                                      

                                                    
                                                    
                                                      {
                                                      cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P3").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P3").map((cote)=>
                                                      <TableCell>{parseInt(cote?.somme_point*cr.ponderation/cote?.ponderation)}</TableCell>
                                                      )
                                                      : <TableCell></TableCell>
                                                  }
                                                                                                      {
                                                      cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P4").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P4").map((cote)=>
                                                      <TableCell>{parseInt(cote?.somme_point*cr.ponderation/cote?.ponderation)}</TableCell>
                                                      )
                                                      : <TableCell></TableCell>
                                                  }
                                                   {
                                                      cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen2").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen2").map((cote)=>
                                                      <TableCell>{parseInt(cote?.somme_point*cr.ponderation*2/cote?.ponderation)}</TableCell>
                                                      )
                                                      : <TableCell></TableCell>
                                                  }
                                                
                                                  {
                                                     cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P3").length>0 && cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P4").length>0 && cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen2").length>0  ? 
                                                           !isEmpty(total2(eleve,cr.id,cr.ponderation)) &&
                                                        <TableCell>
                                                             {total2(eleve,cr.id,cr.ponderation)}                   
                                                        </TableCell>
                                                        :
                                                        <TableCell></TableCell>
                                                      }
                                                        
                                                      {
                                                        !isEmpty(total(eleve,cr.id,cr.ponderation)) && !isEmpty(total2(eleve,cr.id,cr.ponderation)) ?
                                        
                                                            <TableCell>{total(eleve,cr.id,cr.ponderation)+total2(eleve,cr.id,cr.ponderation)}</TableCell>
                                                            :
                                                            <TableCell></TableCell>
                                                      }
                                                  <TableCell style={{backgroundColor:"black"}}>
               
                                                  </TableCell>
                                                  <TableCell >
                                                    
                                                  </TableCell>
                                                  <TableCell >
                                                    
                                                  </TableCell>
                      </TableRow>
                    )
                  }
                </>
              )
            })
         }
         {
                              !isEmpty(classe) && !isEmpty(eleve) &&
                              <>
                              <TableRow style={styles.theader}>
                                  <TableCell>MAXIMA GENERAUX</TableCell>
                                  <TableCell>{maximum()}</TableCell>
                                  <TableCell>{maximum()}</TableCell>
                                  <TableCell>{maximum()*2}</TableCell>
                                  <TableCell>{maximum()*4}</TableCell>
                                  <TableCell>{maximum()}</TableCell>
                                  <TableCell>{maximum()}</TableCell>
                                  <TableCell>{maximum()*2}</TableCell>
                                  <TableCell>{maximum()*4}</TableCell>
                                  <TableCell>{maximum()*8}</TableCell>
                                  <TableCell style={{backgroundColor:"black"}}>
               
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                              </TableRow>
                              <TableRow style={styles.theader}>
                                  <TableCell>TOTAUX</TableCell>
                                  <TableCell>{totaux(eleve,"P1")}</TableCell>
                                  <TableCell>{totaux(eleve,"P2")}</TableCell>
                                  <TableCell>{totaux(eleve,"Examen1")}</TableCell>
                                  <TableCell>{totauxgen(eleve,"tot1")}</TableCell>
                                  <TableCell>{totaux(eleve,"P3")}</TableCell>
                                  <TableCell>{totaux(eleve,"P4")}</TableCell>
                                  <TableCell>{totaux(eleve,"Examen2")}</TableCell>
                                  <TableCell>{totauxgen(eleve,"tot2")}</TableCell>
                                  <TableCell>{totauxgen(eleve,"tot1")+totauxgen(eleve,"tot2")}</TableCell>
                                  <TableCell style={{backgroundColor:"black"}}>
               
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                              </TableRow>
                              <TableRow style={styles.theader}>
                                  <TableCell>POURCENTAGE</TableCell>
                                  <TableCell>{((totaux(eleve,"P1")/maximum())*100).toFixed(2)}%</TableCell>
                                  <TableCell>{((totaux(eleve,"P2")/maximum())*100).toFixed(2)}%</TableCell>
                                  <TableCell>{((totaux(eleve,"Examen1")/(maximum()*2))*100).toFixed(2)}%</TableCell>
                                  <TableCell>{((totauxgen(eleve,"tot1")/(maximum()*4))*100).toFixed(2)}%</TableCell>
                                  <TableCell>{((totaux(eleve,"P3")/maximum())*100).toFixed(2)}%</TableCell>
                                  <TableCell>{((totaux(eleve,"P4")/maximum())*100).toFixed(2)}%</TableCell>
                                  <TableCell>{((totaux(eleve,"Examen2")/(maximum()*2))*100).toFixed(2)}%</TableCell>
                                  <TableCell>{((totauxgen(eleve,"tot2")/(maximum()*4))*100).toFixed(2)}%</TableCell>
                                  <TableCell>{(((totauxgen(eleve,"tot1")+totauxgen(eleve,"tot2"))/(maximum()*8))*100).toFixed(2)}%</TableCell>                                  
                                  <TableCell style={{backgroundColor:"black"}}>
               
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                              </TableRow>
                              <TableRow style={styles.theader}>
                                  <TableCell>PLACE</TableCell>
                                  <TableCell>{(place(parseInt(eleve),"P1"))+1===1 ?(place(parseInt(eleve),"P1"))+1+"er" : (place(parseInt(eleve),"P1"))+1+"ème" }</TableCell>
                                  <TableCell>{(place(parseInt(eleve),"P2"))+1===1 ?(place(parseInt(eleve),"P2"))+1+"er" : (place(parseInt(eleve),"P2"))+1+"ème" }</TableCell>
                                  <TableCell>{(place(parseInt(eleve),"Examen1"))+1===1 ?(place(parseInt(eleve),"Examen1"))+1+"er" : (place(parseInt(eleve),"Examen1"))+1+"ème" }</TableCell>
                                  <TableCell>{(place(parseInt(eleve),"tot1"))+1===1 ?(place(parseInt(eleve),"tot1"))+1+"er" : (place(parseInt(eleve),"tot1"))+1+"ème" }</TableCell>

                                  <TableCell>{(place(parseInt(eleve),"P3"))+1===1 ?(place(parseInt(eleve),"P3"))+1+"er" : (place(parseInt(eleve),"P3"))+1+"ème" }</TableCell>
                                  <TableCell>{(place(parseInt(eleve),"P4"))+1===1 ?(place(parseInt(eleve),"P4"))+1+"er" : (place(parseInt(eleve),"P4"))+1+"ème" }</TableCell>
                                  <TableCell>{(place(parseInt(eleve),"Examen2"))+1===1 ?(place(parseInt(eleve),"Examen2"))+1+"er" : (place(parseInt(eleve),"Examen2"))+1+"ème" }</TableCell>
                                  <TableCell>{(place(parseInt(eleve),"tot2"))+1===1 ?(place(parseInt(eleve),"tot2"))+1+"er" : (place(parseInt(eleve),"tot2"))+1+"ème" }</TableCell>
                                  <TableCell>{(place(parseInt(eleve),"totGen"))+1===1 ?(place(parseInt(eleve),"totGen"))+1+"er" : (place(parseInt(eleve),"totGen"))+1+"ème" }</TableCell>

                                  <TableCell style={{backgroundColor:"black"}}></TableCell>
                                  <TableCell >
               
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>

                              </TableRow>
                              <TableRow style={styles.theader}>
                                  <TableCell>APPLICATION</TableCell>
                                  <TableCell>{((totaux(eleve,"P1")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"P1")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"P1")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</TableCell>
                                  <TableCell>{((totaux(eleve,"P2")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"P2")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"P2")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</TableCell>
                                  <TableCell>{((totaux(eleve,"Examen1")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"Examen1")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"Examen1")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</TableCell>

                                  <TableCell></TableCell>

                                  <TableCell>{((totaux(eleve,"P3")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"P3")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"P3")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</TableCell>
                                  <TableCell>{((totaux(eleve,"P4")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"P4")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"P4")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</TableCell>
                                  <TableCell>{((totaux(eleve,"Examen2")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"Examen2")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"Examen2")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell style={{backgroundColor:"black"}}>
               
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>

                              </TableRow>
                              <TableRow style={styles.theader}>
                                <TableCell>CONDUITE</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell style={{backgroundColor:"black"}}></TableCell>
                                <TableCell style={{backgroundColor:"black"}}></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell style={{backgroundColor:"black"}}></TableCell>
                                <TableCell style={{backgroundColor:"black"}}></TableCell>
                                <TableCell style={{backgroundColor:"black"}}></TableCell>
                                <TableCell >
               
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                              </TableRow>
                              <TableRow style={styles.theader}>
                                <TableCell weighting={0.325} >SIGN. DU RESPONSABLE</TableCell>
                                <TableCell weighting={0.3}></TableCell>
                                <TableCell ></TableCell>
                                <TableCell weighting={0.3}></TableCell>
                                <TableCell >
               
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
                                  <TableCell >
                                    
                                  </TableCell>
            
                              </TableRow>
                        </>
                          }
              
           </Table>
        </View>
        </Page>
  
  )
}
export default Tableau