import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Formulaire from '../../../ModelesPages/Modele2/FormulaireCours_enseigne_classe'
import Swal from 'sweetalert2';
import {useParams,useNavigate} from 'react-router-dom'

function Update(){

    const [Count,setCount]=useState(0);

    const [classes,setClasses]=useState([])
    const [cours,setCours]=useState([])
    const [cour,setCour]=useState("")
    const [ponderation,setPonderation]=useState("")
    const [classe,setClasse]=useState("")
    const {id}=useParams()
    const navigate=useNavigate()
    
    
    
    useEffect(()=>{
        HandleRequest()
        HandleRequestCours()
        HandleRequestCoursEns()
    },[])
    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    const HandleRequest=async()=>{
        await axios.get('api/classeinitiale').then((res)=>{
            setClasses(res.data.items)
        }).catch((err)=>{
            console.log(err)
        })
    }
    const HandleRequestCours=async()=>{
        await axios.get('api/Cours').then((res)=>{
            setCours(res.data.items)
            console.log(res)
        }).catch((err)=>{
            console.log(err)
        })
    }
    function confirmSave(e){
        e.preventDefault()
        if(classe !="" && cours!="" && ponderation!=""){
        Swal.fire({
            title:'Demande de confirmation',
            text:'Voulez-vous vraiment enregistrer ce cours dans cette classe ?',
            icon:'question',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            }
        })  
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord compléter tous les champs',
                icon:'warning'
            })
        }
    }
    const HandleRequestCoursEns=async()=>{
        await axios.get(`api/Coursenseigneclasse/${id}`).then((res)=>{
            setClasse(res.data.items[0].classe)
            setCour(res.data.items[0].cours)
            setPonderation(res.data.items[0].ponderation)
            console.log(res)
        }).catch((err)=>{
            console.log(err)
        })
    }
  
    const HandleSubmit=async (e)=>{
       
                await axios.put(`api/Coursenseigneclasse/${id}`,{cour:cour,classe:classe,ponderation:ponderation}).then((res)=>{
                    setClasse("")
                    setCour("")
                    setPonderation("")
                    if(res.data.status_code===200){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:'Le cours a été dispaché avec succès',
                            icon:'success'
                            
                        })
                        navigate('/coursenseigneclasse')
                    }
                    else{
                        Swal.fire({
                            title:'Message d\'erreur',
                            text:res.data.status_message,
                            icon:'error'
                            
                        })
                    }
                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    return(
    <>
    <Formulaire title="Formulaire d'inscription" onclick={handleclick}  confirmSave={confirmSave} setClasse={setClasse} handlekeyUpgain={handlekeyUpgain}  classe={classe}  handleMouse={handleMouse} Count={Count} setCount={setCount} handleMouseleave={handleMouseleave} HandleSubmit={HandleSubmit} ponderation={ponderation} setPonderation={setPonderation} cour={cour} setCour={setCour} cours={cours}  classes={classes} >
        <div className="w-full md:w-1/1 px-3  md:mb-0">
        
        <div id="resultat" className="block text-red-700"></div>
        <div id="erreur" className="block text-red-500"></div>
    </div>
    </Formulaire>
    </>
    )
}
export default Update