import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import loadingIcon from '../../../../Images/loading.gif'

import Formulaire from '../../../ModelesPages/Modele1/FormulaireCours_ecole'
import Swal from 'sweetalert2';
import { isEmpty } from '../../../../Utilities/Utils';

function Insertion(){

    const [Count,setCount]=useState(0);
    const [loading,setLoading]=useState(false)
    const [rechercher,setRechercher]=useState(false)
    const [classes,setClasses]=useState([])
    const [cours,setCours]=useState([])
    const [enseignants,setEnseignants]=useState([])
    const [idclasseinitiale,setIdclassseinitiale]=useState("")
    const [cour,setCour]=useState("")
    const [ponderation,setPonderation]=useState("")
    const [classe,setClasse]=useState("")
    const [cours_enseign,setCours_enseign]=useState([])
    const [catdispatch,setCatdispatch]=useState("")
    const [my_enseignant,setMy_enseignant]=useState("")
    

    
    
    
    useEffect(()=>{
        HandleRequest()
        HandleRequestCours()
        HandleRequestenseignant()
        
    },[])
    useEffect(()=>{
        cours_classes()
    },[rechercher])
   
    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    function handleclick(){
        alert('You have clicked me')
    }
    const HandleRequest=async()=>{
        await axios.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{
            setClasses(res.data.items)
        }).catch((err)=>{
            console.log(err)
        })
    }
    const HandleRequestenseignant=async()=>{
        await axios.post('api/getenseignants',{idecole:localStorage.getItem("idecole")}).then((res)=>{
            setEnseignants(res.data.items)
        }).catch((err)=>{
            console.log(err)
        })
    }
    const HandleRequestCours=async()=>{
        await axios.get('/api/Cours').then((res)=>{
            setCours(res.data.items)
        }).catch((err)=>{
            console.log(err)
        })
    }
    function confirmSave(e){
        if(catdispatch=="classe" && !isEmpty(classe)){
            const filtre=onclickme().filter((data)=>data.renseigner==true)
                if(filtre.length>0){
                    Swal.fire({
                        title:'Demande de confirmation',
                        text:'Voulez-vous vraiment enregistrer ce cours dans cette classe ?',
                        icon:'question',
                        showCancelButton:true,
                        showConfirmButton:true,
                        confirmButtonText:'Confirmer',
                    }).then((resultat)=>{
                        if(resultat.isConfirmed){
                            HandleSubmit()
                        }
                    })  
                }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Vous devez dispatché au moins un cours de la liste à un enseignant ',
                icon:'warning'
            })
        }
    }
    else if(catdispatch=="cours" && !isEmpty(cour)){

    }
    }

    
    const HandleSubmit=async (e)=>{
       
                await axios.post('api/dispatchcour',{idclasse:classe,idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire"),data:onclickme()}).then((res)=>{
                    setCour("")
                    setPonderation("")
                    if(res.data.status===200){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:res.data.message,
                            icon:'success'
                            
                        })
                        setRechercher(!rechercher)
                    }
                    else{
                        Swal.fire({
                            title:'Message d\'erreur',
                            text:res.data.status_message,
                            icon:'error'
                            
                        })
                    }
                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    const cours_classes=async()=>{
        setLoading(true)
        await axios.post("api/getcourstodispatch",{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
            setLoading(false)
            setCours_enseign(res.data.items)
        }).catch((err)=>{

        })
    }
    const recordsclasse=!isEmpty(classe) && classes.filter((clas)=>clas.id==classe)   
    const records=!isEmpty(classe) && cours_enseign.filter((cour)=>!isEmpty(cour.idclasse) ? cour.idclasse==classe && cour.idenseignant==0 : cour.idclasse_initiale==idclasseinitiale )
    const recordsexist=!isEmpty(classe) && cours_enseign.filter((cour)=>cour.idclasse==classe && cour.idenseignant!=0)
    useEffect(()=>{
        !isEmpty(classe) && setIdclassseinitiale(recordsclasse[0]["idclasse_initiale"])
        !isEmpty(classe) && console.log(idclasseinitiale)
    },[recordsclasse])
    function onclickme(){
   let my_object=[]
    const allselect=document.getElementById("dispatchnow").getElementsByTagName("select").length
    for(let i=0;i<allselect;i++){
        let etat=!isEmpty(document.getElementById("dispatchnow").getElementsByTagName("select")[i].value) ? true : false
        my_object=[
            ...my_object,
            {
                renseigner:etat,
                enseignant:document.getElementById("dispatchnow").getElementsByTagName("select")[i].value,
                cours:document.getElementById("dispatchnow").getElementsByTagName("span")[i].id,

            }
        ]
    }
    
    return my_object
  }

  
    const Affichage=()=>{
        if(!isEmpty(classe) && catdispatch==="classe"){
        return (loading) ? (
            <div className='w-100 mx-auto'>
                <img src={loadingIcon} alt='loading'/>
            </div>
        ) : (
            records.length > 0 &&
            records.map((cour,indexV)=>
            
            //const [indexV,setIndexV]=useState("")
               /* records.filter((enseignant)=>{
                    return search === '' ? enseignant : enseignant.nom_complet.includes(search)
                }).map((enseignant,indexV)=>*/
                {
                  return(
                    
                    <div className="row">
                        <div className="col-md-6 mb-2">
                          <span id={cour.id}> {cour.cours}</span>
                        </div>
                        <div className="col-md-6 mb-2">
                        <select className="form-select form-select-lg" id="grid-state" name="niveau" >
                        <option value="" className="fw-bold" selected disabled >Choix de l'enseignant </option>
                            {
                                enseignants.map((ense)=>
                                <option value={ense.my_enseignant} key={ense.id} className="fw-bold">{ense.nom_complet}</option>
                            )
                            }
                        
                        
                        </select>
                        </div>
                    </div>
                  
                  )
                    
                }
                )
            
        )
    }      
    }
    const Iteration=()=>{
        return (loading) ? (
            <div className='w-100 mx-auto'>
                <img src={loadingIcon} alt='loading'/>
            </div>
        ) : (
            recordsexist.length > 0 &&
            recordsexist.map((cour,indexV)=>
               /* records.filter((enseignant)=>{
                    return search === '' ? enseignant : enseignant.nom_complet.includes(search)
                }).map((enseignant,indexV)=>*/
                {
                  return(
                    <div className="row">
                        <div className="col-6 fw-bold fs-6 text-capitalize">
                            {
                                indexV+1+'. '+cour.cours+' '
                            }
                        </div>
                        <div className="col-6 fw-bold fs-6 text-capitalize">
                            {cour.enseignant}
                        </div>
                    </div>     
                  )
                    
                }
                )
          
        )
        
            }  

    return(
    <>
    <Formulaire title="Formulaire de gestion des cours" recordsexist={recordsexist} Affichage={Affichage} my_enseignant={my_enseignant} setMy_enseignant={setMy_enseignant} enseignants={enseignants} setEnseignants={setEnseignants} titlecours="Liste des cours déjà dispatchés" setCatdispatch={setCatdispatch} catdispatch={catdispatch} onclick={handleclick}   confirmSave={confirmSave} setClasse={setClasse} handlekeyUpgain={handlekeyUpgain}  classe={classe}  handleMouse={handleMouse} Count={Count} setCount={setCount} handleMouseleave={handleMouseleave} HandleSubmit={HandleSubmit} ponderation={ponderation} setPonderation={setPonderation} cour={cour} setCour={setCour} cours={cours}  classes={classes} >
       <div className="">
            {Iteration()}
       </div>
    </Formulaire>
    </>
    )
}
export default Insertion