import axios from "../../../../../api/axios"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { getCookie, isEmpty } from "../../../../../Utilities/Utils"
import { getsession } from "../../../../../actions/user.action"
import { useNavigate } from "react-router-dom"
function My_bulletin(){
const userselector=useSelector((state)=>state.userReducer)
const [eleves,setEleves]=useState([])
const [classes,setClasses]=useState([])
const [cotes,setCotes]=useState([])
const [bulletin,setBulletin]=useState([]) 
const [My_ecole,setMy_ecole]=useState("")
const [periode,setPeriode]=useState("")
const [eleve,setEleve]=useState("")
const [date_evaluation,setDate_evaluation]=useState()
const [loading,setLoading]=useState(false)
const [classe,setClasse]=useState("")
const [cours,setCours]=useState([])
const [mes_cotes,setMes_cotes]=useState([])
const dispatch=useDispatch()
const [p1,setP1]=useState([])
const [totalite,setTotalite]=useState([])
let cot=0
const [p2,setP2]=useState([])
let navigate=useNavigate()
//const record_classe=classes.filter((clas)=>clas.id==classe)
const recordseleves=eleves.filter((eleve)=>eleve.idclasse==classe)
const dataeleve=recordseleves.filter((elev)=>elev.id==eleve)
const dataclasse=classes.filter((clas)=>clas.id==classe)
const classe_initiale=!isEmpty(dataclasse) && dataclasse[0].idclasse_initiale 
const mes_cours=cours.filter((cour)=>cour.idclasse_initiale==classe_initiale)


useEffect(()=>{
    HandleRequest()
    elevesrequest()
    getbulletin()
    getEcole()

},[])

const HandleRequest=async()=>{
    await axios.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{
        setClasses(res.data.items)
    }).catch((err)=>{
        
    })
}
const getEcole=async()=>{
    await axios.get(`api/getEcole/${localStorage.getItem("idecole")}`).then((res)=>{
        setMy_ecole(res.data.items)
    }).catch((err)=>{

    })
}
const elevesrequest=async()=>{

    await axios.post("api/allinscription",{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setEleves(res?.data?.items)
  
    }).catch((err)=>{

    })
}
const getbulletin=async()=>{
    await axios.post("api/getbulletin",{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
        setCours(res?.data?.cours)
        setCotes(res?.data?.tableau)
        setTotalite(res?.data?.total)
    }).catch((err)=>{

    })
}
function getponderation(){
    let table_ponderation=[]
    if(mes_cours.length>0){
     
        for(let i=0;i<mes_cours.length;i++){
            table_ponderation=[...table_ponderation,mes_cours[i].ponderation]
           
        }
        const unique_ponderation=[...new Set(table_ponderation)]
        return unique_ponderation
    }
    
}
function total(idinscription,idcour,ponderation){
  let cot1= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="P1");
  let cot2= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="P2");
  let cot3= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="Examen1");

  let resultat=cot1[0]?.somme_point*ponderation/cot1[0]?.ponderation+cot2[0]?.somme_point*ponderation/cot2[0]?.ponderation+cot3[0]?.somme_point*ponderation*2/cot3[0]?.ponderation
return(parseInt(resultat))
}
function total2(idinscription,idcour,ponderation){
    let cot1= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="P3");
    let cot2= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="P4");
    let cot3= cotes.filter((cote)=>cote.idinscription==idinscription && cote.my_course==idcour && cote.periode=="Examen2");
  
    let resultat=cot1[0]?.somme_point*ponderation/cot1[0]?.ponderation+cot2[0]?.somme_point*ponderation/cot2[0]?.ponderation+cot3[0]?.somme_point*ponderation*2/cot3[0]?.ponderation
  return(parseInt(resultat))
  }

function getpoint_eleve(){
    let my_table=[]
    if(!isEmpty(eleve)){
        mes_cours.map((cour)=>{
           const my_cote_now=cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cour.id)
           const p1=my_cote_now.filter((cote)=>cote.periode=="P1")
           const p2=my_cote_now.filter((cote)=>cote.periode=="P2")
           const p3=my_cote_now.filter((cote)=>cote.periode=="P3")
           const p4=my_cote_now.filter((cote)=>cote.periode=="P4")
           const Exam1=my_cote_now.filter((cote)=>cote.periode=="Examen1")
           const Exam2=my_cote_now.filter((cote)=>cote.periode=="Examen2")
           my_table=[
                ...my_table,
                {
                    mon_cour:cour.nom,
                    idcour:cour.id,
                    p1:parseInt(p1[0]?.somme_point),
                    p2:parseInt(p2[0]?.somme_point),
                    tot1:parseInt(p1[0]?.somme_point)+parseInt(p2[0]?.somme_point)+parseInt(Exam1[0]?.somme_point),
                    p3:parseInt(p3[0]?.somme_point),
                    p4:parseInt(p4[0]?.somme_point),
                    Examen1:parseInt(Exam1[0]?.somme_point),
                    Examen2:parseInt(Exam2[0]?.somme_point),


                },
            ]
            
            
        }
          
        )
    }
   return my_table
}
function maximum(){
    let sum=0
   const nombre_cour=mes_cours.length
   if(nombre_cour>0){
    for(let i=0;i<nombre_cour;i++){
        sum=parseInt(sum)+parseInt(mes_cours[i]?.ponderation)
       
    }
   }

   return(sum)
}
function totaux(eleve,periode){
    let tot=0
    
    if(periode=="Examen1" || periode=="Examen2"){
    for(let i=0;i<mes_cours.length;i++){
        let cour_pond=mes_cours.filter((cour)=>cour.id==mes_cours[i]?.id)
        const mes_points=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode==periode && cote.my_course==mes_cours[i].id)
            tot=parseInt(tot)+parseInt((parseInt(mes_points[0]?.somme_point)*cour_pond[0]?.ponderation*2)/parseInt(mes_points[0]?.ponderation))
        }
        }
        else{
            for(let i=0;i<mes_cours.length;i++){
                let cour_pond=mes_cours.filter((cour)=>cour.id==mes_cours[i]?.id)
                const mes_points=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode==periode && cote.my_course==mes_cours[i].id)

            tot=parseInt(tot)+parseInt((parseInt(mes_points[0]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points[0]?.ponderation))
            }
        }
        
   
return (tot)
}
const totalitete=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P1" && cote.my_course==11)
function totauxgen(eleve,periode){
    let tot1=0
    let tot2=0
    let tot3=0
    if(periode=="tot1"){
        const mes_points1=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P1")
        const mes_points2=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P2")
        const mes_points3=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="Examen1")
        for(let i=0;i<mes_points1.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points1[i]?.my_course)
            tot1=parseInt(tot1)+parseInt((parseInt(mes_points1[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points1[i]?.ponderation))
        }
        for(let i=0;i<mes_points2.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points2[i]?.my_course)
            tot2=parseInt(tot2)+parseInt((parseInt(mes_points2[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points2[i]?.ponderation))
        }
        for(let i=0;i<mes_points3.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points3[i]?.my_course)
            tot3=parseInt(tot3)+parseInt((parseInt(mes_points3[i]?.somme_point)*cour_pond[0]?.ponderation*2)/parseInt(mes_points3[i]?.ponderation))
        }
    }
    else{
        const mes_points1=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P3")
        const mes_points2=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="P4")
        const mes_points3=cotes.filter((cote)=>cote.idinscription==eleve && cote.periode=="Examen2")
        for(let i=0;i<mes_points1.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points1[i]?.my_course)
            tot1=parseInt(tot1)+parseInt((parseInt(mes_points1[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points1[i]?.ponderation))
        }
        for(let i=0;i<mes_points2.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points2[i]?.my_course)
            tot2=parseInt(tot2)+parseInt((parseInt(mes_points2[i]?.somme_point)*cour_pond[0]?.ponderation)/parseInt(mes_points2[i]?.ponderation))
        }
        for(let i=0;i<mes_points3.length;i++){
            let cour_pond=mes_cours.filter((cour)=>cour.id==mes_points3[i]?.my_course)
            tot3=parseInt(tot3)+parseInt((parseInt(mes_points3[i]?.somme_point)*cour_pond[0]?.ponderation*2)/parseInt(mes_points3[i]?.ponderation))
        }
    }
    
return(tot1+tot2+tot3)
}
function place(eleve,periode){
    tableaupourcent(periode)
    //const total_point=totalite.filter((tot)=>tot.periode==periode)
    
    let my_place=""
 
    if(tableaupercent.length>0){
        my_place=tableaupercent.findIndex(element=>element.idinscription==eleve)
    }
    return (my_place)
}

const HandleClick=async()=>{
    await axios.post("api/personnalbulletin").then((res)=>{

    }).catch((err)=>{

    })
}
let tableaupercent = [];
const tableaupourcent = (periode) => {
    tableaupercent=[]
    let pourcent = 0;
    let totalpart=0;
    let totalpart2=0;
    const recordtotalite=totalite.filter((tota)=>tota.periode==periode && tota.idclasse==classe)
    const recordtotalitesem=totalite.filter((tota)=>tota.idclasse==classe)
    if(periode==="Examen1" || periode==="Examen2"){
        recordtotalite.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                pourcent = ((totaux(elv.id, periode) / maximum()*2) * 100).toFixed(2);
                tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
            });
        });
    }
    else if(periode==="tot1"){
        recordtotalitesem.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                totalpart=totauxgen(elv.id,"tot1")
                //totalpart=totaux(elv.id,"P1")+totaux(elv.id,"P2")+totaux(elv.id,"Examen1")
                pourcent = ((parseInt(totalpart) / (maximum()*4)) * 100).toFixed(2);
                if (!tableaupercent.some(e => e.idinscription === elv.id && e.pourcent === pourcent)) {
                    tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
                  }
            });
        });
    }
    else if(periode==="tot2"){
        recordtotalitesem.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                totalpart=totauxgen(elv.id,"tot2")
                //totalpart=totaux(elv.id,"P1")+totaux(elv.id,"P2")+totaux(elv.id,"Examen1")
                pourcent = ((parseInt(totalpart) / (maximum()*4)) * 100).toFixed(2);
                if (!tableaupercent.some(e => e.idinscription === elv.id && e.pourcent === pourcent)) {
                    tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
                  }
            });
        });
    }
    else if(periode==="totGen"){
        recordtotalitesem.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                totalpart=totauxgen(elv.id,"tot1")
                totalpart2=totauxgen(elv.id,"tot2")
                //totalpart=totaux(elv.id,"P1")+totaux(elv.id,"P2")+totaux(elv.id,"Examen1")
                pourcent = (((parseInt(totalpart)+parseInt(totalpart2)) / (maximum()*8)) * 100).toFixed(2);
                if (!tableaupercent.some(e => e.idinscription === elv.id && e.pourcent === pourcent)) {
                    tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
                  }
            });
        });
    }
    else{
        recordtotalite.map((record) => {
            recordseleves.filter((rec) => rec.id == record.idinscription).map((elv) => {
                pourcent = ((totaux(elv.id, periode) / maximum()) * 100).toFixed(2);
                tableaupercent.push({ idinscription: elv.id, pourcent: pourcent });
            });
        });
    }
    
    tableaupercent.sort((a, b) => b.pourcent - a.pourcent);
};


return(
        <>
       
            <div className="container">
                
                <div className="col-md-12 ">
                    <div className="row justify-content-start border py-4 px-2 mb-4">
                        <div className="col-md-4 mb-2">
                            <div className="form-outline">
                                <label htmlFor="" className="form-label">
                                    Classe
                                </label>
                                <select name="classse" id="classe" onChange={(e)=>setClasse(e.target.value)} value={classe} className="form-select form-select-lg">
                                    <option value="tous" selected>Choix de la classe</option>
                                    {
                                        classes.length>0 &&
                                            classes.map((clas)=>
                                                <option value={clas.id}>{clas.nom}</option>

                                            )
                                        
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 mb-2">
                            <div className="form-outline">
                                <label htmlFor="" className="form-label">
                                    Eleve
                                </label>
                                <select name="eleve" id="eleve" className="form-select form-select-lg" onChange={(e)=>setEleve(e.target.value)} value={eleve}>
                                    <option value="" selected>Choix de l'élève</option>
                                    <option value="Tous" selected>Tous les élèves</option>
                                    {
                                        recordseleves.length>0 &&
                                            recordseleves.map((elev)=>   
                                                <option value={elev.id}>{elev.nom_complet}</option>
                                            )
                                    }
                                </select>
                            </div>
                        </div>
                        
                        <div className="col-md-2 mb-2">
                            <div className="">
                            <Link to={!isEmpty(eleve) && eleve!=="Tous" ? `/mybulletin/${false}/${classe}/${eleve}`: !isEmpty(eleve) && eleve==="Tous" ? `/mybulletin/${true}/${classe}/tous`:"/mybulletin"}  >
                                    <button className="btn btn-primary">Télécharger...</button>
                            </Link>
                            </div>
                        </div>
                        
                    </div>
                
                    <div className="row justify-content-center border  border-dark">
                            <div className="col-2">

                            </div>
                            <div className="col-8 text-center">
                                    REPUBLIQUE DEMOCRATIQUE DU CONGO <br />
                                    MINISTERE DE L'ENSEIGNEMENT PRIMAIRE, SECONDAIRE <br />
                                     ET INITIATION A LA NOUVELLE CITOYENNETE
                            </div>
                            <div className="col-2">

                            </div>
                      
                    </div>
                    <div className="row justify-content-start">
                        <span>N° ID </span>
                        <tr className="border border-dark">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </div>
                    <div className="row">
                        PROVINCE :......................
                    </div>
                    <div className="row justify-content-start border border-dark">
                        <div className="col-6 border-right-dark">
                      
                                    <h5>
                                        VILLE : {!isEmpty(My_ecole) && My_ecole?.ville?.toUpperCase()}
                                    </h5>
                                    <h5>
                                        COMMUNE : {!isEmpty(My_ecole) && My_ecole?.commune?.toUpperCase()}
                                    </h5>
                                    <h5>
                                        ECOLE : {!isEmpty(My_ecole) && My_ecole?.nom?.toUpperCase()}
                                    </h5>
                                    <h5>
                                        CODE : {!isEmpty(My_ecole) && My_ecole?.code?.toUpperCase()}
                                    </h5>
                            
                        </div>
                        <div className="col-6">
                                <h5>
                                     ELEVE : {!isEmpty(dataeleve) && dataeleve[0]?.nom_complet.toUpperCase()}

                                </h5>
                                <h5>
                                     NE(E)  A : {!isEmpty(dataeleve) && isEmpty(dataeleve[0].lieu_naissance) ? dataeleve[0]?.lieu_naissance +'\t LE '+ dataeleve[0]?.date_naissance : '\t LE '+ dataeleve[0]?.date_naissance }

                                </h5>
                                <h5>
                                     CLASSE : {!isEmpty(dataclasse) && dataclasse[0]?.nom}

                                </h5>
                                <h5>
                                    N° PERM : {!isEmpty(dataeleve) && dataeleve[0]?.Numero_serni}

                                </h5>
                        </div>
                    </div>
                    <div className="row table-responsive">
                        <table className="table table-bordered table-condensed table-responsive">
                            <thead>
                                <tr>
                                    <th className="sticky-col">BRANCHES</th>
                                    <th>1ère P</th>
                                    <th>2ème P</th>
                                    <th>EXAM</th>
                                    <th>TOT.</th>                                    
                                    <th>3ème P</th>
                                    <th>4ème P</th>
                                    <th>EXAM</th>
                                    <th>TOT.</th>
                                    <th>T.G</th>
                                </tr>
                            </thead>
                            <tbody>
                                 {
                                    !isEmpty(getponderation()) &&
                                        getponderation().map((pond)=> {
                                            return(
                                                <>
                                                    <tr className="fw-bold">
                                                        <td className="sticky-col">MAXIMA</td>
                                                        <td>{pond}</td>
                                                        <td>{pond}</td>
                                                        <td>{pond*2}</td>
                                                        <td>{pond*4}</td>
                                                        <td>{pond}</td>
                                                        <td>{pond}</td>
                                                        <td>{pond*2}</td>
                                                        <td>{pond*4}</td>
                                                        <td>{pond*8}</td>
                                                    </tr> 
                                            {
                                                mes_cours.filter((cr)=>cr.ponderation==pond).map((cr)=>
                                                    <tr>
                                           
                                               
                                                <td className="sticky-col">{cr.nom}</td>

                                              
                                                    {
                                                        cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P1").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P1").map((cote)=>
                                                        <td>{parseInt((parseInt(cote.somme_point)*parseInt(cr.ponderation))/parseInt(cote.ponderation))}</td>
                                                        )
                                                        : <td></td>
                                                    }
                                                   
                                                    {
                                                        cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P2").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P2").map((cote)=>
                                                        <td>{parseInt(parseInt(cote.somme_point)*parseInt(cr.ponderation)/parseInt(cote.ponderation))}</td>
                                                        )
                                                        : <td></td>
                                                    }
                                                    {
                                                        cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen1").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen1").map((cote)=>
                                                        <td>{parseInt(parseInt(cote.somme_point)*parseInt(cr.ponderation*2)/parseInt(cote.ponderation))}</td>
                                                        )
                                                        : <td></td>
                                                    }
                                                      {
                                                     cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P1").length>0 && cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P2").length>0 && cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen1").length>0  ? 
                                                           !isEmpty(total(eleve,cr.id,cr.ponderation)) &&
                                                        <td>
                                                             {total(eleve,cr.id,cr.ponderation)}                   
                                                        </td>
                                                        :
                                                        <td></td>
                                                      }
                                                        

                                                      
                                                      
                                                        {
                                                        cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P3").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P3").map((cote)=>
                                                        <td>{parseInt(cote?.somme_point*cr.ponderation/cote?.ponderation)}</td>
                                                        )
                                                        : <td></td>
                                                    }
                                                     {
                                                        cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P4").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P4").map((cote)=>
                                                        <td>{parseInt(cote?.somme_point*cr.ponderation/cote?.ponderation)}</td>
                                                        )
                                                        : <td></td>
                                                    }
                                                     {
                                                        cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen2").length>0 ? cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen2").map((cote)=>
                                                        <td>{parseInt(cote?.somme_point*cr.ponderation*2/cote?.ponderation)}</td>
                                                        )
                                                        : <td>{}</td>
                                                    }
                                                    {
                                                     cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P3").length>0 && cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="P4").length>0 && cotes.filter((cote)=>cote.idinscription==eleve && cote.my_course==cr.id && cote.periode=="Examen2").length>0  ? 
                                                           !isEmpty(total2(eleve,cr.id,cr.ponderation)) &&
                                                        <td>
                                                             {total2(eleve,cr.id,cr.ponderation)}                   
                                                        </td>
                                                        :
                                                        <td></td>
                                                      }
                                                        
                                                      {
                                                        !isEmpty(total(eleve,cr.id,cr.ponderation)) && !isEmpty(total2(eleve,cr.id,cr.ponderation)) ?
                                                            <td>{total(eleve,cr.id,cr.ponderation)+total2(eleve,cr.id,cr.ponderation)}</td>
                                                            :
                                                            <td></td>
                                                      }
                                                    </tr> 
                                                    
                                                   
                                                )
                                                
                                            }
                                           

                                                </>
                                                
                                            )
                                            
                                        }
                                            
                                        )
                                        
                                 }
                                 
                            </tbody>
                            {
                                !isEmpty(classe) && !isEmpty(eleve) &&
                            <tfoot>
                                <tr>
                                    <td className="sticky-col">MAXIMA GENERAUX</td>
                                    <td>{maximum()}</td>
                                    <td>{maximum()}</td>
                                    <td>{maximum()*2}</td>
                                    <td>{maximum()*4}</td>
                                    <td>{maximum()}</td>
                                    <td>{maximum()}</td>
                                    <td>{maximum()*2}</td>
                                    <td>{maximum()*4}</td>
                                    <td>{maximum()*8}</td>
                                </tr>
                                <tr>
                                    <td className="sticky-col">TOTAUX</td>
                                    <td>{totaux(eleve,"P1")}</td>
                                    <td>{totaux(eleve,"P2")}</td>
                                    <td>{totaux(eleve,"Examen1")}</td>
                                    <td>{totauxgen(eleve,"tot1")}</td>
                                    <td>{totaux(eleve,"P3")}</td>
                                    <td>{totaux(eleve,"P4")}</td>
                                    <td>{totaux(eleve,"Examen2")}</td>
                                    <td>{totauxgen(eleve,"tot2")}</td>
                                    <td>{totauxgen(eleve,"tot1")+totauxgen(eleve,"tot2")}</td>
                                </tr>
                                <tr>
                                    <td className="sticky-col">POURCENTAGE</td>
                                    <td>{((totaux(eleve,"P1")/maximum())*100).toFixed(2)}%</td>
                                    <td>{((totaux(eleve,"P2")/maximum())*100).toFixed(2)}%</td>
                                    <td>{((totaux(eleve,"Examen1")/(maximum()*2))*100).toFixed(2)}%</td>
                                    <td>{((totauxgen(eleve,"tot1")/(maximum()*4))*100).toFixed(2)}%</td>
                                    <td>{((totaux(eleve,"P3")/maximum())*100).toFixed(2)}%</td>
                                    <td>{((totaux(eleve,"P4")/maximum())*100).toFixed(2)}%</td>
                                    <td>{((totaux(eleve,"Examen2")/(maximum()*2))*100).toFixed(2)}%</td>
                                    <td>{((totauxgen(eleve,"tot2")/(maximum()*4))*100).toFixed(2)}%</td>
                                    <td>{(((totauxgen(eleve,"tot1")+totauxgen(eleve,"tot2"))/(maximum()*8))*100).toFixed(2)}%</td>
                                </tr>
                                <tr>
                                    <td className="sticky-col">PLACE</td>
                                  
                                    <td>{(place(parseInt(eleve),"P1"))+1===1 ?(place(parseInt(eleve),"P1"))+1+"er" : (place(parseInt(eleve),"P1"))+1+"ème" }</td>
                                    
                                    <td>{(place(parseInt(eleve),"P2"))+1===1 ?(place(parseInt(eleve),"P2"))+1+"er" : (place(parseInt(eleve),"P2"))+1+"ème" }</td>
                                 
                                    <td>{(place(parseInt(eleve),"Examen1"))+1===1 ?(place(parseInt(eleve),"Examen1"))+1+"er" : (place(parseInt(eleve),"Examen1"))+1+"ème" }</td>
                                    
                                    <td>{(place(parseInt(eleve),"tot1"))+1===1 ?(place(parseInt(eleve),"tot1"))+1+"er" : (place(parseInt(eleve),"tot1"))+1+"ème" }</td>
                                  

                                    <td>{(place(parseInt(eleve),"P3"))+1===1 ?(place(parseInt(eleve),"P3"))+1+"er" : (place(parseInt(eleve),"P3"))+1+"ème" }</td>
                                    <td>{(place(parseInt(eleve),"P4"))+1===1 ?(place(parseInt(eleve),"P4"))+1+"er" : (place(parseInt(eleve),"P4"))+1+"ème" }</td>
                                    <td>{(place(parseInt(eleve),"Examen2"))+1===1 ?(place(parseInt(eleve),"Examen2"))+1+"er" : (place(parseInt(eleve),"Examen2"))+1+"ème" }</td>
                                    <td>{(place(parseInt(eleve),"tot2"))+1===1 ?(place(parseInt(eleve),"tot2"))+1+"er" : (place(parseInt(eleve),"tot2"))+1+"ème" }</td>
                                    <td>{(place(parseInt(eleve),"totGen"))+1===1 ?(place(parseInt(eleve),"totGen"))+1+"er" : (place(parseInt(eleve),"totGen"))+1+"ème" }</td>

                                </tr>
                                <tr>
                                    <td  className="sticky-col">APPLICATION</td>
                                    <td>{((totaux(eleve,"P1")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"P1")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"P1")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</td>
                                    <td>{((totaux(eleve,"P2")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"P2")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"P2")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</td>
                                    <td>{((totaux(eleve,"Examen1")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"Examen1")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"Examen1")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</td>

                                    <td></td>

                                    <td>{((totaux(eleve,"P3")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"P3")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"P3")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</td>
                                    <td>{((totaux(eleve,"P4")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"P4")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"P4")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</td>
                                    <td>{((totaux(eleve,"Examen2")/maximum())*100).toFixed(2)<50 ? "AB" : ((totaux(eleve,"Examen2")/maximum())*100).toFixed(2)<70 ? "B" : ((totaux(eleve,"Examen2")/maximum())*100).toFixed(2)<80 ? "TB" : "E"}</td>
                                    <td></td>
                                    <td></td>

                                </tr>
                            </tfoot>
                            }
                        </table>
                    </div>
                </div>
            </div>
        
        </>
    )

}
export default My_bulletin
