


//function Formulaire({children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,count,setCount,nom,postnom,prenom,sexe,telephone,Image,setNom,setPostnom,setPrenom,setSexe,setImage,setTelephone,setNomcomplet}){
    function Formulaire({classes,my_classe,setMy_classe,error_submission,submission,HandleSubmit,children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,nom,postnom,prenom,sexe,date_naissance,lieu_naissance,setNom,setPostnom,setPrenom,setSexe,setDate_naissance,setLieu_naissance,setNom_complet,numero_serni,setNumero_serni,adresse,setAdresse}){
  
    return(
        <>
        <div className="container justify-content-end">

        
            <form className="mt-2" >
                <legend>
                    <h3 className="font-bold text-blue-500 mb-4 text-xl">
                        {title}
                    </h3>
                </legend>
                {//Début test
                }
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Nom</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={nom} onChange={(e)=>setNom(e.target.value.toLowerCase())} onBlur={(e)=>setNom_complet(e.target.value.toLowerCase() +" "+postnom+" "+prenom)} onKeyUp={handlekeyUpgain} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Postnom</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={postnom} onChange={(e)=>setPostnom(e.target.value.toLowerCase())} onBlur={(e)=>setNom_complet(nom +" "+e.target.value.toLowerCase()+" "+prenom)} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Prenom</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={prenom} onChange={(e)=>setPrenom(e.target.value.toLowerCase())} onBlur={(e)=>setNom_complet(nom +" "+postnom+" "+e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">CLASSE</label>
                            <select name="section" id="section" className="form-control form-select-lg" value={my_classe} onChange={(e)=>setMy_classe(e.target.value)} >
                                <option value="" selected disabled>Choix de la classe</option>
                                {
                                    classes.map((cla)=>(
                                        <option  value={cla.id} key={cla.id}  >{cla.nom}</option>
                                        )
                                    )
                                }
                                </select>                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Adresse</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={adresse} onChange={(e)=>setAdresse(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Date de naissance</label>
                            <input type="date" id="firstName" class="form-control form-control-lg" value={date_naissance} onChange={(e)=>setDate_naissance(e.target.value)} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Lieu de naissance</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={lieu_naissance} onChange={(e)=>setLieu_naissance(e.target.value.toLowerCase())} />                          
                        </div>
                    </div>
              </div>
              <div class="row">
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Genre</label>
                            <select name="genre" id="genre" className="form-control form-select-lg" value={sexe} onChange={(e)=>setSexe(e.target.value)}>
                                <option value="" selected disabled>Choix du genre</option>
                                <option value="M">Homme</option>
                                <option value="F">Femme</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 mb-2">
                        <div class="form-outline">
                            <label class="form-label" for="firstName">Numéro Serni</label>
                            <input type="text" id="firstName" class="form-control form-control-lg" value={numero_serni} onChange={(e)=>setNumero_serni(e.target.value)} />                          
                        </div>
                    </div>
              </div>
              <div className="row">
                {children}
              </div>
              {
                            error_submission && 
                      
              <div className="row justify-content-start">
              
                <div className="col-md-6 mb-2">
                    <span className="text-danger">
                        Veuillez compléter tous les champs obligatoires
                    </span>
                </div>
              </div>
              }
               {
                            submission && 
                      
              <div className="row justify-content-start">
              
                <div className="col-md-6 mb-2">
                    <span className="text-primary">
                        L'élève a été enregistré avec succès
                    </span>
                </div>
              </div>
              }
              <div class="row">
                    <div class="col-md-6 mb-2">
                        <div class="form-outline">
                            <button type="button" className="btn btn-primary" onClick={HandleSubmit}>Enregistrer</button>
                        </div>
                    </div>
              </div>

                
                
            </form>
        </div>
        </>
    )
}
export default Formulaire;