import { Document,Page,View,Text,StyleSheet,PDFViewer } from "@react-pdf/renderer";

const TableauListe=({tableaupercent,myclasse,periode,recordtotalite,recordseleves,totaux,maximum})=>{
    
    const styles = StyleSheet.create({
        page: {
          flexDirection: 'column',
          backgroundColor: '#fff',
          padding: 10
        },
        table: {
          display: "table",
          width: "auto",
          borderStyle: "solid",
          borderWidth: 1,
          borderRightWidth: 0,
          borderBottomWidth: 0
        },
        tableRow: {
          margin: "auto",
          flexDirection: "row"
        },
        tableColHeader: {
           
          width: "30%",
          borderStyle: "solid",
          borderWidth: 1,
          borderLeftWidth: 0,
          borderTopWidth: 0,
          backgroundColor: '#f3f3f3'
        },
        tablColEntete:{
            margin:"auto",
            width:"100%",
            borderStyle:"solid",
            borderWidth:1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            fontWeight:"bold",
        },
        tableColHeaderEleve: {
           
            width: "60%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#f3f3f3'
          },
          tableColHeaderNum: {
           
            width: "10%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#f3f3f3'
          },
        tablColousEntete:{
            margin:"auto",
            width:"50%",
            borderStyle:"solid",
            borderWidth:1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableCol: {
          width: "30%",
          borderStyle: "solid",
          borderWidth: 1,
          borderLeftWidth: 0,
          borderTopWidth: 0
        },
        tableCellHeaderEntete: {
            margin: "auto",
            margin: 5,
            fontSize: 12,
            fontWeight: 500,
            textAlign:"center"
          },
        tableCellHeader: {
          margin: "auto",
          margin: 5,
          fontSize: 12,
          fontWeight: 500
        },
        tableCell: {
          margin: "auto",
          margin: 5,
          fontSize: 10
        },
        tableColEleve: {
            width: "60%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
          },
          tableColNum: {
            width: "10%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
          },
      });
    return(
        
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tablColEntete}>
                                <Text style={styles.tableCellHeaderEntete}>Liste de la proclamation - </Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tablColousEntete}>
                                <Text style={styles.tableCellHeader}>Classe : {myclasse?.[0]?.nom} </Text>
                                <Text style={styles.tableCellHeader}>Titulaire : {myclasse?.[0]?.mon_titulaire} </Text>
                            </View>
                            <View style={styles.tablColousEntete}>
                                <Text style={styles.tableCellHeader}>Nbre d'élève : {recordseleves?.length} </Text>
                                <Text style={styles.tableCellHeader}>Période : {periode}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeaderNum}><Text style={styles.tableCellHeader2}>#</Text></View>
                            <View style={styles.tableColHeaderEleve}><Text style={styles.tableCellHeader2}>Elève</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCellHeader2}>%</Text></View>

                        </View>
                        { recordseleves.length>0 &&
                            tableaupercent.map((record,index)=>
                                recordseleves.filter((rec)=>rec.id==record.idinscription).map((elv)=>
                                    <View style={styles.tableRow} key={index}>
                                        <View style={styles.tableColNum}><Text style={styles.tableCell}>{index+1}</Text></View>
                                        <View style={styles.tableColEleve}><Text style={styles.tableCell}>{elv.nom_complet}</Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}>{((totaux(elv.id,periode)/maximum())*100).toFixed(2)}%</Text></View>
                                    </View>
                                )
                                )
                        }
                    </View>
                </Page>
            </Document>
        
    )
}
export default TableauListe;