import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import Formulaire from '../../../ModelesPages/Modele1/FormulaireInspection'
import Swal from 'sweetalert2';

function Update(){
  

    const [Count,setCount]=useState(0);
    const {id}=useParams()
    const [inspection,setInspection]=useState([])
    const [province_educationnelle,setProvince_educationnelle]=useState("");
    const [province,setProvince]=useState("")
    const [code_province,setCode_province]=useState("")
    const [arrete,setArrete]=useState("")
    let navigate=useNavigate()
useEffect(()=>{
    handleRequest()
},[])
   
    const handleRequest=async()=>{
        await axios.get(`api/Inspection/${id}`).then((res)=>{
            if(res?.data?.status_code==200){
             //   setInspection(res?.data?.inspection)
                setProvince(res?.data?.inspection?.province)
                setProvince_educationnelle(res?.data?.inspection?.province_educationnelle)
                setArrete(res?.data?.inspection?.arrete)
                setCode_province(res?.data?.inspection?.code_province)
            }
        }).catch((err)=>{

        })
    }
    function confirmSave(e){
        e.preventDefault()
        if(province_educationnelle !="" && province!=""){
        Swal.fire({
            title:'Demande de confirmation',
            text:'Voulez-vous  enregistrer les modifications de cette inspection ?',
            icon:'question',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            
            }
        })  
        }
        else{
            Swal.fire({
                title:'Erreur de soumission',
                text:'Veuillez d\'abord entrer le nom de la province éducationnelle',
                icon:'warning'
            })
        }
    }
    const HandleSubmit=async (e)=>{ 
                await axios.put(`/api/Inspection/${id}`,{province_educationnelle:province_educationnelle.toUpperCase(),province:province,code_province:code_province,arrete:arrete}).then((res)=>{
                    setProvince_educationnelle("")
                    setProvince("")
                    setCode_province("")
                    setArrete("")
                    if(res?.data?.status_code===200){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:'Votre inspection a été enregistrée avec succès',
                            icon:'success'
                            
                        })
                        navigate('/Mes_Inspections')
                    }
                    
                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    return(
    <>
    <Formulaire title="Mis à jour de l'inspection" setProvince_educationnelle={setProvince_educationnelle} setProvince={setProvince} setCode_province={setCode_province} setArrete={setArrete}  confirmSave={confirmSave} province={province} province_educationnelle={province_educationnelle} code_province={code_province} arrete={arrete}   >
    <div className="w-full md:w-1/1 px-3  md:mb-0">
       
    <div id="resultat" className="block text-red-700"></div>
    <div id="erreur" className="block text-red-500"></div>
    </div>
    </Formulaire>
    </>
    )
}
export default Update