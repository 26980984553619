import React from "react";
import axios from "../../../../../api/axios";
import { isEmpty } from "../../../../../Utilities/Utils";
import {Document,Page,View,Text,Font,StyleSheet,PDFViewer } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import Mytableau from "./tableaucotation";
import { useState,useEffect } from "react";

const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#fff',
      padding: 10
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row"
    },
    tableColHeader: {
       
      width: "16.6%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#f3f3f3'
    },
    tablColEntete:{
        margin:"auto",
        width:"100%",
        borderStyle:"solid",
        borderWidth:1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        fontWeight:"bold",
    },
    tablColousEntete:{
        margin:"auto",
        width:"50%",
        borderStyle:"solid",
        borderWidth:1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol: {
      width: "16.6%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCellHeaderEntete: {
        margin: "auto",
        margin: 5,
        fontSize: 12,
        fontWeight: 500,
        textAlign:"center"
      },
    tableCellHeader: {
      margin: "auto",
      margin: 5,
      fontSize: 12,
      fontWeight: 500
    },
    tableCell: {
      margin: "auto",
      margin: 5,
      fontSize: 10
    }
  });
 
const Mydocument=()=>{
    let {myclasse,mycour,myperiode}=useParams([])
    let cour=mycour
    let classe=myclasse
    let periode=myperiode
    const [myevaluation,setMyEvaluations]=useState([])
    const recordmyevaluations=!isEmpty(periode) ? myevaluation.filter((evaluation)=>evaluation.idclasse==classe && evaluation.idcour==cour && evaluation.periode==periode) : myevaluation.filter((evaluation)=>evaluation.idclasse==classe && evaluation.idcour==cour)
    const [eleves,setEleves]=useState([])
    const [classes,setClasses]=useState([])
    const [cotes,setCotes]=useState([])
    const [cours,setCours]=useState([])
    const [ecole,setEcole]=useState([])
    const [evaluations,setEvaluations]=useState([])

    
    const [detailcotes,setDetailcotes]=useState([])
    const [loading,setLoading]=useState(false)
    const [date_evaluations,setDate_evaluations]=useState()
    const recordcours=cours.filter((cr)=>cr.idclasse==classe && cr.idenseignant==localStorage.getItem("idenseignant"))
    const coursencours=cours.filter((cr)=>cr.id==cour)
    const recordseleves=eleves.filter((eleve)=>eleve.idclasse==classe)
    let total=0
    let totalmax=0
    let maximum=0
    let summ=0
    useEffect(()=>{
    
      HandleRequest()
      elevesrequest()
      coursRequest()
      cotations()
    
  },[])
  useEffect(()=>{
    Mypdf()
  },[])
  const HandleRequest=async()=>{
      await axios.post('api/classes_getter',{idecole:localStorage.getItem("idecole")}).then((res)=>{
          setClasses(res.data.items)
          setEcole(res.data.ecole)
      }).catch((err)=>{
          
      })
  }
  const coursRequest=async()=>{
      await axios.post('api/getcours',{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
          setCours(res.data.items)
      }).catch((err)=>{
          
      })
  }
  const elevesrequest=async()=>{
      setLoading(true)
      await axios.post("api/allinscription",{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire")}).then((res)=>{
          setEleves(res?.data?.items)
          setLoading(false)
      }).catch((err)=>{
  
      })
  }
    const cotations=async()=>{
        await axios.post('api/getallcotes',{idecole:localStorage.getItem("idecole"),annee_scolaire:localStorage.getItem("annee_scolaire"),idenseignant:localStorage.getItem("idenseignant")}).then((res)=>{
        
            setMyEvaluations(res?.data?.evaluations)
                 setDetailcotes(res?.data?.detailcotes)
        }).catch((err)=>{
        })
    }
    const dateformate=(mydate)=>{
      const [year, month, day] = mydate.split('-');
      const formattedDate = `${day}/${month}`;
      
     
      return formattedDate
      }
      const moyenne=(total,maximum,ponderation)=>{
        const pond=(periode=="Examen1" || periode=="Examen2") ? ponderation*2 : ponderation
        return parseInt(total/maximum*pond)
    }
    const somme=(point)=>{
       summ=summ+point
       return summ
    }
    const reinitialisation=()=>{
        summ=0
    }
    const maximumEval=()=>{
      recordmyevaluations?.length>0 &&
        recordmyevaluations.map((rec)=>
        {
            maximum=maximum+rec.ponderation
        }
        )
    return maximum
    }
    const Mypdf=()=>{
      return( 
        <>
   
      
        </>
      
      )
    }

    return(
        <>
                   <PDFViewer style={{width:'100%',height:'90vh'}}>
                        <Mytableau maximumEval={maximumEval} somme={somme} moyenne={moyenne} reinitialisation={reinitialisation} dateformate={dateformate} detailcotes={detailcotes} total={total} ecole={ecole} coursencours={coursencours} recordseleves={recordseleves} recordmyevaluations={recordmyevaluations} totalmax={totalmax} classe={myclasse} cour={mycour} periode={myperiode} nbrevaluation={recordmyevaluations?.length} />
                   </PDFViewer>
            
          
        </>
    )
}

export default Mydocument