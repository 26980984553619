import { isEmpty } from "../../../Utilities/Utils";



//function Formulaire({children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,count,setCount,nom,postnom,prenom,sexe,telephone,Image,setNom,setPostnom,setPrenom,setSexe,setImage,setTelephone,setNomcomplet}){
    function Formulaire({recordsexist,Affichage,HandleSubmit,my_enseignant,setMy_enseignant,enseignants,setEnseignants,catdispatch,setCatdispatch,titlecours,children,classe,ponderation,setClasse,setPonderation,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,cour,setCour,confirmSave,classes,cours}){
      return(
            <>
            <div className="container">
            <div className="row justify-content-center">
                <div className="card col-md-6 border-0">
                    <form className="" >
                        <div className="card-header">
                            
                                <h3 className=" card-title fw-bold">
                                    {title}
                                </h3>
                            
                        </div>
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="form-outline">
                                <label htmlFor="dispatching" className="form-label">Distribuer les cous par :</label>
                                <select name="dispatching" id="" className="form-select form-select-lg" onChange={(e)=>setCatdispatch(e.target.value)} value={catdispatch}>
                                    <option value="" selected disabled>Catégorie de dispatching</option>
                                    <option value="classe">Par classe</option>
                                    <option value="cours">Par cours</option>
                                  {  //<option value="enseignant">Par enseignant</option>
                                  }
                                </select>
                            </div>
                        </div>
                    </div>
                {
                    catdispatch==="classe" &&
                    <div className="row">
                       
                        
                        <div className="col-md-12 mb-2">
                            <label className="form-label" for="grid-state">
                                CLASSE  <span class="obligatoire">*</span>
                            </label>
                            <div className="form-outline">
                                <select className="form-select form-select-lg" id="grid-state" name="classe" onChange={(e)=>setClasse(e.target.value)} value={classe}  >
                                <option value="" selected disabled>Choix de la classe</option>
                                {
                                    classes.map((cla)=>
                                        <option value={cla.id} key={cla.id} >{cla.nom}</option>
                                    )
                                }
                                
                                </select>
                            </div>
                        </div>
                       
                        
                    </div>
                }
                {
                    catdispatch==="cours" &&
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="form-outline">
                                    <label className="form-label" for="grid-state">
                                        COURS  <span class="obligatoire">*</span>
                                    </label>
                                    <select className="form-select form-select-lg" id="grid-state" name="niveau"  onChange={(e)=>setCour(e.target.value)} value={cour}>
                                    <option value="" selected disabled>Niveau de la classe</option>
                                        {
                                            cours.map((crs)=>
                                            <option value={crs.id} key={crs.id} >{crs.nom}</option>
                                        )
                                        }
                                    
                                    
                                    </select>
                            </div>
                        </div>
                    </div>
    }
      {
                    catdispatch==="enseignant" &&
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="form-outline">
                                    <label className="form-label" for="grid-state">
                                        ENSEIGNANT  <span class="obligatoire">*</span>
                                    </label>
                                    <select className="form-select form-select-lg" id="grid-state" name="niveau"  onChange={(e)=>setMy_enseignant(e.target.value)} value={my_enseignant}>
                                    <option value="" selected disabled className="fw-bold">Choix de l'enseignant</option>
                                        {
                                            enseignants.map((ense)=>
                                            <option value={ense.id} key={ense.id} className="fw-bold">{ense.nom_complet}</option>
                                        )
                                        }
                                    
                                    
                                    </select>
                            </div>
                        </div>
                    </div>
    }
                    <div className="">
                       
                    </div>

                    
                 

                  
                 </div>
                </form>
        </div>
        </div>
        <div className="row justify-content-start">
                <div className="card col-md-6 mb-2 border-0">
                    <div className="card-header">
                        <h3 className="card-title fw-bold">
                            Dispatching des cours
                        </h3>
                    </div>
                    <div className="card-body" id="dispatchnow">
                        {Affichage()}

                    </div>
                    <div className="card-footer">
                  { !isEmpty(catdispatch)  &&
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="form-outline">
                                <button className="btn btn-primary" type="button" onClick={confirmSave} name="soumettre" id="submit" >Submit</button>
                            </div>
                        </div>
                    </div>
                    }
                    </div>
                </div>
                <div className="card col-md-6 px-2 border-0">
                    <div className="card-header">
                        <h3 className="card-title fw-bold text-black">
                           {titlecours} (<span>Total : {!isEmpty(classe) && recordsexist.length} </span> )
                        </h3>
                    </div>    
                    <div className="card-body">
                            {children}
                </div>
                </div>
        </div>
    </div>
            </>
        )
    }
    export default Formulaire;