


//function Formulaire({children,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,count,setCount,nom,postnom,prenom,sexe,telephone,Image,setNom,setPostnom,setPrenom,setSexe,setImage,setTelephone,setNomcomplet}){
    function Formulaire({HandleSubmit,children,litterature,setLitterature,title,handleMouse,handleMouseleave,handlekeyUpgain,onclick,nom,setNom,confirmSave}){
  
        return(
            <>
            <div className="container">
                <form onSubmit={confirmSave}>
                    <legend>
                        <h3 className="font-bold text-blue-500 mb-4 text-xl">
                            {title}
                        </h3>
                    </legend>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-outline">
                                <label class="form-label" for="firstName">Section</label>
                                <input type="text" id="firstName" class="form-control form-control-lg" value={nom} onChange={(e)=>setNom(e.target.value.toLowerCase())} onKeyUp={handlekeyUpgain} />                          
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-outline">
                                <label class="form-label" for="firstName">Littérature</label>
                                <input type="text" id="firstName" class="form-control form-control-lg" value={litterature} onChange={(e)=>setLitterature(e.target.value.toLowerCase())} onKeyUp={handlekeyUpgain} />                          
                            </div>
                        </div>
                    </div>
                    {children}

                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <button className="btn btn-primary" type="submit" name="soumettre" id="submit"  onMouseLeave={handleMouseleave}>Submit</button>
                        </div>
                    </div>

                </form>
            </div>

            </>
        )
    }
    export default Formulaire;