import axios from "axios"
import axios2 from "../api/axios"
import { useState, useEffect } from "react"
import Swal from "sweetalert2"
import { useDispatch, useSelector } from "react-redux"
import { adduser } from "../actions/user.action"
import { useNavigate } from "react-router-dom"
import { addeleve } from "../actions/eleve.action"
import { getCookie, isEmpty } from "../Utilities/Utils"

function Register(){
 // const userselector=useSelector((state)=>state.userReducer)
  const eleveselector=useSelector((state)=>state.eleveReducer)
const [nom,setNom]=useState("")
const [prenom,setPrenom]=useState("")
const [postnom,setPostnom]=useState("")
const [sexe,setSexe]=useState("")
const [errorglobaleleve,setErrorglobaleleve]=useState("")
const [errorglobaluser,setErrorglobaluser]=useState("")

const [adresse,setAdresse]=useState("")
const [datenaissance,setDatenaissance]=useState("")
const [email,setEmail]=useState("")
const [telephone,setTelephone]=useState("")
const [etatcivil,setEtatcivil]=useState("")
const [password,setPassword]=useState("")
const [name,setName]=useState("")
const [password_confirmation,setPasswordConfirmation]=useState("")
const [users,setUsers]=useState([])
const [errors,setErrors]=useState([])
const [errorseleve,setErrorseleve]=useState([])
const type="Administrateur"
const navigate=useNavigate()
const dispatch=useDispatch()
//const csrf=()=>axios2.get("/sanctum/csrf-cookie") 
useEffect(()=>{
    //
})

const handleeleve=async()=>{
try{
    const nomcomplet=nom+' '+postnom+' '+prenom

  await  axios2.post('api/addminecol',{nom:nom,postnom:postnom,prenom:prenom,sexe:sexe,adresse:adresse,date_naissance:datenaissance,telephone:telephone,nom_complet:nomcomplet,name,email,password,password_confirmation,type:"Administrateur"}).then((res)=>{
    
        res?.status===200 && res?.data?.status_code===204 && setErrorglobaleleve(res?.data?.status_message)
        if(res?.status===200 && res?.data?.status===210){
          navigate("/connexion")
        }

  }).catch((err)=>{
    err?.response?.status===422 && setErrorseleve(err?.response?.data?.errors)
  })
  }
  catch(err){

    
  }
 
    
}
    

const handleuser=async()=>{
  await axios2.get("/sanctum/csrf-cookie") 
  try{
    await axios2.post("/register",{name,email,password,password_confirmation,nom,postnom,prenom,datenaissance,adresse,telephone,type_compte:"Administrateur"}).then((res)=>{
      setEmail("")
      setPassword("")
      setNom("")
      setPostnom("")
      setPrenom("")
      setSexe("")
      setPassword("")
      setTelephone("")
      setPasswordConfirmation("")

      setPasswordConfirmation("")
      setName("")
     navigate("/connexion")
//console.log(res)  
    }).catch((e)=>{
      e.response.status===422 && setErrors(e.response.data.errors)
    })
     }
  catch(e){
   // userselector?.response?.status===422 &&  setErrorseleve(userselector?.response?.data?.errors)
    e.response.status===422 && setErrors(e.response.data.errors)
  }
}
console.log(errorseleve)
/*const handleuser=async()=>{
  await axios.post('http://localhost:8000/api/user',{username:username,email:email,password:password,type:type,adresse:adresse,datenaissance:datenaissance,nom:nom,postnom:postnom,prenom:prenom}).then((res)=>{
    console.log(res)
}).catch((err)=>{

})
}*/

const handlesubmit=async()=>{
  await axios2.get("/sanctum/csrf-cookie") 
 /* const table=[
    {
      username:username,
      password:password,
      email:email,
      type_compte:type  
    }
    
  ]*/
 // const nomcomplet=nom+' '+postnom+' '+prenom
         handleeleve()
    //    handleuser()
       

      //  dispatch(addeleve(nom,postnom,prenom,sexe,adresse,datenaissance,telephone,nomcomplet))
    //    dispatch(adduser(name,email,password,password_confirmation,type,adresse,datenaissance,nom,postnom,prenom,telephone))
    // userselector?.response?.status===422 &&  setErrors(userselector?.response?.data?.errors)

      
       
  }   
return(
<>
<form className="needs-validation">
<div class="vh-100 gradient-custom">
  <div class="container py-5 h-100">
    <div class="row justify-content-end align-items-center h-100">
      <div class="col-12 col-lg-9 col-xl-7">
        <div class="card shadow-2-strong card-registration border-rounded" >
          <div class="card-body p-4 p-md-5">
            <h3 class="mb-4 pb-2 pb-md-0 mb-md-5 text-primary font-bold">Inscrivez-vous ici </h3>
            <form>
              <div class="row">
                <div class="col-md-6 mb-4">
                
                  <div class="form-outline">
                  <label class="form-label" for="firstName">Nom</label>
                    <input type="text" id="firstName" class="form-control form-control-lg" onChange={(e)=>setNom(e.target.value.toLowerCase())} value={nom} required/>
                  {errorseleve?.nom &&
                   <div className="text-danger">
                        <span>
                            {errorseleve?.nom[0]
                            }
                        </span>
                    </div>
                  }
                  </div>
                </div>
                <div class="col-md-6 mb-4">

                  <div class="form-outline">
                  <label class="form-label" for="lastName">Postnom</label>
                    <input type="text" id="lastName" class="form-control form-control-lg"  onChange={(e)=>setPostnom(e.target.value.toLowerCase())} value={postnom} required/>
                    { errorseleve?.postnom &&
                   <div className="text-danger">
                        <span>
                            {
                            errorseleve?.postnom[0]
                            }
                        </span>
                    </div>
                  }
                  </div>

                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4">

                  <div class="form-outline">
                    <label class="form-label" for="firstName">Prenom</label>
                    <input type="text" id="firstName" class="form-control form-control-lg" onChange={(e)=>setPrenom(e.target.value.toLocaleLowerCase())} value={prenom} />
                    
                  </div>

                </div>
                <div class="col-6 mb-4">
                  <div class="form-outline">
                    <label class="form-label" for="phoneNumber">Nom d'utilisateur</label>

                      <input type="text" id="phoneNumber" class="form-control form-control-lg" onChange={(e)=>setName(e.target.value)} value={name} required/>
                    </div>
                        { errorseleve?.name &&
                        <div className="text-danger">
                              <span>
                                  {errorseleve?.name[0]
                                  }
                              </span>
                          </div>
                        }
                </div>
               
              </div>

              <div class="row">
                <div class="col-md-6 mb-4 d-flex align-items-center">

                  <div class="form-outline datepicker w-100">
                  <label for="birthdayDate" class="form-label">Date de naissance</label>
                    <input type="date" class="form-control form-control-lg" id="birthdayDate" onChange={(e)=>setDatenaissance(e.target.value)} value={datenaissance} required />
                    {errorseleve?.date_naissance &&
                   <div className="text-danger">
                        <span>
                            {errorseleve?.date_naissance[0]
                            }
                        </span>
                    </div>
                  }
                  </div>

                </div>
                <div class="col-md-6 mb-4">

                  <h6 class="mb-2 pb-1">Genre: </h6>

                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="femaleGender"
                      value="Femme" onChange={(e)=>setSexe(e.target.value)} required/>
                    <label class="form-check-label" for="femaleGender">Femme</label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="maleGender"
                      value="Homme" onChange={(e)=>setSexe(e.target.value)}  />
                    <label class="form-check-label" for="maleGender">Homme</label>
                  </div>
                  
                  { errorseleve?.sexe &&
                   <div className="text-danger">
                        <span>
                            {errorseleve?.sexe[0]
                            }
                        </span>
                    </div>
                  }
               
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-4 pb-2">

                  <div class="form-outline">
                  <label class="form-label" for="emailAddress">Email</label>
                    <input type="email" id="emailAddress" class="form-control form-control-lg" onChange={(e)=>setEmail(e.target.value)} value={email} />
                    { errorseleve?.email &&
                   <div className="text-danger">
                        <span>
                            {errorseleve?.email[0]
                            }
                        </span>
                    </div>
                  }
                  </div>

                </div>
                <div class="col-md-6 mb-4 pb-2">

                  <div class="form-outline">
                  <label class="form-label" for="phoneNumber">Téléphone</label>

                    <input type="text" id="phoneNumber" class="form-control form-control-lg" onChange={(e)=>setTelephone(e.target.value)} value={telephone} required/>
                  </div>
                  { errorseleve?.telephone &&
                   <div className="text-danger">
                        <span>
                            {errorseleve?.telephone[0]
                            }
                        </span>
                    </div>
                  }
               
                </div>
              </div>

              <div class="row">
                <div class="col-6 mb-4">
                <label class="form-label select-label">Etat-civil</label>

                  <select class="form-select form-select-lg" onChange={(e)=>setEtatcivil(e.target.value)} value={etatcivil} required >
                    <option value="1" disabled>Choisir une option</option>
                    <option value="2">Célibataire</option>
                    <option value="3">Marié</option>
                    <option value="3">Divorcé</option>
                    <option value="4">Veuf</option>
                  </select>

                </div>
                <div class="col-md-6 mb-4">

                    <div class="form-outline">
                    <label class="form-label" for="lastName">Adresse</label>
                      <input type="text" id="lastName" class="form-control form-control-lg" onChange={(e)=>setAdresse(e.target.value)} value={adresse} required/>
                      { errorseleve?.adresse &&
                    <div className="text-danger">
                          <span>
                              {errorseleve?.adresse[0]
                              }
                          </span>
                      </div>
                    }
                    </div>

              </div>
              </div>
              <div class="row">
                
              </div>
              <div class="row">
                <div class="col-md-6 mb-4">

                  <div class="form-outline">
                  <label class="form-label" for="firstName">Mot de passe</label>

                    <input type="password" id="firstName" class="form-control form-control-lg" onChange={(e)=>setPassword(e.target.value)} value={password} />
                  </div>
                  { errorseleve?.password &&
                   <div className="text-danger">
                        <span>
                            {errorseleve?.password[0]
                            }
                        </span>
                    </div>
                  }
                </div>
                <div class="col-md-6 mb-4">

                  <div class="form-outline">
                  <label class="form-label" for="lastName">Confirmer mot de passe</label>

                    <input type="password" id="lastName" class="form-control form-control-lg" onChange={(e)=>setPasswordConfirmation(e.target.value)} value={password_confirmation} />
                  </div>
                  { errorseleve?.password_confirmation &&
                   <div className="text-danger">
                        <span>
                            {errorseleve?.password_confirmation[0]
                            }
                        </span>
                    </div>
                  }
                </div>
              </div>
                <div className="text-danger">
                  {
                    !isEmpty(errorglobaleleve) && 
                    <span>
                      {
                        errorglobaleleve
                      }
                    </span>
                  }
                </div>
              <div class="mt-4 pt-2">
                <button class="btn btn-primary btn-lg" type="button" onClick={handlesubmit}>Submit</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</form>
</>
    )
  
}
export default Register