import { useState } from "react"
import axios from "../../../../api/axios"
import Formulaire from "../../../ModelesPages/Modele1/Formulaire_programme"
function Insertion(){
    const [annee_scolaire,setAnnee_scolaire]=useState("")
    const [debut_p1,setDebut_p1]=useState("")
    const [debut_p2,setDebut_p2]=useState("")
    const [debut_p3,setDebut_p3]=useState("")
    const [debut_p4,setDebut_p4]=useState("")
    const [fin_p1,setFin_p1]=useState("")
    const [fin_p2,setFin_p2]=useState("")
    const [fin_p3,setFin_p3]=useState("")
    const [fin_p4,setFin_p4]=useState("")
    const [debut_exam1,setDebut_exam1]=useState("")
    const [fin_exam1,setFin_exam1]=useState("")
    const [debut_exam2,setDebut_exam2]=useState("")
    const [fin_exam2,setFin_exam2]=useState("")
    const [message,setMessage]=useState("")
    const [submission,setSubmission]=useState(false)
    const [error_submission,setError_submission]=useState(false)
    const HandleSubmit=async()=>{
     
        await axios.post('api/Programme',{debut_exam1,debut_exam2,debut_p1,debut_p2,debut_p3,debut_p4,fin_exam1,fin_exam2,fin_p1,fin_p2,fin_p3,fin_p4,annee_scolaire}).then((res)=>{
            setSubmission(true)    
            setMessage(res.data.message)
            if(res.data.status===200){
                setAnnee_scolaire("")
                setDebut_exam1("")
                setDebut_exam2("")
                setDebut_p1("")
                setDebut_p2("")
                setDebut_p3("")
                setDebut_p4("")
                setFin_exam1("")
                setFin_exam2("")
                setFin_p1("")
                setFin_p2("")
                setFin_p3("")
                setFin_p4("")

            }
        }).catch((err)=>{
        
         //    err.length>0 &&  setError_submission(true)
        })
    }
    return(
        <>
        

        <Formulaire   annee_scolaire={annee_scolaire}  HandleSubmit={HandleSubmit} setAnnee_scolaire={setAnnee_scolaire} submission={submission} error_submission={error_submission} debut_p1={debut_p1} setDebut_p1={setDebut_p1} debut_p2={debut_p2} setDebut_p2={setDebut_p2} debut_p3={debut_p3} setDebut_p3={setDebut_p3} debut_p4={debut_p4} setDebut_p4={setDebut_p4} debut_exam1={debut_exam1} setDebut_exam1={setDebut_exam1} debut_exam2={debut_exam2} setDebut_exam2={setDebut_exam2} fin_p1={fin_p1} setFin_p1={setFin_p1} fin_p2={fin_p2} setFin_p2={setFin_p2} fin_p3={fin_p3} setFin_p3={setFin_p3} fin_p4={fin_p4} setFin_p4={setFin_p4} fin_exam1={fin_exam1} setFin_exam1={setFin_exam1} fin_exam2={fin_exam2} setFin_exam2={setFin_exam2}>

        </Formulaire>
        </>
    )
}
export default Insertion
