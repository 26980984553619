import axios from '../../../../api/axios';
import React,{useState,useEffect} from 'react'
import Formulaire from '../../../ModelesPages/Modele1/FormulaireCours'
import { BookFill } from 'react-bootstrap-icons';
import Swal from 'sweetalert2';
import {useParams,useNavigate} from 'react-router-dom'

function Delete(){

    const [Count,setCount]=useState(0);
    const {id}=useParams()
    const navigate=useNavigate()
    const [nom,setNom]=useState("");
    const [motif,setMotif]=useState("")
    const [status_code,setStatus_code]=useState("")

    useEffect(()=>{
        HanleRequest()
    },[])
    function handleMouse(e){

        e.target.textContent="Bonjour"
    }
    function handleMouseleave(e){
        e.target.textContent="Soumettre"
    }
    function handlekeyUpgain(e){
    
        const elementss=e.nextElementSibling;
      
    }
    const HanleRequest=async(e)=>{
        await axios.get(`api/Cours/${id}`).then((res)=>{
            setNom(res.data.items.nom)
            setStatus_code(res.data.status_code)
            console.log(res)
        }).catch((err)=>{
            console.log(err)
        })
    }
    function confirmSave(e){
        e.preventDefault()
 
        Swal.fire({
            title:'Demande de confirmation',
            text:'Vous êtes sur le point de supprimer ce cours ?',
            icon:'warning',
            showCancelButton:true,
            showConfirmButton:true,
            confirmButtonText:'Confirmer',
        }).then((resultat)=>{
            if(resultat.isConfirmed){
                HandleSubmit()
            }
        })  
    }
    const HandleSubmit=async (e)=>{
       
                await axios.delete(`api/Cours/${id}`).then((res)=>{
                    setNom("")
                    if(res.data.status_code===200){
                        Swal.fire({
                            title:'Message de confirmation',
                            text:'Le cours a été supprimé avec succès',
                            icon:'success',
                            
                        }).then((resultat)=>{
                            navigate('/cours')
                        })

                    }
                    else{
                        Swal.fire({
                            title:'Message d\'erreur',
                            text:'Une erreur est survenue, réessayer ultérieurement',
                            icon:'error'
                            
                        })
                    }
                    console.log(res)
                     
                }).catch((err)=>{
                        document.getElementById("erreur").innerHTML=err
                        
                        
                })
    }
    return(
    <>
  {

status_code === 200 ?
     <div className="card w-25 align-middle mx-auto mt-8" >
 <div className="card-body">
 <BookFill color='blue' size={96} />
     <h5 className="card-title text-primary text-center">{nom}</h5>
    
     <div className="">
         <select name="motif" id="motif" className='form-control mx-2' onChange={(e)=>setMotif(e.target.value)}>
             <option value="" selected disabled>Motif de suppression</option>
             <option value="Le cours n'existe plus">Le cours n'existe plus</option>
             <option value="Le cours est momentannement mis en suspens">Le cours est momentannement mis en suspens</option>                                                  
         </select>
     </div>
 </div>
 
 <div className="card-footer">
     <a href="#" className="card-link btn btn-danger px-2 mr-12" onClick={confirmSave}>Supprimer</a>
     <a href="#" className="card-link btn btn-primary px-2 mr-2" onClick={()=>navigate('/cours')}>Annuler</a>
 </div>
 </div>
 :
 <div className="">
     <h3 className='text-danger'>
         Aucun enregistrement correspondant!
     </h3>
 </div>
 }

    </>
    )
}
export default Delete